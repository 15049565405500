/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const DEFAULT_COMMENT_DURATION = 0;

const NUDGE_ZOOM_GAP = 5;

const COMMENT_PANE_WIDTH_KEY = 'pageproof.app.commentPaneWidth';
const DEFAULT_COMMENT_PANE_WIDTH = 500;
const S_BREAK_M = 750;

const HEADER_HEIGHT = 100;
const FOOTER_HEIGHT = 100;
const OFFSCREEN_BOUNCE_BACK_MARGIN = 10;

const MEASUREMENT_SCALE_STORAGE_KEY = 'pageproof.app.measurement-scale.';
const DEFAULT_MEASUREMENT_SCALE = 1;
const MINIMUM_MEASUREMENT_SCALE = 0.001;
const MAXIMUM_MEASUREMENT_SCALE = 999;

const CREATED_AS_DRAFT_SEARCH_PARAM_NAME = 'a3b7xz';

const ZOOM_LEVELS = [
    25,
    50,
    75,
    100,
    150,
    200,
    400,
    1000,
];

const postmanPatTries = {};

const getCommentTools = (textSelectionAvailable, freeDrawingAvailable, canHaveGeneralComments) => [
    'pin',
    textSelectionAvailable && 'text',
    freeDrawingAvailable && 'draw',
    canHaveGeneralComments && 'general',
].filter(Boolean);

class GenericProofController extends BaseProofController {
    /**
     * The user object to display the proof page to.
     *
     * @type {PPUser}
     */
    user = null;

    /**
     * The encryption object to use for crypto.
     *
     * @type {PageProofEncryption}
     */
    encryptionData = null;

    /**
     * The comments controller.
     *
     * @type {CommentsController}
     */
    comments = null;

    /**
     * The current page number.
     *
     * @type {Number}
     */
    currentPage = 1;

    /**
     * Whether or not to show the pins.
     *
     * @type {Boolean}
     */
    showPins = true;

    /**
     * Whether or not to pins colour been inverted.
     *
     * @type {Boolean}
     */
    isInvertedPins = false;

     /**
     * Whether the date in comments shown general(eg. last month, 20 hours) or detailed (eg. Thursday, May 17, 2018 3:57 PM).
     *
    * @type {Boolean}
    */
    isDetailedDate = false

    proofLoaded = false;

    hasCustomTools = false;

    customTools = {};
    
    pinsHistory = [];
    pinsHistoryIndex = 0;

    commentPaneWidth = Number(window.localStorage.getItem(COMMENT_PANE_WIDTH_KEY)) || DEFAULT_COMMENT_PANE_WIDTH;

    /**
     * Optional flags for the controller (for enabling/disabling functionality)
     *
     * @type {Object}
     */
    flags = {
        commentDuration: DEFAULT_COMMENT_DURATION,
        enableCommentRefresh: true,
        allowFullscreen: true,
        updatePermissions: true,
    };

    /**
     * Whether the button confirmation is active.
     *
     * @type {Boolean}
     */
    buttonConfirm = false;

    /**
     * The kind of action to take when the user scrolls the proof.
     *
     * - pan
     * - zoom
     *
     * @type {string}
     */
    scrollMode = 'pan';

    /**
     * @type {string}
     */
    editorOwnerMessage = '';

    /**
     * If proof is a part of a group, then it will have all data related to that group
     *
     * @type {object}
     */
    group = null;

    /**
     * @type {string}
     */
    statusText = '';

    /**
     * @type {string}
     */
    statusClass = '';

    /**
     * @type {boolean}
     */
    canShowReviewerCount = false;

    /**
     * If proof is part of a group then weather group collection view is open or not
     *
     * @type {boolean}
     */
    showCollection = false;

    /**
     * Email address which has requested to add on a proof by email
     *
     * @type {string}
     */
    requestAccessEmail = null;

    isActivatedRuler = false;

    isActivatedMarqueeZoom = false;

    isActivatedGridLines = false;

    pageDimensionInfo = {
        width: 0,
        height: 0,
        unit: null,
        isDecimal: false,
        scale: DEFAULT_MEASUREMENT_SCALE,
        minimumScale: MINIMUM_MEASUREMENT_SCALE,
        maximumScale: MAXIMUM_MEASUREMENT_SCALE,
    };

    currentCanvasPosition = {};

    isInitGridRulers = false;

    angularRef = { current: null };

    freeDrawingAvailable = true;

    get canFinalApprove() {
        return (
            this.$permissions.isFinalApproverStage &&
            this.$permissions.isFinalApprover &&
            !(
                this.proof.checklist &&
                this.proof.checklist.isRequiredForFinalApproval &&
                this.isChecklistComplete === false
            )
        );
    }

    /**
     * @constructor
     */
    constructor () {
        super();

        this.$$import(this.$$dependencies([
            '$q',
            '$scope',
            '$rootScope',
            '$timeout',
            '$routeParams',
            '$location',
            'SegmentIo',
            'UserService',
            'zipService',
            'eventService',
            'sdk',
            'appService',
            'userService',
            'backendService',
            'proofRepositoryService',
            'textCommentWithMentionFilter',
            'seoService',
            'scopeService',
            'shortcutService',
            'tooltipService',
            'storageService',
            'domService',
            'browserService',
            'printService',
            'printServiceLegacy',
            'modalService',
            'PPProofControllerHook',
            'PPProofComment',
            'PPProofPage',
            'PPProofButtonState',
            'PPProofActionType',
            'PPCommentMarkType',
            'PPProofStatus',
            'PPCommentMessageType',
            'PPProofDialogType',
            'SegmentIo',
            'localCommentService',
            'apiService',
            'proofCacheService',
            'temporaryStorageService',
        ]));

        this.loadTime = moment();

        this.proofId = this.version = this.$$.$routeParams.proofId || this.$$.$routeParams.briefId;
        this.proofType = this.$$.$routeParams.briefId ? 2 : 1;
        this.requestAccessEmail = this.$$.$location.search().requester;
        this.$$.SegmentIo.track(21, {
            'proof id': this.proofId,
        }); // Proof Opened

        this.user = this.$$.userService.getUser();
        this.isTeamPageProof = this.user.email && this.user.email.endsWith('@pageproof.com');
        this.encryptionData = this.user.getEncryptionData();

        this.isSmallScreen = window.innerWidth <= 750;
        this.isMobile = this.$$.browserService.is('mobile');
        this.isTablet = this.$$.browserService.is('tablet');
        this.isIE = this.$$.browserService.is('ie');
        this.isSafari = this.$$.browserService.is('safari');
        this.isChecklistComplete = null;
        this.commentPermissions = {
            canCreateComment: false,
            canCreatePrivateComment: false,
            canSetCommentStatus: false,
        }
        
        this.initKeyboardShortcuts();
        this.initWatchers();
        this.initCommentRefreshConditions();
        this.initHooks();
        this.initDownloadState();
        this.initReactProps();
        this.initCurrentCanvasPosition();
        this.initCommentNavigationWatchers();
        this.getCommentPermissions();

        window.generalfunctions_preloadQuarkChunk('editor');
        
        this.beforeDestroy(() => {
            this.$$.$rootScope.logos.proof = null;
            this.setVersionsInHeader(false);
            this.$$.$rootScope.isCreatingComment = false;
            this.toggleFocusModeInHeader(false);
            this.clearProofScreenMobileMenuProps();
        });
        
        const that = this;
        this.penToolProps = {
            get mode() {
                return that.interactionMode;
            },
            get modes() {
                return that.temporaryComment && that.temporaryComment.pins && that.temporaryComment.pins.length > 0
                    ? [that.interactionMode]
                    : getCommentTools(that.textSelectionAvailable, that.freeDrawingAvailable, that.proof.canHaveGeneralComments);
            },
            get selected() {
                return that.isCommenting;
            },
            get direction() {
                return that.isSmallScreen ? 'up' : 'right';
            },
            get constraints() {
                return that.isSmallScreen
                    ? { width: 30, height: 14}
                    : { width: 40, height: 23 };
            },
            get size() {
                return that.isSmallScreen ? 42 : 60;
            },
            get spacing() {
                return that.isSmallScreen ? 10 : 15;
            },
            onChangeMode(mode, selected) {
                that.interactionMode = mode;
                that.isCommenting = selected;
                that.isActivatedRuler = false;
                that.isActivatedMarqueeZoom = false;
                that.onInteractionModeChanged();
            },
        };

        this.drawingControlsProps = {
            get canUndoDrawing() {
                return that.canUndo();
            },
            get canRedoDrawing() {
                return that.canRedo();
            },
            onUndoDrawing: () => {
                if (!that.canUndo()) {
                    return;
                }
                
                if (this.canUsePinHistory()) {
                    that.pinsHistoryIndex--;
                    that.restorePinsSnapshot();
                    return;
                }

                that.canvas.undoDrawing()
            },
            onRedoDrawing: () => {
                if (!that.canRedo()) {
                    return;
                }

                if (this.canUsePinHistory()) {
                    that.pinsHistoryIndex++;
                    that.restorePinsSnapshot();
                    return;
                }

                that.canvas.redoDrawing();
            },
            onDeleteDrawing: () => {
                if (this.canUsePinHistory()) {
                    that.addPinsSnapshot([]);
                    that.restorePinsSnapshot();
                    return;
                }

                that.canvas.deleteDrawing();
            }
        }

        this.getProofCtrl = () => this;

        this.proofStorage = this.$$.storageService('pageproof.app.' + this.proofId + '.' + this.user.id + '.');

        this.pageDimensionInfo.scale = Number(window.localStorage.getItem(MEASUREMENT_SCALE_STORAGE_KEY + this.proofId + '.' + this.user.id)) || DEFAULT_MEASUREMENT_SCALE;

        this.zoomProps = {
            onChange: level => this.zoomTo(level),
        };

        this.onChangeRulerMode = (selected) => {
            that.interactionMode = selected ? 'ruler' : null;
            that.isCommenting = false;
            that.isActivatedRuler = selected;
            that.isActivatedMarqueeZoom = that.isActivatedMarqueeZoom && !selected;
            that.onInteractionModeChanged();
            if (!that.$$.$scope.$$phase) {
                that.$$.$scope.$apply();
            }
        }

        this.onChangeMarqueeZoomMode = (selected) => {
            that.interactionMode = selected ? 'marqueeZoom' : null;
            that.isCommenting = false;
            that.isActivatedMarqueeZoom = selected;
            that.isActivatedRuler = that.isActivatedRuler && !selected;
            that.onInteractionModeChanged();
            if (!that.$$.$scope.$$phase) {
                that.$$.$scope.$apply();
            }
        }

        this.onChangeGridLinesMode = (selected, ignoreHeader = false) => {
            that.isActivatedGridLines = selected;
            if (ignoreHeader) {
                return;
            }
            that.$$.$scope.headerControls.show = !selected;
            that.$$.$scope.headerControls.lock = selected;
        }

        this.onOpenPdfCommentImport = () => {
            const isAdminOnProof = (this.proof.teamId === this.user.teamId) && this.user.isDomainAdmin
            const { destroy } = this.$$.modalService.createWithComponent('ImportPdfCommentsContainer', { 
                commentPermissions: this.commentPermissions,
                isAdminOnProof,
                proofId: this.proofId,
                proofDimensions: this.proof.pageDimensions,
                proofPageCount: this.proof.pageCount,
                onClose : () => destroy()
            },
            null,
            false);
            this.closeOptions = destroy;
        }

        this.onChangeIsInitGridRuler = selected => that.isInitGridRulers = selected;

        this.updatePageDimensionInfo = (data) => {
            
            if (data.scale) {
                window.localStorage.setItem(MEASUREMENT_SCALE_STORAGE_KEY + this.proofId + '.' + this.user.id, data.scale);
            }

            that.pageDimensionInfo = Object.assign({}, that.pageDimensionInfo, data);
        }

        const bounceBackProofIfOffscreen = debounce((shouldBounceBack) => {
            if (shouldBounceBack) {
                that.canvas._moveToCenter();
            }
        }, 500);

        this.whenImageLoad = () => {
            if (this.isScrollingPages) {
                const scrollModeBackup = this.scrollMode;
                this.scrollMode = null;
                if (scrollModeBackup) {
                    setTimeout(() => {
                        this.scrollMode = scrollModeBackup;
                    }, 200);
                }

                this.canvas.setPosition({
                    top: this.canvas._getViewportCenterPoint().y,
                    left: this.canvas.getPosition().left,
                });

                this.isScrollingPages = false;
            }

            if (this.temporaryComment && this.interactionMode === 'draw') {
                if (this.currentPage === this.temporaryComment.pageNumber) {
                    this.canvas.showDrawingElem();
                } else {
                    this.canvas.hideDrawingElem();
                }
            }
        }
        
        this.updateCanvasPosition = (data) => {
            const { top, left, height, width } = data;

            const isOffBottomOfScreen = top > (window.innerHeight - FOOTER_HEIGHT - OFFSCREEN_BOUNCE_BACK_MARGIN);
            const isOffTopOfScreen = top + height < OFFSCREEN_BOUNCE_BACK_MARGIN;
            const isOffRightOfScreen = left > (window.innerWidth - OFFSCREEN_BOUNCE_BACK_MARGIN);
            const isOffLeftOfScreen = left + width < OFFSCREEN_BOUNCE_BACK_MARGIN;

            let shouldBounceBack = isOffBottomOfScreen || isOffTopOfScreen || isOffLeftOfScreen || isOffRightOfScreen;

            if (!this.isScrollingPages) {
                if (isOffTopOfScreen && that.proof.pages.length > that.currentPage) {
                    this.isScrollingPages = true;
                    this.nextPage();
                } else if (isOffBottomOfScreen && that.currentPage > 1) {
                    this.isScrollingPages = true;
                    this.previousPage();
                }
                
                if (this.isScrollingPages) {
                    this.loadCurrentPagePreview();
                    shouldBounceBack = false;
                }
            }

            // This still needs to be called when shouldBounceBack is false because it will prevent the bounce back after debounce time is complete
            bounceBackProofIfOffscreen(shouldBounceBack);

            this.updateCurrentCanvasPosition(data);
        };

        this.setupCommentEvents();
        this.setupProofPermissionsListener();

        this.flashingGeneralComment = null;

        this.getGeneralCommentIconButtonsLocation = () => {
            const { top, left, width } = that.currentCanvasPosition;

            return {
                top: this.focusMode ? top : top - HEADER_HEIGHT,
                left: left + width,
            }
        }

        this.generalCommentIconButtonsProps = {
            get page() {
                return that.getCurrentPage();
            },
            get position() {
                return that.getGeneralCommentIconButtonsLocation();
            },
            get flashingType() {
                return that.flashingGeneralComment ? that.getCommentMarkStatus(that.flashingGeneralComment) : null;
            },
            get filterName() {
                return that.filter.name;
            },
            get commentOrder() {
                return that.commentOrder;
            },
            targetZIndex: 1, // px-canvas's z-index
            getGeneralCommentsByType(comments) {
                const { TODO, DONE, UNMARKED } = that.$$.PPCommentMarkType;
                const markTypeComments = { [UNMARKED]: [], [TODO]: [], [DONE]: [] };

                const filtered = comments.filter(comment => that.filter.fn(comment) && that.isGeneralComment(comment));

                that.$$
                    .$filter('orderBy')(filtered, that.commentOrder.orderCommentsBy, that.commentOrder.isReversedCommentOrder)
                    .forEach((comment) => {
                        const markType = that.getCommentMarkStatus(comment);
                        markTypeComments[markType].push(comment);
                    });

                return markTypeComments;
            },
            selectGeneralComment(comment) {
                that.selectComment(comment, true);
            },
        };

        this.commentsPaneProps = {
            getProofCtrl: this.getProofCtrl,
        };
    }

    canUsePinHistory() {
        return this.interactionMode === 'pin';
    }

    canUndo() {
        if (this.canUsePinHistory()) {
            return this.pinsHistoryIndex > 0 && this.pinsHistory.length > 0
        }

        return !!this.canvas && this.canvas.canUndoDrawing;
    }

    canRedo() {
        if (this.canUsePinHistory()) {
            return this.pinsHistoryIndex < this.pinsHistory.length - 1;
        }

        return !!this.canvas && this.canvas.canRedoDrawing;
    }
    
    getPinsSnapshot(pins) {
        return JSON.stringify(pins, (key, value) => {
            if (key === '$$hashKey' || key === '__canvasXY') {
                return undefined;
            }
            return value;
        });
    }

    restorePinsSnapshot() {
        const snapshot = this.pinsHistory[this.pinsHistoryIndex];

        if (snapshot) {
            const updatedPins = JSON.parse(snapshot).map((pin, index) => {
                const existingPin = this.temporaryComment.pins[index];

                // If the pin is the same as the existing pin, return the existing pin to prevent re-rendering/flashing
                if (existingPin && this.getPinsSnapshot([existingPin]) === this.getPinsSnapshot([pin])) {
                    return existingPin;
                }

                return pin;
            });
            this.temporaryComment.pins = updatedPins;
        }
    }

    clearPinsHistory() {
        this.pinsHistory = [];
        this.pinsHistoryIndex = 0;
    }

    addPinsSnapshot(pins) {
        const newSnapshot = this.getPinsSnapshot(pins || this.temporaryComment.pins);
        const currentSnapshot = this.pinsHistory[this.pinsHistoryIndex];

        if (newSnapshot === currentSnapshot) {
            return; // Prevents clearing multiple times adding multiple of the same history items.
        }

        this.pinsHistoryIndex++;
        this.pinsHistory = [...this.pinsHistory.slice(0, this.pinsHistoryIndex), newSnapshot];
    }

    setupProofPermissionsListener() {
        const that = this;
        this.proofPermissionsProps = {
            proofId: this.proofId,
            onEvent() {
                that.onUserProofPermissionsChanged();
            },
        };
    }

    setupCommentEvents() {
        const that = this;

        const commentEventsQueue = new Set();
        let numberOfSequentialEmptyRefreshes = 0;

        this.commentEventsProps = {
            proofId: this.proofId,
            onEvent(event) {
                const id = event.commentId || event.replyId;

                if (['CommentDeletedEvent', 'ReplyDeletedEvent'].includes(event.__typename)) {
                    const comment = that.comments.getCommentById(id, true);
                    that.removeComment(comment);
                } else {
                    commentEventsQueue.add(id);
                }
            },
        };

        const getRefreshedCommentIds = (data) => {
            const ids = [];
            Object.keys(data.Pages).forEach((pageNumber) => {
                data.Pages[pageNumber].Comments.forEach((comment) => {
                    ids.push(comment.CommentId);
                });
            });
            return ids;
        };

        let commentEventsTimeout = setTimeout(function poll() {
            if (commentEventsQueue.size) {
                that.$deferCommentRefresh()
                    .then(() => that.refreshComments((this.$$commentRefreshLast || this.loadTime).subtract(1, 'second')))
                    .then((data) => {
                        const refreshedIds = getRefreshedCommentIds(data);
                        refreshedIds.forEach((id) => commentEventsQueue.delete(id));
                        if (refreshedIds.length === 0) {
                            numberOfSequentialEmptyRefreshes++;
                        } else {
                            numberOfSequentialEmptyRefreshes = 0;
                        }
                        if (numberOfSequentialEmptyRefreshes >= 20) {
                            console.log('Stopping comment refresh polling - no changes for 20 attempts.');
                            commentEventsQueue.clear();
                            numberOfSequentialEmptyRefreshes = 0;
                        }
                        if (commentEventsTimeout) {
                            commentEventsTimeout = setTimeout(poll, 50);
                        }
                    });
                this.$$commentRefreshLast = moment();
            } else {
                commentEventsTimeout = setTimeout(poll, 50);
            }
        }, 50);

        this.beforeDestroy(() => {
            clearInterval(commentEventsTimeout);
            commentEventsTimeout = null;
        });
    }

    updateCommentPaneWidth(width) {
        this.commentPaneWidth = width;
        window.localStorage.setItem(COMMENT_PANE_WIDTH_KEY, width);

        if (this.canvas) {
            this.canvas.setOffsets({
                left: this.showComments ? -(width / 2) : 0,
            }, true, 500);
        }
    }

    /* function to handle skip message, will skip all dialog messages with this status*/
    onCheckboxToggle = () => {
        const proofStorage = this.$$.storageService('pageproof.app.proof.' + this.user.id + '.');
        const skipMessageStatusList = proofStorage.json('dialogs') || [];

        if (!this.skipDialogCheckboxProps.selected) {
            this.skipDialogCheckboxProps.selected = true;
            if (skipMessageStatusList.indexOf(this.dialog.type) === -1) {
                skipMessageStatusList.push(this.dialog.type);
            }
        } else {
            const index = skipMessageStatusList.indexOf(this.dialog.type);
            this.skipDialogCheckboxProps.selected = false;
            skipMessageStatusList.splice(index, 1);
        }

        if (skipMessageStatusList.length !== 0) {
            proofStorage.json('dialogs', skipMessageStatusList);
        } else {
            proofStorage.remove('dialogs');
        }
    }

    onInteractionModeChanged() {
        this.showHiddenPins();
        if (this.isCommenting) {
            this.isActivatedGridLines = false;
            switch (this.interactionMode) {
                case 'pin': {
                    this.hideText();
                     //-- resetting the drawing history and pin history--//
                    this.canvas.drawingInitiated = false;
                    this.clearPinsHistory()
                    this.pinsHistory.push('[]')
                     //-- resetting the drawing history and pin history--//
                    break;
                }
                case 'draw': {
                    this.enableDrawing();
                    this.hideText();
                    break;
                }
                case 'text': {
                    this.showText();
                    //-- resetting the drawing history and pin history--//
                    this.clearPinsHistory()
                    this.pinsHistory.push('[]')
                    this.canvas.drawingInitiated = false;
                    //-- resetting the drawing history and pin history--//
                    break;
                }
                case 'general': {
                    this.startCreateComment();
                    break;
                }
            }
        } else {
            this.hideText();
        }
        
        this.$$.$rootScope.isCreatingComment = this.isCommenting || this.isCreatingComment;
    }

    hideText() {
        if (this.canvas) {
            this.canvas.removeText();
        }
    }

    setProofDownloadButtonProps() {
        const that = this;
        const props = {
            status: null,
            downloadProgress: {
                // file id => percent
            },
            get files() {
                if (that.proof && that.proof.canDownload) {
                    return [that.proof.file];
                }
                return [];
            },
            get isExternalWeb() {
                if (that.proof && that.proof.fileType) {
                    return that.proof.fileType === 'external-Web';
                }
                return false;
            },
            get attachments() {
                const attachments = [];
                if (
                    that.proof &&
                    that.proof.pages &&
                    that.permissions &&
                    that.permissions.proofer.proofLevel.canDownloadAttachments
                ) {
                    that.proof.pages.forEach((page) => {
                        if (page.comments) {
                            page.comments.forEach((comment) => {
                                if (comment.attachments.length) {
                                    attachments.push(...comment.attachments);
                                }
                                if (comment.replies) {
                                    comment.replies.forEach((reply) => {
                                        if (reply.attachments.length) {
                                            attachments.push(...reply.attachments);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                return attachments.filter(attachment => !!attachment.id);
            },
            get type() {
                if (that.proof) {
                    return that.proof.getProofType();
                }
            },
            get importUrl() {
                if (that.proof) {
                    return that.proof.importUrl;
                }
            },
            onDownload: (fileIds) => {
                this.downloadFiles(fileIds, false, props, this.proof.title);
            },
        };

        return props;
    }

    initReactProps() {
        const that = this;
        this.proofDownloadButtonProps = this.setProofDownloadButtonProps();

        this.printOptionsButtonProps = {
            onSelection: (selection) => {
                selection === 'legacy' ? this.legacyPrint() : this.printOptions();
            },
            get role() {
              return that.proof && that.proof.role;
            },
        }

        this.shareButtonProps = {
            proofId: this.proofId,
            url: this.$$.$location.absUrl().split('?')[0] + '?referrer=share',
            get title() {
                if (that.proof) {
                    return that.proof.title;
                }
            },
            get canSendShareLink() {
                if (that.proof) {
                    return that.proof.canSendShareLink;
                }
            },
            get proofStatus() {
                return that.proof && that.proof.status;
            }
        }
    }

    openPdfPreview(blob) {
        this.adobeViewerApis = null;
        var adobeDCView = new AdobeDC.View({
            clientId: env('adobe_pdf_embed_api_key'),
            locale: window.__pageproof_i18n__.getLocale(),
        });

        const previewContent = { promise: blob.arrayBuffer() };
        const previewMetaData = { fileName: this.proof.title };
        const previewOptions = {
            embedMode: 'LIGHT_BOX',
            showDownloadPDF: false,
            showPrintPDF: false,
            showAnnotationTools: false,
            enableAnnotationAPIs: false,
            includePDFAnnotations: false,
        };

        let defaultZoomLevel;
        if (window.innerWidth < S_BREAK_M) {
            previewOptions.defaultViewMode = 'FIT_WIDTH';
        } else {
            const proofDimensions = this.proof.dimensions;

            // 0.2 is a random number taken off the ratio so that square-ish screens won't set the default zoom level
            if (proofDimensions && (proofDimensions.width/proofDimensions.height > (window.innerWidth/window.innerHeight - 0.2))) {
                defaultZoomLevel = 0.75;
            } else {
                previewOptions.defaultViewMode = 'FIT_PAGE';
            }
        }

        adobeDCView.previewFile({ content: previewContent, metaData: previewMetaData }, previewOptions)
            .then(adobeViewer => {
                adobeViewer.getAPIs()
                    .then(apis => {
                        if (defaultZoomLevel) {
                            apis.getZoomAPIs().setZoomLevel(defaultZoomLevel);
                        }

                        apis.gotoLocation(this.currentPage);
                        this.adobeViewerApis = apis;
                    })
            });

        const eventOptions = {
            listenOn: [ 
                AdobeDC.View.Enum.FilePreviewEvents.CURRENT_ACTIVE_PAGE,
                AdobeDC.View.Enum.Events.PDF_VIEWER_OPEN,
                AdobeDC.View.Enum.Events.PDF_VIEWER_CLOSE
            ],
            enableFilePreviewEvents: true,
        };

        const updateCurrentPage = debounce((pageNumber) => {
            this.currentPage = pageNumber;
            this.loadCurrentPagePreview();
        }, 500);

        const eventHandler = ({ type, data }) => {
            switch (type) {
                case AdobeDC.View.Enum.FilePreviewEvents.CURRENT_ACTIVE_PAGE:
                    // Wait for the viewer APIs to be available so that we don't update the current page before we change the preview page
                    if (this.adobeViewerApis) {
                        updateCurrentPage(data.pageNumber);
                    }
                    break;
                case AdobeDC.View.Enum.Events.PDF_VIEWER_OPEN:
                    this.isPdfPreviewOpen = true;
                    break;
                case AdobeDC.View.Enum.Events.PDF_VIEWER_CLOSE:
                    this.isPdfPreviewOpen = false;
                    break;
                default:
                    break;
            }
            
            if (!this.$$.$rootScope.$$phase) {
                this.$$.$rootScope.$apply();
            }
        }

        adobeDCView.registerCallback(AdobeDC.View.Enum.CallbackType.EVENT_LISTENER, eventHandler, eventOptions);
    }

    openPreview(blob) {
        // We do not want to open the preview if the blob finished downloading after the user has left the page
        if (this.$$destroyed) {
            return;
        }
        switch (this.proof.extension.toLowerCase()) {
            case 'pdf':
                this.openPdfPreview(blob);
                break;
            default:
                throw new Error(`Previewing proofs with the extension '${this.proof.extension}' is not yet supported`);
        }
    }

    previewFile = (fileId) => {
        if (this.fileBlob) {
            this.openPreview(this.fileBlob);
        } else {
            this.$$.SegmentIo.track(60, {
                'proof id': this.proofId,
                'file id': fileId,
            }); // Proof file downloaded for preview

            return this.downloadFiles([fileId], true, this.proofDownloadButtonProps);
        }
    }

    loadPreviewLibrary() {
        switch (this.proof.extension.toLowerCase()) {
            case 'pdf':
                const scriptElId = `adobe-pdf-preview-script`;

                if (!document.getElementById(scriptElId)) {
                    const scriptEl = document.createElement('script');
                    scriptEl.id = scriptElId;
                    scriptEl.crossOrigin = 'anonymous';
                    scriptEl.src = 'https://documentservices.adobe.com/view-sdk/viewer.js';
                    document.head.appendChild(scriptEl);
                }
                break;
            default:
                break;
        }
    }

    downloadFiles(fileIds, openAsPreview = false, props, zipFolderName) {
        if (props.status) {
            // If we're in the middle of downloading files, abort
            return;
        }

        const isFileIdOnly = fileIds.length === 1 && fileIds[0] === this.proof.fileId;

        // If it is only the file id we want to load the preview library now so that it's ready for use straight away
        if (isFileIdOnly || openAsPreview) {
            this.loadPreviewLibrary();
        }

        const files = {};
        const multiple = fileIds.length > 1;
        const dupes = {};

        fileIds.forEach((id) => {
            props.downloadProgress[id] = 0;
        });

        const createFinalFile = () => {
            if (multiple) {
                props.status = 'archiving';
                return this.$$.zipService.prepare()
                    .then(() => this.$$.zipService.compress(files));
            } else {
                return window.objectValues(files)[0];
            }
        };

        const getDownloadFileName = () => {
            if (multiple) {
                return zipFolderName + ' Files.zip';
            } else {
                return Object.keys(files)[0];
            }
        };

        const download = (blob) => {
            if (openAsPreview) {
                this.fileBlob = blob;
                this.openPreview(blob);
                return;
            }

            if (isFileIdOnly) {
                this.fileBlob = blob;
            }

            return window.downloadFile(getDownloadFileName(), blob);
        };

        function addFile(name, blob) {
            if (name in files) {
                dupes[name] = (dupes[name] || 1);
                const number = ++dupes[name];
                const lastDotIndex = name.indexOf('.');
                const before = name.substring(0, lastDotIndex);
                const after = name.substring(lastDotIndex);
                name = before + ' (' + number + ')' + after;
            }
            files[name] = blob;
        }

        const downloadOriginalFiles = () => {
            return this.$$.$q((resolve, reject) => {
                props.status = 'downloading';
                let downloadIndex = 0;
                let completeCount = 0;

                const next = () => {
                    if (downloadIndex >= fileIds.length) {
                        return;
                    }
                    const id = fileIds[downloadIndex++];
                    let name;
                    this.$$.sdk.files.details(id)
                        .then((fileDetails) => {
                            if (fileDetails.downloadable) {
                                name = fileDetails.name;
                                return this.$$.sdk.files.download({
                                    fileId: id,
                                    silent: openAsPreview,
                                    onProgress: (percent) => {
                                        props.downloadProgress[id] = percent;
                                        this.$$.$rootScope.$apply();
                                    },
                                })
                                .catch((originalErr) => {
                                    const err = new Error('Failed to download file: ' + originalErr);
                                    err.name = 'FileNotDownloadable';
                                    throw err;
                                });
                            } else {
                                const err = new Error(`${fileDetails.id} is not downloadable`);
                                err.name = 'FileNotDownloadable';
                                throw err;
                            }
                        })
                        .then((blob) => addFile(name, blob))
                        .then(() => {
                            if (++completeCount >= fileIds.length) {
                                resolve();
                            } else {
                                next();
                            }
                        })
                        .catch((error) => {
                            if (error.name === 'FileNotDownloadable' || error.response.code === 1000) {
                                props.downloadProgress[id] = -1;
                                if (++completeCount >= fileIds.length) {
                                    resolve();
                                } else {
                                    next();
                                }
                            } else {
                                reject();
                                throw error;
                            }
                        });
                };
                let multi = 4;
                while (multi--) {
                    next();
                }
            });
        };

        const cleanup = () => {
            props.status = null;
            props.downloadProgress = {};
        };

        return (
            downloadOriginalFiles()
                .then(createFinalFile)
                .then(download)
                .then(cleanup, cleanup)
        );
    }

    /**
     * Whether the proof's percentage is positive.
     *
     * @returns {Boolean}
     */
    get hasProgress () {
        return this.progressPercent >= 0;
    }

    /**
     * Returns the progress percentage (decimal).
     *
     * @returns {Number}
     */
    get progressPercent () {
        return this.proof ? this.proof.getProgressPercent() : -1;
    }

    /**
     *
     */
    initCommentRefreshConditions () {
        // Pass through the `enableCommentRefresh` flag as a comment refresh condition
        Object.defineProperty(this.$$commentRefresh, 'enableCommentRefresh', {
            get: () => this.flags.enableCommentRefresh
        });

        // Whether the proof object has been loaded in yet (this is due to the fact the comment refresh starts
        // immediately after the controller has been created - which in some cases is before the api has responded with
        // with the initial proof data.
        Object.defineProperty(this.$$commentRefresh, 'hasProof', {
            get: () => !! this.proof
        });
    }

    /**
     *
     */
    initHooks () {
        this.registerHook(
            this.$$.PPProofControllerHook.WHEN_COMMENT_REFRESH,
            (lastTime) => this.refreshComments(lastTime || this.loadTime)
        );
        
        if (this.$$.$routeParams.commentId) {
            // When all the comments have finished loading (initially)
            this.registerHookSingle(this.$$.PPProofControllerHook.DID_DECRYPT_COMMENTS, () => {
                this.$$.$scope.$applyAsync(() => {
                    const {commentId} = this.$$.$routeParams;
                    this.selectComment(this.comments.getCommentById(commentId), true).then((comment) => {
                        this.showComments = true;
                        this.$$.$timeout(() => {
                            this.commentsPane.scrollToComment(comment ? comment.id : commentId);
                        }, 750);
                    });
                });
            });
        }

        this.beforeDestroy(this.$$.proofInfoService.on('close', () => {
            this.refreshGroupProps();
            this.refreshProof();
            this.refreshDecisionSummary();
        }));
    }

    /**
     *
     */
    initWatchers () {
        this.beforeDestroy(this.$watch('version', (proofId) => {
            if (proofId !== this.proofId) {
                this.unjail();
                this.$$.$location.url('proof-screen/' + proofId);
            }
        }));

        this.beforeDestroy(this.$watch('showComments', (showComments) => {
            if ( ! showComments) {
                if (this.comments) {
                    this.comments.unselectComment();
                }
            }
        }));

        this.beforeDestroy(this.$watch('isCommenting', (isCommenting) => {
            if (
                isCommenting &&
                (
                    this.temporaryComment &&
                    this.temporaryComment.pins.length &&
                    this.temporaryComment.pins[0].fill &&
                    this.temporaryComment.pins[0].fill.type === 'text'
                )
            ) {
                if (this.isCreatingComment && this._reactCreateCommentContainerRef) {
                    this._reactCreateCommentContainerRef.onCreate();
                }
                this.cancelCreateComment();
                this._reactCreateCommentContainerRef = null;
                this.isCommenting = true;
            }
        }));

        this.$$.$rootScope.$on('toggleDownloadOnProof', (vars, args) => {
            if (this.proof.id === args.proofId && this.proof.fileId === args.fileId) {
                this.toggleDownloadAbility(args.canDownload);
            }
        });

        this.beforeDestroy(this.$watch('isCommenting', () => {
            this.onInteractionModeChanged();
        }));

        this.beforeDestroy(this.$$.eventService.on(window, 'resize', () => {
            const wasSmallScreen = this.isSmallScreen;
            this.isSmallScreen = window.innerWidth <= 750;

            if (wasSmallScreen !== this.isSmallScreen) {
                this.setOrUpdateProofVersions();
                this.setVersionsInHeader(!!this.props);
                this.setHeader();
            }
        }));
    }

    /**
     *
     */
    initKeyboardShortcuts () {
        // Toggle the `isCommenting` state (default key binding: c)
        this.beforeDestroy(this.$$.shortcutService.watch('commentTool', () => {
            if (this.permissions.proofer.commentLevel.canCreate && !this.progress && !this.$$.domService.isFullScreen()) {
                this.isCommenting = ! this.isCommenting;
            }
        }));

        // Mark the selected comment status as to-do/done (default key binding: `)
        this.beforeDestroy(this.$$.shortcutService.watch('markComment', () => {
            this.changeCommentStatusByShortcutKey();
        }));

        // Toggle the `interactionMode` state (default key binding: shift+c)
        this.beforeDestroy(this.$$.shortcutService.watch('switchPen', () => {
            if (this.permissions.proofer.commentLevel.canCreate && !this.$$.domService.isFullScreen() && this.isCommenting) {
                const tools = getCommentTools(this.textSelectionAvailable, this.freeDrawingAvailable, this.proof.canHaveGeneralComments);
                const nextIndex = (tools.indexOf(this.interactionMode) + 1) % tools.length;
                this.interactionMode = tools[nextIndex];
                this.onInteractionModeChanged();
            }
        }));

        // Toggle the `showComments` state (default key binding: <, >)
        this.beforeDestroy(this.$$.shortcutService.watch('commentPane', () => {
            this.showComments = ! this.showComments;

            if ( ! this.proof.commentCount && ! this.isCreatingComment) {
                // Prevent the comment pane from showing using the keyboard shortcut
                this.showComments = false;
            }
        }));

        // Toggle the `showPins` state (default key binding: \)
        this.beforeDestroy(this.$$.shortcutService.watch('togglePins', () => {
            this.togglePins();
        }));

        // Toggle the `focusMode` state (default key binding: f)
        this.beforeDestroy(this.$$.shortcutService.watch('focusMode', () => {
            this.toggleFocusMode();
        }));

        // Toggle the `showShortcuts` state (default key binding: shift+?)
        this.beforeDestroy(this.$$.shortcutService.watch('shortcuts', () => {
            this.showShortcuts = ! this.showShortcuts;
        }));

        // Jump to the next page in the proof (default key binding: [right])
        this.beforeDestroy(this.$$.shortcutService.watch('nextPage', () => {
            if (this.canNavigatePage()) {
                this.nextPage();
            }
        }));

        // Jump to the previous page in the proof (default key binding: [left])
        this.beforeDestroy(this.$$.shortcutService.watch('previousPage', () => {
            if (this.canNavigatePage()) {
                this.previousPage();
            }
        }));

        // Open/close the proof info pane (default key binding: i)
        this.beforeDestroy(this.$$.shortcutService.watch('proofInfo', () => {
            this.toggleProofInfo();
        }));

        // Toggle the 'invert pin colour' state (default key binding: * (ctrl+8))
        this.beforeDestroy(this.$$.shortcutService.watch('invertPins', () => {
            angular.element('.slide').toggleClass('inverted-pins');
        }));

        // Toggle the scroll mode (static proofing) (default key binding: z)
        this.beforeDestroy(this.$$.shortcutService.watch('scrollMode', () => {
            this.toggleScrollMode();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('printProof', () => {
            this.printOptions();
        }));

        // Toggle the ruler mode (static proofing) (default key binding: -)
        this.beforeDestroy(this.$$.shortcutService.watch('ruler', () => {
            if (this.proof.measurementUnits) {
                this.onChangeRulerMode(!this.isActivatedRuler);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('marqueeZoom', () => {
            this.onChangeMarqueeZoomMode(!this.isActivatedMarqueeZoom);
        }));

        // Toggle the gridlines mode (static proofing) (default key binding: -)
        this.beforeDestroy(this.$$.shortcutService.watch('gridlines', () => {
            this.onChangeGridLinesMode(!this.isActivatedGridLines);
        }));

        // Toggle the 'invert pin colour' state (default key binding: * (ctrl+8))
        this.beforeDestroy(this.$$.shortcutService.watch('invertPins', () => {
            this.invertPins();
        }));

        // Toggle the view of the collection pane (default key binding: shift+left)
        this.beforeDestroy(this.$$.shortcutService.watch('previousCollectionProof', () => {
            if (this.group) {
                this.previousCollectionProof();
            }
        }));

        // Toggle the view of the collection pane (default key binding: shift+right)
        this.beforeDestroy(this.$$.shortcutService.watch('nextCollectionProof', () => {
            if (this.group) {
                this.nextCollectionProof();
            }
        }));
    }

    initCanvasKeyboardShortcuts() {

        // Toggle pages (default key binding: p)
        this.beforeDestroy(this.$$.shortcutService.watch('togglePages', () => {
            if (this.canNavigatePage()) {
                this.togglePages();
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('outline', () => {
            this.toggleOutline();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomIn', () => {
            this.zoomIn();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomOut', () => {
            this.zoomOut();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nudgeZoomIn', () => {
            this.nudgeZoomIn();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nudgeZoomOut', () => {
            this.nudgeZoomOut();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomFit', () => {
            this.fit();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom100', () => {
            this.zoomTo(100);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom200', () => {
            this.zoomTo(200);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom400', () => {
            this.zoomTo(400);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('rotate', () => {
            this.rotate();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('rotateCC', () => {
            this.rotate(true);
        }));
    }

    changeCommentStatusByShortcutKey() {
        const selectedComment = this.comments.selectedComment;
        if (!selectedComment) return;

        const nextCommentStatusType = this.$getNextCommentStatusType(this.permissions, selectedComment);
        if (nextCommentStatusType) {
            this.markComment(selectedComment, nextCommentStatusType.type, nextCommentStatusType.state);
        }
    }

    initDownloadState () {
        this.downloadSupported = (
            (!this.$$.browserService.is('safari') && !this.$$.browserService.is('mobile')) ||
            (this.$$.browserService.is('safari') && parseFloat(this.$$.browserService.version) >= 10.1)
        );
    }

    togglePins() {
        this.showPins = ! this.showPins;
        if (this.canvas) {//checks if there is canvas, if file is not static 'showPins' toggle is enough
            this.canvas.toggleEnablePins();
        }
    }

    showHiddenPins() {
        if (!this.showPins) {
            this.showPins = true;
            if (this.canvas) {
                this.canvas.toggleEnablePins();
            }
        }
    }

    redirectToCorrectProofPage(proof) {
        if (this.$$destroyed) {
            throw new Error('Tried to redirect to correct proof page after the user left the page.');
        }
        let proofUrl = proof.getUrl();

        if (this.$$.$location.search()[CREATED_AS_DRAFT_SEARCH_PARAM_NAME]) {
            if (!proof.workflowId && (proof.isOwnerOrCoOwner || (proof.isCurrentUserInProofTeam && this.user.isDomainAdmin))) {
                this.$$.$location.url('/proof-setup/' + proof.id).replace();
                return;
            }

            this.$$.$location.search(CREATED_AS_DRAFT_SEARCH_PARAM_NAME, null);
        }

        if (proof.hasFileError) {
            this.$redirectErrorPage('access/12'); // file errored proof page message
            return this.$$.$q.reject(null);
        } else if (['Created', 'Queued', 'Ripping'].includes(proof.fileStatus)) {
            this.$redirectErrorPage('access/16')
            return this.$$.$q.reject(null);
        }  else if (proof.hasProgress) {
            this.redirectTo('dashboard');
            return this.$$.$q.reject(null);
        } else if (this.$$.$location.path() !== proofUrl) {
            this.$$.$location.path(proofUrl).replace();
            return this.$$.$q.reject(null); // Prevent the page from continuing to load
        }
        return proof;
    }

    preLoadChecks() {
        return true;
    }

    _loadCustomTools() {
        this.$$.sdk.proofs.customTools.load(this.proofId)
            .then((customTools) => {
                this.customTools = customTools;
                this.hasCustomTools = Object.keys(customTools).length !== 0;
            });
    }

    /** 
     * Reloads the proof object, recalculates permissions and updates relevant components.
     * Call when proof changes affect user permissions (ie, added as owner, proof archived, etc...)
     */
    onUserProofPermissionsChanged () {
        this.$$.proofCacheService.getProof(this.proofId, this.onProofObjectUpdate)
            .then((proof) => {
                this.proof = proof;
                this.loadPermissionsActionButton();
            });
    }

    // Updating UI with latest proof object, while keeping old page-comments-preview.
    onProofObjectUpdate = (proof) => {
        if (this.$$destroyed) {
            return;
        }
        // Keeping cached pages data, so that no need to fetch comments again.
        const pagesFromCache = this.proof.pages;
        const previousFileId = this.proof.fileId;
        
        this.proof = proof;
        this.proof.pages = pagesFromCache;

        if (this.proof.fileId !== previousFileId) {
            // File has been changed (means no decisions or comments has been made), then refresh the page
            window.location.reload();
        }

        this.openDialog();
        this.whenCommentMarkStateHasChanged();
        this.setOrUpdateProofVersions();
        this.setHeader();
        this.setVersionsInHeader(true);
        // Loading the page preview here is required to fix a bug.
        // Bug: Navigating to the second page of a proof after it is refreshed from cache breaks everything.
        // A better solution would be better but the code is very flakey and would require a lot more investigation/testing
        if (this.proof.pages.length > 1) {
            this.loadPagePreview(this.proof.pages[this.currentPage - 1]);
        }
        if (this.proof.groupId) {
            this.loadGroupProofs(this.proof.groupId);
        }
        // Fixes a bug where the mentions suggestions were not being updated properly on the comments pane.
        this.populateMentionData(this.proof, this.user);
    }

    setOrUpdateProofVersions() {
         // Set the versions array (for populating the versions select menu)
         this.props.versions = Object.keys(this.proof.versions).map((id) => {
          let name;
          let versionNumber;
          if (!this.proof.versions[id].versionNumber) {
              name = 'proof-comparison.dropdown.brief';
          } else {
              name = this.isSmallScreen ? 'proof-comparison.dropdown.version-mobile' : 'proof-comparison.dropdown.version',
              versionNumber = this.proof.versions[id].versionNumber;
          }
          return { value: id, name, versionNumber, canAccess: this.proof.versions[id].canAccess };
         });
    }

    load () {
        this.$$.appService.showTranslatedLoaderMessage('loader-message.decrypting-proof');

        return (
            this.$$.proofCacheService.getProof(this.proofId, this.onProofObjectUpdate)
                .then(proof => {
                    // Remove proof promise from cache if proof file is still processing
                    if (['Created', 'Queued', 'Ripping'].includes(proof.fileStatus)) {
                        this.$$.proofCacheService.removeCachedProof(this.proofId);
                    }
                    
                    this.$$.features.setProof(proof.featureFlags).update();
                    return proof;
                })
                .then(proof => (
                    this.redirectToCorrectProofPage(proof)
                ))
                .then((proof) => {
                    this.proof = proof;

                    if (proof.publicProofKeyPair) {
                      this.beforeDestroy(this.$$.sdk.keyPairs.addKeyPair(proof.publicProofKeyPair));

                      // If the video data failed to load originally, it's likely due to the user's key not being on the proof.
                      // This happens for unlisted reviewers, as the proofRepositoryService attempts to decrypt the video data
                      // before the controller has a chance to add the public proof's custom keypair to the SDK.
                      if (proof.isVideo && !proof.video) {
                          return this.$$.proofRepositoryService.$getVideoByProofId(proof.id, proof)
                              .then(data => {
                                  // Normally not required to assign video object back to the proof object, 
                                  // but due to time race issue, sometimes this.proof gets reference from the cached proof object, and video object does not get attached
                                  this.proof.video = data.video;
                              });
                      }
                  }
                })
                .then(() => this.preLoadChecks())
                .then(supported => {
                    if (supported === false) {
                        return this.$$.$q.reject(null);
                    }
                })
                .then(() => {
                    if (this.proof.lockerId) {
                      this.$$.userRepositoryService
                        .get(this.proof.lockerId)
                        .then((user) => {
                          this.$$.$scope.lockerEmail = user.email;
                        });
                    }

                    // Open dialog if proof is not fetched from cached.
                    // Other wise it will show pop up later on once gets latest proof object from api.
                    if (!this.proof.isFromCache) {
                        this.openDialog();
                    }
                    this.$$.proofRepositoryService.$registerProofHasSeen(this.proofId); //send server request that proof has been opened

                    this._loadCustomTools();

                    let that = this;

                    this.props = {
                        proofId: this.proofId,
                        onSwitchVersion: this.onSwitchVersion,
                        versions: [],
                        get direction() {
                            return that.isSmallScreen ? 'down' : 'up';
                        },
                    };
                    this.setOrUpdateProofVersions();

                    this.lockProps = {
                        get isLocked() {
                            return that.proof ? that.proof.isLocked : false;
                        },
                        get isLockVisible() {
                            return that.isLockVisible();
                        },
                        get canUnlock() {
                            return that.proof ? that.proof.canUnlock() : false;
                        },
                        get canRequest() {
                            return that.proof ? (that.proof.canRequestUnlock() && !that.hasRequestedUnlock()) : false;
                        },
                        get isRequestPending() {
                            return that.hasRequestedUnlock();
                        },
                        get hasRequested() {
                            return that.proof ? !that.proof.canUnlock() && that.hasRequestedUnlock() : false;
                        },
                        onUnlock: that.$unlockProof,
                        onLock: that.$lockProof,
                        onUnlockRequest: that.$requestUnlockProof,
                    };

                    this.skipDialogCheckboxProps = {
                        selected: false, //to do take last selected value or false
                        onChange: () => this.onCheckboxToggle(),
                    };

                    // Doesn't matter that nothing has 'changed' initialise the user button/action
                    // permission settings and count the number of comments (to-dos & dones)
                    this.whenCommentMarkStateHasChanged();

                    if (this.proof.workflow) {
                        this.userFinishedStatus = this.calculateReviewers(this.proof.workflow.steps);
                        this.totalComplete = this.userFinishedStatus.finishedReviewersListLength;
                        this.totalReviewers =  this.userFinishedStatus.usersListLength;
                        this.isOnWorkflow =  this.userFinishedStatus.isOnWorkflow;
                    }

                    // Set page title/seo stuff
                    this.setHeader();
                    this.setVersionsInHeader(true);

                    // Opens comment pane if proof is in status 50 (to do's requested for all users)
                    this.showCommentPane(this.proof.status === this.$$.PPProofStatus.CHANGES_REQUESTED || this.filter.name);

                    // Display the proof's owner's custom branding (logo)
                    this.$$.$rootScope.logos.proof = this.proof.brandingUrl || '/img/logo/pageproof-logo_2x.png';
                    this.putInJail();

                    if (this.proof.groupId) {
                        this.loadGroupProofs(this.proof.groupId);
                    }

                    this.storeInRecentProofs();

                    this.decisionButtonProps = {
                        get cannotLeaveDecision() {
                            // Disable the decision button if the user is currently an unlisted reviewer however they're also in the workflow - meaning they're in a future step. Until their step becomes visible, they are not permitted to leave a decision.
                            return !!(
                                that.proof.role === 'unlisted-reviewer' &&
                                that.proof.workflow &&
                                that.proof.workflow.steps &&
                                that.proof.workflow.steps.some(step => (
                                    step.users.some(user => user.id === that.user.id)
                                ))
                            );
                        },
                        get canSendChanges() {
                            return !!that.proof.commentCount;
                        },
                        get hasApprovedWithChangesDecision() {
                            return that.proof.allowedDecisions.includes(window.__pageproof_quark__.sdk.Enum.Decision.APPROVED_WITH_CHANGES);
                        },
                        onDecision: that.sendDecision,
                        onClick: that.onDecisionClick,
                    }

                    this.changeDecisionButtonProps = {
                        get proof() {
                          return that.proof;
                        },
                        onDecisionChange: that.changeDecision,
                    }

                    this.nextProofProps = {
                        proof: null,
                        visible: false,
                        onClose: that.setNextProofTileVisibility,
                    };

                    this.cacheProof(this.proof, this.proof.id);
                })
                .catch((err) => {
                    // Handle the load proof error logic
                    this.$handleLoadProofError(err);
                    return this.$$.$q.reject(null);
                })
                .finally(() => {
                    // Hide the application (global) loader (and message)
                    this.$$.appService.hideLoader();
                })
        );
    }

    cacheProof(proof, id) {
      this.$$.proofCacheService.cacheProofPromise(proof, id);
    }

    refreshDecisionSummary() {
        if (this.angularRef.current) { // It is null in cases where decision summary component never got mounted for ex- when decisions are disabled, or proof has a newer version
            this.angularRef.current.refreshDecisionSummary();
        }
    }

    showSkipAll() {
        return window.generalfunctions_canSkipAll(this.proof.status, this.proof.workflow !== null && this.proof.workflow.steps[this.proof.workflow.steps.length - 1].isVisible);
    }

    getDecisionSummaryProps() {
        const proof = this.proof;
        const currentUser = this.$$.userService.getUser();

        const isTeamAdmin = (
            proof.teamId === currentUser.teamId &&
            currentUser.isDomainAdmin
        );

        return {
            proofId: this.proofId,
            workflowId: proof.workflowId,
            angularRef: this.angularRef,
            isOverdue: proof.isOverdue,
            shouldFireConfetti: this.proofHasLoaded() && this.proof.hasApproved,
            canNudgeUsers: (
                (proof.isOwnerOrCoOwner || isTeamAdmin) &&
                [this.$$.PPProofStatus.NEW, this.$$.PPProofStatus.PROOFING, this.$$.PPProofStatus.FINAL_APPROVING].includes(this.proof.status)
            ),
            onAction: () => {
                this.setHeader();
            },
            canSkipAll: this.showSkipAll(),
        };
    }

    setHeader() {
        this.setHeaderStatus();
        this.setProofScreenMobileMenuProps();
        this.$$.seoService.set({
            hasDecisionsEnabled: this.canShowDecisionsInHeader(),
            decisionSummaryProps: this.getDecisionSummaryProps(),
            title: this.proof.title,
            pageTitle: this.showCollection
              ? 'collection.title'
              : this.isSmallScreen
                ? ''
                : this.proof.title,
            translateTitle: false,
            translatePageTitle: this.showCollection,
            pageSubTitle: this.showCollection ? this.group.name : '',
            totalComplete:  this.totalComplete,
            totalReviewers: this.totalReviewers,
            canShowReviewerCount: this.canShowHeaderStatus(),
            hasNewerVersion: this.proof.status === 66,
            isOnWorkflow: this.isOnWorkflow,
            statusText: this.statusText,
            statusClass: this.statusClass,
            approvalDate: this.proof.hasApproved,
            isOnCollection: this.showCollection,
        });
    }

  canShowDecisionsInHeader = () => (
      !this.showCollection &&
      this.proof.hasDecisionsEnabled &&
      !window.isBriefId(this.proofId)
  );

    setVersionsInHeader(bool) {
        this.$$.$rootScope.$broadcast('headerVersions', {
            enable: bool && this.isSmallScreen,
            versionProps: this.props,
            toggleProofInfo: this.toggleProofInfo,
        });
    }

    toggleFocusModeInHeader(bool) {
        this.$$.$rootScope.isFocusMode = bool;
        if (!this.$$.$rootScope.$$phase) { this.$$.$rootScope.$apply(); }
    }

    canShowHeaderStatus() {
        return (this.canShowReviewerCount || this.proof.hasApproved) &&
                !this.showCollection;
    }

    hasRequestedUnlock = () => {
        return this.proof ? this.proof.isUnlockRequestPending : false;
    }

    isLockVisible = () => {
        return this.proof
                && this.proof.status === this.$$.PPProofStatus.FINAL_APPROVING
                && this.proof.workflow
                && this.proof.workflow.steps.length > 1
                && (this.proof.isLocked || this.canLock());
    }

    canLock() {
        return (this.isApproverOrGatekeeper() || this.proof.isOwnerOrCoOwner) && !this.proof.isLocked;
    }

    onDecisionClick = () => {
        this.handleUnsavedComment();
    }

    sendDecision = (decisionObj) => this.sendOrChangeDecision(decisionObj, 'finish');

    changeDecision = (decisionObj) => this.sendOrChangeDecision(decisionObj, 'updateDecision');

    setNextProofTileVisibility = (bool) => this.nextProofProps.visible = bool;

    sendOrChangeDecision = (decisionObj, reqType) => {
        this.loadNextProof();

        const userType = this.$permissions.generalObj.userType;
        const decisionState = window.__pageproof_quark__.sdk.Enum.Decision;
        const decision = decisionState[decisionObj.id.toUpperCase()];
        let promise;

        switch (userType) {
            case 'unlistedReviewer':
                promise = this.$$.sdk.proofs.decisions.leaveDecision(this.proofId, decision);
                break;
            default:
                promise = this.$$.sdk.proofs[reqType](this.proofId, decision);
                break;
        }

        return promise
            .then(() => this.updateProof())
            .then(() => this.loadPermissionsActionButton())
            .then(() => this.setHeader())
            .then(() => this.showNextProof())
            .then(() => {
                if (!this.nextProofProps.proof && !this.proof.canManage) { // Don't redirect to dashboard if user is one of the owner
                    this.unjail();
                    this.$actions.redirectTo("dashboard");
                }
            });
    }

    setHeaderStatus() {
        let statusText = '';
        let statusClass = '';
        const totalComplete = this.totalComplete;
        const totalReviewers = this.totalReviewers;
        const canShowReviewerCount = totalReviewers > 0 && (this.proof.status === 10 || this.proof.status === 30);

        if (totalComplete === totalReviewers) {
            if (this.isOnWorkflow) {
                if (totalReviewers === 1) {
                    statusText = "in-the-flow.finished.one";
                } else {
                    statusText = "in-the-flow.finished";
                }
            } else {
                statusText = "out-of-flow.finished";
            }
            statusClass = 'green';
        }
        if (totalComplete === 0) {
            if (this.isOnWorkflow) {
                if (totalReviewers === 1) {
                    statusText = "in-the-flow.not-finished.one";
                } else {
                    statusText = "in-the-flow.not-finished.several";
                }
            } else {
                if (totalReviewers === 1) {
                    statusText = "out-of-flow.not-finished.one";
                } else {
                    statusText = "out-of-flow.not-finished.several";
                }
            }
            statusClass = 'orange';
        }
        if (totalComplete > 0 && totalComplete < totalReviewers) {
            if (this.isOnWorkflow) {
                if (totalComplete === 1) {
                    statusText = "in-the-flow.partially-finished.one";
                } else {
                    statusText = "in-the-flow.partially-finished.several";
                }
            } else {
                if (totalComplete === 1) {
                    statusText = "out-of-flow.partially-finished.one";
                } else {
                    statusText = "out-of-flow.partially-finished.several";
                }
            }
            statusClass = 'grey';
        }
        if (this.proof.hasApproved) {
            statusText = "approved";
            statusClass = 'green';
        }
        if (this.proof.status === 66) {
            statusText = "has-new-version";
            statusClass = 'orange';
        }
        this.statusText = statusText;
        this.statusClass = statusClass;
        this.canShowReviewerCount = canShowReviewerCount;
    }

    /**
    * Calculate how many reviewers are complete and are in the workflow steps that are visible minus one,
    * because that will be the reviewer who is being asked to lock the proof (if there is more than 1 step)
    *
    * @param {PPProofWorkflowStep[]} steps
    * @returns {Number}
    */
    calculateReviewers(steps) {
        let isOnWorkflow = false;
        const currentUserId =  this.user.id;
        let usersList = [];
        let finishedReviewersList = [];
        steps.map((step) => {
            if (step.isVisible) {
                step.users.map(function(user) {
                    if (user.id !== currentUserId) {
                        usersList.push(user);
                        if (user.isComplete) {
                            finishedReviewersList.push(user);
                        }
                    } else {
                        isOnWorkflow = true;
                    }
                });
            }
        });
        let usersReviewObject = ({
            usersListLength: usersList.length,
            finishedReviewersListLength: finishedReviewersList.length,
            isOnWorkflow: isOnWorkflow,
        });
        return usersReviewObject;
    }

    visibleCommentCount() {
        if (!this.proof) {
            return 0;
        }
        if (this.filter) {
            const filterType = this.filter.args[0];
            switch (this.filter.name) {
                case 'unmarked': return this.proof.unmarkedCount;
                case 'todo': return this.proof.todoCount;
                case 'done': return this.proof.doneCount;
                case 'attachments': return this.proof.attachmentCount;
                case 'agrees': return this.proof.agreeCount;
                case 'noAgrees': return this.proof.notAgreeCount;
                case 'label': return (this.proof.labelCount && (this.proof.labelCount[filterType]));
                case 'page': return 0;
                case 'pages': return this.proof.pages[filterType - 1].comments.length;
                case 'user': return 0; // unknown
                case 'mention': return 0; // unknown
                case 'search': return 0; // unknown
            }
        }
        return this.proof.commentCount;
    }

    checkUserDismissedGettingStarted() {
        this.gettingStarted = false;
        if (this.gettingStartedKey) {
            this.gettingStarted = !(this.$$.storageService().json(this.gettingStartedKey) || window.__pageproof_quark__.cookies.get(this.gettingStartedKey, false)) && !window.isBriefId(this.proofId);
        }
    }

    openCommentsPaneIfRequired() {
        // Opens comment pane if there are comments and
        // if proof is in status 50(to do 's requested for all users)
        // Or status 30 and user is approver or gatekeeper
        // or if any filter is on for comments
        if (this.proof.commentCount &&
            (this.proof.status === this.$$.PPProofStatus.CHANGES_REQUESTED ||
            this.filter.name ||
            this.proof.status === this.$$.PPProofStatus.FINAL_APPROVING && this.isApproverOrGatekeeper())) {
            this.showCommentPane(true);
        }
    }

    dismissGettingStarted() {
        window.__pageproof_quark__.cookies.set(this.gettingStartedKey, 1);
        this.gettingStarted = false;
    }

    openDialog() {
        // Open up any dialog messages when the proof has finished loading
        this.$whenProofDataLoaded(this.proof, this.user);
    }

    didLoad() {
        return this.$loadComments(this.proof)
            .then(() => this.$$commentRefresh.hasLoaded = true)
            .then(() => this.populateMentionData(this.proof, this.user))
            .then(() => this.proofLoaded = true)
            .then(() => this.handleRequestToBeAdd())
            .then(() => this.populateMentionedUsers(this.proof))
            .then(() => this.whenCommentMarkStateHasChanged())
            .then(() => this.openCommentsPaneIfRequired())
            .then(() => this.decryptComments());
    }

    refreshProof() {
        return this.refreshComments(this.$$commentRefreshLast || this.loadTime)
            .then(() => this.$updateProof(this.proof))
            .then(() => this.loadPermissionsActionButton())
            .then(() => !this.$$destroyed ? this.setHeader() : null)
            .then(() => this.populateMentionData(this.proof, this.user));
    }

    toggleDownloadAbility (canDownload) {
        this.proof.canDownload = canDownload;
    }

    getDescription () {
        const reviewerDesc = 'navigate-away.reviewer-description';
        const approverDesc = 'navigate-away.approver-description';
        const gatekeeperDesc = 'navigate-away.gatekeeper-description';
        let result = {};
        switch (this.$permissions.generalObj.userType) {
            case 'finalApprover':
            case 'proofCoOwnerPlusFinalApprover':
            case 'prooferOwnerPlusFinalApprover':
                result.desc = approverDesc;
                break;
            case 'approverProofer':
            case 'proofOwnerPlusApproverProofer':
            case 'proofCoOwnerPlusApproverProofer':
                result.desc = gatekeeperDesc;
                break;
            default:
                result.desc = reviewerDesc;
        }
        const owners = this.proof.getOwners();
        let lastOwner = '';
        if (owners.length > 1) {
            result.desc = result.desc + '.multi';
            lastOwner = owners[owners.length - 1];
            owners.pop();
        }
        result.variables = {
            'owner': this.proof.ownerUser.name || this.proof.ownerUser.email,
            'owners': owners.map(user => user.name || user.email).join(', '),
            'lastOwner': lastOwner.name || lastOwner.email,
        };
        return result;
    }

    putInJail () {
        this.$$.$scope.$watch(() => this.button, (button) => {
            const proofStorage = this.$$.storageService('pageproof.app.proof.' + this.user.id + '.');
            const skippedDialogs = proofStorage.json('dialogs') || [];
            const isCriminal = this.proof
                ? (this.proof.status === 10 ||
                    (this.proof.status === 30 && this.proof.isLocked && this.proof.lockerId === this.user.id) ||
                    (this.proof.status === 30 && !this.proof.isLocked))
                    && window.isProofId(this.proofId) && !this.$permissions.permissionObj.proofLevel.isEditor
                    && !this.$permissions.permissionObj.proofLevel.isViewOnlyReviewer
                    && skippedDialogs.indexOf('navigate-away') === -1
                : false;
            const { desc, variables } = this.getDescription();
            if (isCriminal) {
                if (this.jail) {
                    this.jail();
                    this.jail = null;
                }
                if (button && button !== this.$$.PPProofButtonState.DECISION_GIVEN) {
                    this.jail = this.$$.backgroundService.createNavigation({
                        title: 'navigate-away.have-you-finished',
                        description: desc,
                        variables,
                    });
                    this.beforeDestroy(this.jail);
                }
            }
        });
    }

    /**
     * Stop firing navigate away message before changing page url
     */
    unjail (result) {
        if (!result) {
            if (this.jail) {
                this.jail();
                this.jail = null;
            }
            // prevents the promise chain from continuing as if we're still handling the action.
            return this.$$.$q.reject(null);
        }
    }

    /**
     * Load the users permissions on the proof & the action/button.
     *
     * @see {BaseProofController.$loadPermissionsActionButton}
     */
    loadPermissionsActionButton() {
        if (this.flags.updatePermissions) {
            this.$loadPermissionsActionButton(this.proof, this.user)
                .then(({ $permissions, $actions, permissions, action, button }) => {
                    this.$permissions = $permissions;
                    this.$actions = $actions;
                    this.permissions = permissions;
                    this.action = action;
                    this.button = button;
                    if (!this.button) {
                        this.unjail();
                    }
                });
        }
    }

    /**
     * Updates the proof object internally.
     *
     * @returns {$q<PPProof>}
     */
    updateProof () {
        return this.$updateProof(this.proof);
    }

    /**
     * Decrypts all the comments & replies.
     *
     * Note: Replies are scheduled after comments are decrypted, so the UI initially shows all top level
     * comments (making the user perceive the page has finished loading).
     */
    decryptComments () {
        let comments = [],
            replies = [];

        this.proof.pages.forEach((page) => {
            page.comments.forEach((comment) => {
                if (comment.hasDecrypted()) {
                    comments.push(comment);
                }

                if (comment.isParent) {
                    comment.replies.forEach((reply) => {
                        if (reply.hasDecrypted()) { // Don't double decrypt
                            replies.push(reply);
                        }
                    });
                }
            });
        });

        if (comments.length + replies.length) {
            console.debug('Scheduled %s comment(s) for decryption...', comments.length + replies.length);
        }

        // Invoke the base proof controller's decrypt comments helper method
        return this.$decryptCommentsByPriority(this.encryptionData, [...comments, ...replies]);
    }

    /**
     * Adds a comment to the proof page object.
     *
     * @param {PPProofComment} comment
     * @param {PPProofPage} page
     */
    addComment (comment, page) {
        // Add the comment to the comments array
        let comments = page.comments,
            index = comments.indexOf(comment);

        if (index === -1) {
            comments.unshift(comment);
        }
    }

    /**
     * Removes a comment from the proof page object.
     *
     * @param {PPProofComment} comment
     */
    removeComment(comment) {
        let page = this.proof.getPage(comment.pageNumber);
        this.$removeComment(comment, page);
    }

    handleChangesAfterCommentRemoval() {
        this.whenCommentMarkStateHasChanged();
        this.populateMentionedUsers(this.proof);
        this.hideCommentPane();
    }

    /**
     * Delete a comment from the proof.
     *
     * @param {PPProofComment} comment
     */
    deleteComment (comment) {
        this.$$.SegmentIo.track(35, {
            'proof id': this.proofId,
            'comment id': comment.id,
        }); // Delete Comment

        this.$stallCommentRefresh(
            () => (
                // Sends a request to the server to delete
                this.$deleteComment(comment)
                    .then((response) => {
                        if (response.data.ResponseStatus === 'ERROR_NO_VALID_DATA') {
                            this.refreshComments(this.$$commentRefreshLast);
                        } else {
                            this.removeComment(comment);
                        }
                    })
            ),
            () => {
                this.handleChangesAfterCommentRemoval();
            }
        );
    }

    /**
     * Delete an attachment from a comment.
     *
     * @param {PPProofCommentAttachment, PPProofCOmment} attachment, comment
     */
    deleteAttachment (attachment, comment) {
        this.$deleteAttachment(attachment, comment);
        comment.attachments = comment.attachments.filter(attachmentValue => attachmentValue !== attachment);
        comment.$message = null;
    }

    /**
     * Creates a new comment.
     *
     * @param {PPProofComment} comment
     */
    createComment (comment) {
        this.$stallCommentRefresh(
            () => {
                const autoTodo = !comment.isPrivate && 
                    (
                        // if it is a brief in status 0 or 10 Or
                        // user is ownerOrCo-owner and status is not 0 || 10
                        // If user is the final approver and proof is locked, automatically mark comment as to-do Or
                        (this.proof.isOwnerOrCoOwner && this.proof.status > this.$$.PPProofStatus.PROOFING)
                        || (this.isApproverOrGatekeeper() && this.proof.status === this.$$.PPProofStatus.FINAL_APPROVING)
                        || this.isNewBriefOrBriefing()
                        // || this.isGatekeeperAndLocked()
                        // || this.isApproverAndLocked()
                    );

                if (autoTodo) {
                    comment.isTodo = true;
                    this.whenCommentMarkStateHasChanged();
                }

                // Encrypt the new comment and send a request to create it
                return this.$encryptAndCreateComment(this.encryptionData, comment)
                    .then((response) => {
                        // TODO: PP-6344 Once the comments service returns proper error codes from ACLS this should be swapped out
                        if (response && !response.success && response.message === 'You do not have permission to create comments on this proof.') {
                            this.refreshProof()
                                .then(() => {
                                    if (this.proof.isLocked) {
                                        const canCommentOnLockedProof = this.proof.ownerIds.includes(this.user.id) || (this.$permissions.isLockedUser && this.proof.lockerId === this.user.id);

                                        if (!canCommentOnLockedProof) {
                                            this.dialog = {
                                                type: this.$$.PPProofDialogType.PROOF_LOCKED,
                                                location: 'modal',
                                            };
                                        }
                                    }
                                })
                        }

                        // If the comment also has an attachment, upload it
                        // This has to be done after the comment has been created (in the database) because
                        // we need to assume the comment ID is available for the new function call...
                        if (comment.attachments && comment.attachments.length) {
                            this.$uploadAndAssignAttachments(comment, comment.attachments);
                        }

                        if (comment.snapshot) {
                            this.$uploadAndAssignSnapshot(comment, comment.snapshot);
                        }
                    })
                    .finally(() => {
                        comment.decryptedComment = comment.comment;
                        comment.$encryptedComment = comment.encryptedComment;
                    });
            },
            () => {
                this.addComment(comment, this.proof.getPage(comment.pageNumber));
                this.whenCommentMarkStateHasChanged();
            }
        );
    }

    /**
     * Updates an existing comment.
     *
     * @param {PPProofComment} comment
     * @param {Array<PPProofCommentAttachment>} comment
     */
    updateComment (comment, newAttachments) {
        this.$updateComment(comment, newAttachments, this.proof);
    }

    /**
     * Update a comments pin data.
     *
     * @param {PPProofComment} comment
     */
    updateCommentPin (comment) {
        this.$updateCommentPin(comment);
    }

    /**
     * Agree with a comment.
     *
     * @param {PPProofComment} comment
     */
    agreeComment (comment) {
        // Visually add/remove the user's id from the agrees array
        toggleValueInArray(comment.agrees, this.user.id);

        this.proof.calculateCommentCounts();

        // Send a request to the server to toggle the users agree state
        this.$agreeComment(comment)
    }

    /**
     * Reply to a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofComment} reply
     * @returns {PPProofComment}
     */
    replyComment(comment, reply) {
        return this.$replyComment(comment, reply, this.proof);
    }

    /**
     * If a user can reply when canAddCommentReplies is off (only owners and editors can reply when canAddCommentReplies is off)
     * @returns {boolean}
     */
    canReply() {
        return this.proof.canAddCommentReplies ||
            (
                this.proof.isOwnerOrCoOwner ||
                this.proof.isEditor ||
                (this.proof.recipient === this.user.id)
            );
    }

    /**
     * Flashes a comment (when a user clicks on the comment in the comment pane.)
     *
     * @param {PPProofComment}
     */
    flashComment(comment) {
        this.comments.unselectComment();
        this.$$.$timeout(() => {
            this.selectComment(comment);
        });
    }

    /**
     * When the user starts creating a comment.
     *
     * @param {Object|undefined} data
     * @param {string|undefined} text
     * @param {Function|undefined} transformPins
     * @returns {PPProofComment}
     */
    startCreateComment (data, text, transformPins) {
        if (this.temporaryComment && this.interactionMode !== 'text') {
            if (this.interactionMode !== 'general') {
                this.temporaryComment.pins.push(this.canvasXYToCommentPin(data));
            }

            if (transformPins) {
                transformPins(this.temporaryComment.pins);
            }

            if (this.canUsePinHistory()) {
                this.addPinsSnapshot();
            }

            return this.temporaryComment;
        }

        if (text) {
            // we place '&nbsp;' characters in the DOM to simulate spaces, however we want to remove this character when we create a comment, or we really screw up the formatting.
            // the '&nbsp;' character is unicode character code 160... just for your information. thanks for coming to my TED talk.
            text = text.split(String.fromCharCode(160)).join(' ');
        }

        let comment = this.$startComment(this.proof, this.user, text || null, data);

        comment.pageNumber = this.currentPage; // All comments for videos are on page #1

        // Show the comments pane
        this.showComments = true;

        if (comment.pins.length && comment.pins[0].fill && comment.pins[0].fill.type === "text") {
            // Don't allow for multiple pins for text selection
            this.isCommenting = false;
        }

        // Set the temporary pin data & comment data & show the `commentCreate` directive
        this.temporaryComment = comment;
        this.isCreatingComment = true;

        // Unselect the selected comment (so only one pin is shown as selected)
        this.comments.unselectComment();
        // Scroll to the top of the comments pane
        this.scrollAndFocusComment();
        if (transformPins) {
            transformPins(this.temporaryComment.pins);
        }

        this.clearPinsHistory();

        if (this.canUsePinHistory() || this.interactionMode === 'draw') {
            this.pinsHistory.push('[]')
            this.addPinsSnapshot();
        }
        return comment;
    }

    scrollAndFocusComment() {
        this.commentsPane.scrollToCreateComment().then(() => {
            // The Editor component auto-focuses, however the following manual focus is needed when the user is adding more
            // pins to the "temporary comment" - because they lose focus when the pin is placed on the proof.
            document.querySelector(`#create-comment-${this.proofId}-${this.currentPage} [contenteditable]`).focus();
        });
    }

    /**
     * When the user has chosen to create a comment.
     *
     * @param {PPProofComment} comment
     */
    finishCreateComment (comment, selectComment = true) {
        comment.decryptedComment = comment.comment; // Immediately display the comment
        comment.createdAt = moment(); // Set the created at date (for the comment's view)

        // Reset the temporary pin data & comment data
        this.temporaryComment = null;

        this.clearPinsHistory();

        // Hide the `commentCreate` directive
        this.isCreatingComment = false;
        
        // Enable header visibility after creating comment
        this.$$.$rootScope.isCreatingComment = false;
        // And push away the comments pane
        //this.showComments = false;

        // Disable the pen tool
        this.isCommenting = false;

        // Send a request to create the new comment object
        this.createComment(comment);

        if (selectComment) {
            this.$$.$timeout(() => {
                this.selectComment(comment, true);
                this.$setFilter(null, this.proof);
            });
        }
    }

    hideCommentPane() {
        if (!this.canShowCommentPane()) {
            this.showComments = false;
        }
    }

    /**
     * When the user decides to cancel a new comment.
     */
    cancelCreateComment () {
        // Hide the comments flyout/pane
        // this.showComments = false;

        // Cancellation of new general comment should cease creation and become unselected
        // draw & pin can still add further markup
        if (this.interactionMode === 'general') {

            this.isCommenting = false;
        }

        if (this.interactionMode === 'draw') {
            this.canvas.deleteDrawing();
            this.canvas.resetDrawingHistory();
            this.canvas.drawingInitiated = false;
        }

        // Reset the temporary pin data & comment data
        this.temporaryComment = null;
        this.clearPinsHistory();
        

        // Hide the `commentCreate` directive
        this.isCreatingComment = false;

        this.hideCommentPane();
    }

    resetDrawing() {
        if (this.canvas) {
            this.canvas.resetDrawing();
        }
    }

    /**
     * Selects a specific comment.
     *
     * @param {PPProofComment} comment
     * @param {boolean} [scroll] Whether to scroll to the comment
     */
    selectComment (comment, scroll = false) {
        const commentPage = this.proof.getPage(comment.pageNumber);

        const deferUntilSwitchPage = this.proof.fileCategory === 'web' ? Promise.resolve() : this.$$.$q.when(this.switchPage(commentPage));
        return deferUntilSwitchPage.then(() => {
            // Delegate this logic to the `CommentsController`
            this.comments.selectComment(comment);

            if (scroll) {
                this.commentsPane.scrollToComment(comment.id);
            }

            return comment;
        });
    }

    selectCommentByNumber(number) {
        this.selectComment(this.comments.getCommentByNumber(number)).then((comment) => {
            this.commentsPane.scrollToComment(comment.id);
        });
    }

    getCommentDataByNumber(number) {
        return this.comments.getCommentByNumber(number);
    }

    getAllCommentsFilteredAndOrdered() {
        let comments = this.proof.pages.flatMap(page => page.comments);

        if (this.filter) {
            comments = comments.filter((comment) => this.filter.fn(comment));
        }
        
        if (this.commentOrder) {
            comments = this.$$.$filter('orderBy')(
                comments,
                this.commentOrder.orderCommentsBy,
                this.commentOrder.isReversedCommentOrder
            );
        }

        return comments.sort((a,b) => {
            if (a.pageNumber < b.pageNumber) {
                return -1;
            }
            if (a.pageNumber > b.pageNumber) {
                return 1;
            }
            return 0;
        });
    }

    cacheCommentNavigation = () => {
        if (!this.proof) {
            return;
        }

        const comments = this.getAllCommentsFilteredAndOrdered();
        const currentCommentId = this.comments && this.comments.selectedComment && this.comments.selectedComment.id;

        if (!currentCommentId) {
            this._previousComment = null;
            this._currentComment = null;
            this._nextComment = comments[0];
            return;
        }

        const currentCommentIndex = comments.findIndex(comment => comment.id === currentCommentId);
        if (currentCommentIndex === -1) {
            this._previousComment = null;
            this._currentComment = null;
            this._nextComment = comments[0];
            return;
        }

        this._previousComment = comments[currentCommentIndex - 1] || null;
        this._currentComment = comments[currentCommentIndex] || null;
        this._nextComment = comments[currentCommentIndex + 1] || null;
    }

    initCommentNavigationWatchers() {
        this.$watch('filter', this.cacheCommentNavigation);
        this.$watch(() => this.commentOrder && this.commentOrder.orderCommentsBy, this.cacheCommentNavigation);
        this.$watch(() => this.commentOrder && this.commentOrder.isReversedCommentOrder, this.cacheCommentNavigation);
        this.$watch(() => this.comments && this.comments.selectedComment && this.comments.selectedComment.id, this.cacheCommentNavigation);
        this.$watch(() => this.proof && this.proof.commentCount, this.cacheCommentNavigation);
    }

    previousComment() {
        if (this._previousComment) {
            this.selectComment(this._previousComment, true);
        }
    }

    nextComment() {
        if (this._nextComment) {
            this.selectComment(this._nextComment, true);
        }
    }

    navigateComments(direction) {
      if (this.comments.selectedComment) {
        if (direction === 'up') {
          this.previousComment();
        } else if (direction === 'down') {
          this.nextComment();
        }
      } else {
        const comments = this.getAllCommentsFilteredAndOrdered();
        this.selectComment(comments[0], true);
      }
    }

    /**
     * When the selection of a comment updates.
     *
     * @param {PPProofComment} previous
     * @param {PPProofComment} current
     */
    selectionUpdate (previous, current) {
        this.$selectionUpdate(previous, current);
    }

    /**
     * Updates the temporary comment's pin.
     *
     * @param {canvas.XY} data
     * @param {number} pinIndex
     * @param {Function|undefined} transformPins
     */
    updateTemporaryPin (data, pinIndex, transformPins) {
        if (data.boxFillType === 'draw' && (data.x1 === undefined || data.y1 === undefined)) {
            this.temporaryComment.pins = [];
            return;
        }

        this.temporaryComment.pins[pinIndex] = this.canvasXYToCommentPin(data);

        if (transformPins) {
            transformPins(this.temporaryComment.pins);
        }

        this.addPinsSnapshot();

        if (this.temporaryComment.pins[0].fill) {
            this.scrollAndFocusComment();
        }
    }

    /**
     * Updates the comment's xy12.
     *
     * @see {PPProofComment.pin}
     */
    updatePin (comment, data, index, _onBeforeUpdate) {
        const currentPin = comment.pins[index];

        if (
            currentPin.x !== data.x1 ||
            currentPin.y !== data.y1 ||
            currentPin.x2 !== data.x2 ||
            currentPin.y2 !== data.y2
        ) {
            comment.pins[index] = this.canvasXYToCommentPin(data);
            if (_onBeforeUpdate) _onBeforeUpdate(comment.pins[index]);
            this.updateCommentPin(comment);
        }

        // Scroll to the comment (if it was re-ordered)
        this.$$.$timeout(() => this.commentsPane.scrollToComment(comment.id));
    }

    /**
     *
     */
    refreshComments(since = this.loadTime) {
        return (
            this.$loadCommentsSince(this.proofId, since)
                .then((data) => {
                    // Update the proof object (pages included) with the latest proof comment data
                    let comments = this.proof.updateFromRecentCommentsData(data);
                    if (Object.keys(data.Pages).length) {
                        this.populateMentionedUsers(this.proof);
                    }

                    // Decrypt the comments which were flagged as 'changed' - this doesn't just mean new comments, it
                    // can also be any comments which have been changed (edited) by the user and require decryption again.
                    this.decryptComments();

                    // Trigger the fact that comments marked state may have changed
                    this.whenCommentMarkStateHasChanged();

                    //trigger creating comments from offline saved comments
                    if (!data.Locked) {
                        this.$$.localCommentService.handleCommentLocalStorage();
                    }

                    return data;
                })
        );
    }

    /**
     * Open the proof info pane for the proof (by id).
     *
     * @see {proofInfoService.open}
     */
    openProofInfo = (proofId) => {
        this.$openProofInfo(proofId || this.proofId);
    }

    openManageProof = (proofId) => {
        const options = {
            canManage: this.proof.canManage,
        };
        this.$$.proofInfoService.openManageProof(proofId || this.proofId, 'proof-manage', options);
    }

    /**
     * Toggle the proof info pane for the proof (by id).
     *
     * @see {proofInfoService.open} and
     */
    toggleProofInfo = () => {
        this.$toggleProofInfo(this.proofId);
    }

    /**
     * Previews an attachment.
     *
     * @see {FilePreviewController}
     * @param {PPProofCommentAttachment} attachment
     * @param {PPProofComment} comment
     */
    previewAttachment (attachment, comment) {
        if (attachment.hasProcessed) {
            this.attachment = attachment;
        } else {
            comment.$message = (
                // The attachment is either uploading or being processed base that off whether the attachment
                // has an id already (an id'd attachment has finished uploading).
                this.$$.PPCommentMessageType[
                    attachment.id ? 'ATTACHMENT_PROCESSING' : 'ATTACHMENT_UPLOADING'
                ]
            );
        }
    }

     /**
    *function to hide detailed date and show general date
    */
    toggleDateDisplay () {
        this.isDetailedDate = !this.isDetailedDate;
     }

    /**
     * Download an attachment.
     *
     * @param {PPProofCommentAttachment} attachment
     * @returns {$q<Blob>}
     */
    downloadAttachment (attachment) {
        return this.$downloadAttachment(attachment);
    }

    /**
     * @returns {Boolean}
     */
    isApproverAndLocked () {
        return (this.$permissions.isFinalApproverStage && this.$permissions.isFinalApprover && this.$permissions.isLocked);
    }

    isGatekeeperAndLocked () {
        return this.proof.status === this.$$.PPProofStatus.FINAL_APPROVING
                && this.$permissions.permissionObj.proofLevel.isApprover;
    }

    isApproverOrGatekeeper () {
        return this.$permissions.permissionObj.proofLevel.isApprover ||
                (this.$permissions.isFinalApproverStage && this.$permissions.isFinalApprover);
    }

    /**
     * @returns {Boolean}
     */
    isNewBriefOrBriefing () {
        return (this.proof.proofType === this.$$.PPProofType.BRIEF && (this.proof.status === this.$$.PPProofStatus.NEW || this.proof.status === this.$$.PPProofStatus.PROOFING));
    }

    /**
     * @returns {String}
     */
    getOwnerEmailValidation(email) {
        const ownerEmails = this.proof.getOwners().map(owner => owner.email);
        const ownerIndex = ownerEmails.indexOf(email);
        if (ownerIndex !== -1) {
            const ownerEmail = ownerEmails[ownerIndex];
            return this.user.email === ownerEmail
                ? 'you-are-owner'
                : 'they-are-owner';
        }
        return null;
    }

    /**
     * @param {String}
     * @returns {Boolean}
     */
    validateEditorEmail(email) {
        this.editorOwnerMessage = this.getOwnerEmailValidation(email);
        this.visitedEmail = true;
        return this.editorOwnerMessage === null;
    }

    cleanErrorOnFocus() {
        this.visitedEmail = false;
    }

    saveComment (hideConfirm = false) {
        this._reactCreateCommentContainerRef.onCreate();
        this.cancelCreateComment();
        this._reactCreateCommentContainerRef = null;
        if (hideConfirm) {
            this.hideButtonConfirm();
        }
    }

    continueActionHandler () {
        this._reactCreateCommentContainerRef = null;
    }

    handleUnsavedComment() {
        if (this.isCreatingComment && this._reactCreateCommentContainerRef) {
            if (this.isApproverOrGatekeeper()) {
                this.dialog = {
                    type: this.$$.PPProofDialogType.UNSAVED_COMMENT,
                    location: 'modal',
                };
            } else {
                this.saveComment();
            }
        }
    }

    handleRequestToBeAdd () {
        if (this.requestAccessEmail) {
            this.dialog = {
                type: this.$$.PPProofDialogType.REQUEST_ACCESS,
                location: 'modal',
            };
        }
    }

    /**
     * Invokes the button's action.
     *
     * @see {PPProofActions.getActionHandler}
     * @param {Object} [options]
     * @returns {$q}
     */
    handleButtonAction(options = {}) {
        let type = this.$actions.getActionType(),
            handler = this.$actions.getActionHandler(type, this.proofId, this.nextProofProps.proof),
            promise;

        if ('message' in options) {
            // If a message is provided, set it for the default handler
            // alert('Setting message.');
            this.$actions.setProofMessage(options.message);
        }

        // alert('Invoking handler... ' + type);
        promise = handler()
            .then(this.unjail())
            .then(() => this.setButtonConfirm(false))
            .then(() => this.updateProof())
            .then(() => this.loadPermissionsActionButton())
            .then(() => this.showNextProof())
            .then(() => {
                //if handler was invoked by a final approver for locking the proof, it will mark all comment as to do
                if (this.isApproverAndLocked()) {
                    this.markAllComments('todo', true);
                    this.showCommentPane();
                    this.$$.$timeout(() => this.commentsPane.scrollToTop(), 500);
                }
            });

        if (promise) {
            this.handlingAction = true;
            promise.finally(() => (this.hideButtonConfirm()));
        }

        return promise;
    }

    getUploadNewVersionButtonProps() {
        return {
            proof: Object.assign({}, this.proof, {
                owners: this.proof.owners.map(owner => ({ email: owner.Email })),
                name: this.proof.title,
                messageToReviewers: this.proof.description,
            }),
            variant: 'small',
            direction: 'up',
        };
    }

    getUploadNewVersionButtonWithFeatureFlagProps() {
        return {
            variant: 'small',
            compact: true,
            onClick: () => this.handleButtonAction(),
            onDragOver: event => event.preventDefault(),
            onDrop: (event) => {
                event.preventDefault();
                this.handleButtonAction();
            },
            tooltipTitle: window.__pageproof_quark__.translation.createReactTranslationElement('dashboard.proof.options.upload-new-version'),
        };
    }

    get isButtonConfirmationOpen() {
        return this.button && this.buttonConfirm;
    }

    get isChecklistBlockingFinalApproval() {
        return this.proof.checklist && this.proof.checklist.isRequiredForFinalApproval && this.isChecklistComplete === false;
    }

    getChecklistProps() {
        const {id: checklistId, name} = this.proof.checklist;

        const canBeOpen = !this.$$.proofInfoService.$$open &&
            !this.$$.$scope.menuOpen &&
            !this.$$.$scope.teamMenuOpen &&
            !this.$$.modalService.$$modals.length &&
            !this.dialog &&
            !this.$$.walkthrough.currentWalkthrough &&
            !this.showCollection &&
            !this.focusMode &&
            !this.showColorSeparations &&
            !this.$$.$rootScope.isActivityFeedOpen &&
            !this.isPdfPreviewOpen &&
            !this.isButtonConfirmationOpen &&
            !this.nextProofProps.visible;

        return {
            checklistId,
            name,
            isHeaderVisible: this.$$.$rootScope.isHeaderVisible(),
            onChecklistUpdated: ({ isComplete }) => {
                this.isChecklistComplete = isComplete;
            },
            canBeOpen,
        };
    }

    /**
     * Opens up comment pane automatically, after proof loads, for certain conditions
     * 1. If status is 50
     * 2. If FinalApprover locks the proof and comment count is greater than 0
     */
    showCommentPane(showComments = this.proof.commentCount) {
        this.showComments = !!showComments;
    }

    /**
     * Returns if comment/reply can be edited by other users or not
     *
     * @returns {boolean}
     */
    canEditComment = (comment) => {
        return comment.$selected &&
            this.permissions.proofer.commentLevel.canEdit &&
            (comment.ownerId === this.user.id ||
            (this.proof.canOthersEditComments && (this.proof.isOwnerOrCoOwner || this.permissions.proofer.proofLevel.isFinalApprover)));
    }

    populateCommentedPagesForFilter() {
        this.proof.commentedPages = [];
        this.proof.pages.forEach(page => {
            if (page.comments.length) {
                this.proof.commentedPages.push({
                    id: page.pageNumber,
                    label: window.__pageproof_quark__.translation.createReactTranslationElement('default.page-x', { page: page.pageNumber }),
                });
            }
        });
    }

    /**
     * Updates the user button/action permissions and re-calculates the number (count)
     * of comments on the proof (and how many are marked as to-do/done).
     *
     * @see {PPProof.calculateCommentCounts}
     */
    whenCommentMarkStateHasChanged () {
        // (sync) Calculate the number of comments & comments marked as to-do/done
        if (this.proofLoaded) {
            this.proof.calculateCommentCounts();
        }

        // Update the user button/action permissions settings
        this.loadPermissionsActionButton();
        this.populateCommentedPagesForFilter();
        this.updateGroupCommentCount();
        this.cacheCommentNavigation();
    }

    /**
     * Mark/unmark a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPCommentMarkType} type
     * @param {Boolean} state
     */
    markComment (comment, type, state) {
        this.$markComment(comment, type, state)
            .then(() => this.whenCommentMarkStateHasChanged());
        this.whenCommentMarkStateHasChanged();
    }

    /**
     * Returns the partial url for the button html.
     *
     * @returns {String}
     */
    getButtonTemplateUrl () {
        return `templates/partials/proof/components/buttons/${this.button}.html`;
    }

    /**
     * Sets the button confirmation state.
     *
     * @param {Boolean} confirm
     */
    setButtonConfirm (confirm, button) {
        this.buttonConfirm = confirm;
        this.showComments = false;
        this.confirmationButton = button || this.button;
        this.handleUnsavedComment();
        if (!confirm) {
            this.handlingAction = false;
        } else {
            this.loadNextProof();
        }
    }

    getCurrentPage() {
        return this.proof ? this.proof.pages[this.currentPage - 1] : null;
    }

    getCurrentPagePreview() {
        const page = this.getCurrentPage();
        return page ? page.image.high || page.image.low : null;
    }

    setDimensions() {
        this.proof.dimensions = {width: this.proof.width, height: this.proof.height};
    }

    switchPage(page) {
        if (this.currentPage !== page.pageNumber) {
            this.currentPage = page.pageNumber;
            return true;
        }
        return false;
    }

    nextPage() {
        if (this.currentPage + 1 <= this.proof.pages.length) {
            this.currentPage++;
            // this.loadCurrentPagePreview();
            return true;
        }
        return false;
    }

    previousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            // this.loadCurrentPagePreview();
            return true;
        }
        return false;
    }

    getCurrentProofInCollectionIndex() {
        return this.group.proofs.findIndex(proof => proof.id === this.proof.id);
    }

    nextCollectionProof() {
        const currentProofInCollectionIndex = this.getCurrentProofInCollectionIndex();

        if ((currentProofInCollectionIndex + 1) < this.group.proofs.length) {
            this.navigateToProof(this.group.proofs[currentProofInCollectionIndex + 1]);
        }
    }

    previousCollectionProof() {
        const currentProofInCollectionIndex = this.getCurrentProofInCollectionIndex();

        if (currentProofInCollectionIndex > 0) {
            this.navigateToProof(this.group.proofs[currentProofInCollectionIndex - 1]);
        }
    }

    getPreviousCollectionProof() {
        const currentProofInCollectionIndex = this.getCurrentProofInCollectionIndex();
        if (currentProofInCollectionIndex > 0) {
            return this.group.proofs[currentProofInCollectionIndex - 1];
        } else {
            return null;
        }
    }

    getNextCollectionProof() {
        const currentProofInCollectionIndex = this.getCurrentProofInCollectionIndex();
        if ((currentProofInCollectionIndex + 1) < this.group.proofs.length) {
            return this.group.proofs[currentProofInCollectionIndex + 1];
        } else {
            return null;
        }
    }

    navigateToProof(proof, unjail = true) {
        const type = proof.type === 2 ? 'brief' : 'proof';
        const category = proof.fileCategory || 'static';

        this.$$.$location.url(`/${type}/${category}/${proof.id}`);
        if (unjail) {
            this.unjail();
        }
    }

    loadCurrentPagePreview() {
        return this.loadPagePreview(this.getCurrentPage());
    }

    loadPagePreview(page) {
        const shouldLoadSprite = this.proof.pages.length > 1;
        return this.$$.proofRepositoryService.loadPagePreview(page, this.proof, shouldLoadSprite)
            .then(() => this.setDimensions());

    }

    canUserManageCollection = () => {
        return (
            this.isUserAnOwnerOnGroup() ||
            this.group.isUserAdminOfGroupTeam(this.$$.sdk.session.user) ||
            (this.group.isOwnedByTeam && this.group.teamId === this.user.teamId)
        );
    }

    loadGroupProofs(groupId) {
        return this.$$.sdk.proofs.groups.load(groupId)
            .then(group => {
                this.group = group;
                this.group.proofs.map(proof => {
                    this.queueThumbnail(proof);
                });
                this.group.canManageCollection = this.canUserManageCollection();
                this.setCollectionButtonProps();
                this.getCommentableProofsInCollection()
                    .then((proofs) => {
                        this.group.commentableProofsInCollection = proofs;
                        this.setGroupPopupProps();
                        this.setCollectionMenuProps();
                    });
            });
    }

    setGroupPopupProps() {
        const that = this;
        this.groupPopupProps = {
            group: that.group,
            userId: that.user.id,
            onClose: () => {
                that.toggleCollectionView();
            },
            onRedirection: (type, proofId) => that.redirectTo(type, proofId),
            onOpenCollectionManage: () => that.openCollectionManage(),
            footerOptions: {
                info: {enabled: true, onClick: (proof) => that.openProofInfo(proof.id)},
                manage: {enabled: false},
                print: {enabled: false},
                download: {enabled: false},
                lock: {enabled: false},
                collection: {
                    enabled: true,
                    viewLink: {
                        enabled: true,
                        onClick: () => that.redirectTo('group'),
                    },
                    onManageOpen: () => that.openCollectionManage(),
                    onRemove: proofId => that.removeProofFromGroup(proofId)
                        .then(() => {
                            this.refreshGroupProps();
                        }),
                    onCopy: groupId => window.generalfunction_copyToClipboard(`${window.location.origin}/dashboard/group?groupId=${groupId}`),
                },
            },
            currentProofId: that.proof.id,
            collectionProofDownloadProps: that.setCollectionProofDownloadProps(),
            bulkGeneralCommentsProps: that.setBulkGeneralCommentsProps(),
            get showTools() {
                return !that.isSmallScreen;
            }
        }
    }

    setCollectionButtonProps() {
        const that = this;
        this.collectionButtonProps = {
            get showCollection() {
                return that.showCollection;
            },
            proofCount: that.group.proofs.length,
            onClick: () => that.toggleCollectionView(),
            collectionCardProps: that.proof.isLatestVersion && (that.getCurrentProofInCollectionIndex() !== -1) && that.setCollectionCardProps(),
        }
    }

    setProofScreenMobileMenuProps() {
        const proofScreenMobileMenuProps = {
            onProofInfo: () => this.toggleProofInfo(),
            onManageProof: () => this.openManageProof(),
            proofDownloadButtonProps: this.proofDownloadButtonProps,
        }
        this.$$.$rootScope.$broadcast('setProofScreenMobileMenuProps', proofScreenMobileMenuProps);
    }
    
    clearProofScreenMobileMenuProps() {
        this.$$.$rootScope.$broadcast('setProofScreenMobileMenuProps', null);
    }

    setCollectionMenuProps() {
        this.collectionMenuProps = {
            canManage: this.canUserManageCollection(),
            onManageClick: () => this.openCollectionManage(),
            collectionProofDownloadProps: this.groupPopupProps.collectionProofDownloadProps,
        }
        this.$$.$rootScope.$broadcast('setCollectionMenuProps', this.collectionMenuProps);
    }

    setCollectionCardProps() {
        const that = this;
        return {
            title: that.group.name,
            get prevProofThumbnail() {
                return that.getPreviousCollectionProof() && that.getPreviousCollectionProof().thumbnail;
            },
            get currentProofThumbnail() {
                return that.group.proofs.filter(proof => proof.id === that.proof.id)[0].thumbnail;
            }, 
            get nextProofThumbnail() {
                return that.getNextCollectionProof() && that.getNextCollectionProof().thumbnail;
            },
            onPreviousProof: () => {
                that.previousCollectionProof();
                if (!this.$$.$rootScope.$$phase) { this.$$.$rootScope.$apply(); }
            },
            onNextProof: () => {
                that.nextCollectionProof();
                if (!this.$$.$rootScope.$$phase) { this.$$.$rootScope.$apply(); }
            },
            currentProofPosition: that.getCurrentProofInCollectionIndex() + 1,
            amountOfProofs: that.group.proofs.length,
        };
    }

    setCollectionProofDownloadProps() {
        const props = {
            status: null,
            downloadProgress: {},
            collectionFiles: this.getDownloadableCollectionProofFiles(),
            onDownload: (fileIds) => this.downloadFiles(fileIds, false, props, this.group.name),
            proofType: this.proof.getProofType(),
        };

        return props;
    }

    getDownloadableCollectionProofFiles() {
        return this.group.proofs.filter(proof => (proof.canDownload && proof.fileSize && proof.state !== 'file-error'))
            .map(proof => ({
                name: proof.name,
                id: proof.fileId,
            }));
    }

    setBulkGeneralCommentsProps() {
        return {
            proofs: this.group.commentableProofsInCollection,
            onCommentsCreated: (proofIds) => this.updateMultipleGroupCommentCount(proofIds),
        };
    }

    getCommentableProofsInCollection() {
        return this.$$.sdk.graphql(
            `
                query ppxapp_GetProofsPermissions($ids: [ID!]) {
                    proofs(ids: $ids) {
                        id
                        name
                        permissions {
                            canCreateGeneralComment {
                                value
                            }
                        }
                    }
                }
            `,
            {
                ids: this.group.proofs.map(proof => proof.id),
            },
            {
                throwOnError: false,
            }
        ).then((result) => {
            return result.data.proofs
                .filter(proof => proof && proof.permissions.canCreateGeneralComment.value)
                .sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    return 0;
                });
        });
    }

    updateMultipleGroupCommentCount(proofIds) {
        proofIds.forEach((proofId) => {
            if (proofId === this.proof.id) {
                // Comment count incrementing already for current proof.
                return;
            }
            const proof = this.group.proofs.find(proof => proof.id === proofId);
            if (proof) {
                proof.commentCount += 1;
            }
        });
    }

    updateGroupCommentCount() {
        this.group && this.group.proofs.some(proof => {
            if (proof.id === this.proofId) {
                proof.todoCommentCount = this.proof.todoCount;
                proof.doneCommentCount = this.proof.doneCount;
                proof.commentCount = this.proof.commentCount;
                proof.privateCount = this.proof.privateCount;
            }
        });
    }

    refreshGroupProps() {
        if (this.proof.groupId) {
            this.loadGroupProofs(this.proof.groupId);
        }
    }

    isUserAnOwnerOnGroup = () => {
        if (this.group) {
            return this.group.proofs.some((proof) => {
                return this.$$.userService.matches(...proof.ownerUserIds);
            });
        }
    }

    removeProofFromGroup = (proofId) => {
        return this.$$.sdk.proofs.groups.removeProof(proofId);
    }

    redirectTo = (type, proofId) => {
        this.unjail();
        let path = '';
        switch (type) {
            case 'group':
                if (this.proof.groupId) {
                    path = 'dashboard/group?groupId=' + this.proof.groupId;
                    break;
                }
            case 'proof':
                if (proofId) {
                    path = 'proof/static/' + proofId;
                    break;
                }
            case 'dashboard':
            default:
                path = 'dashboard';

        }
        this.$$.$location.url(path);
      }

    queueThumbnail(proof) {
        proof.$$thumbnailState = 2;
        return this.$$.sdk.files.thumbnail(proof.fileId)
                .then((blob) => {
                    proof.$$thumbnailState = 3;
                    proof.thumbnail = URL.createObjectURL(blob);
                });
    }

    /**
     * Opens another version from the version dropdown
     *
     * @param {proofId} string
     *
     */
    onSwitchVersion = (proofId) => {
        this.version = proofId;
    };

    /**
     * Delegates to $validateAttachmentType.
     *
     * @param {File} file
     * @returns {Boolean}
     */
    validateAttachmentType (file) {
        return this.$validateAttachmentType(file);
    }

    /**
     * Delegates $validateAttachmentSize.
     *
     * @param {File} file
     * @returns {Boolean}
     */
    validateAttachmentSize (file) {
        if (!this.$validateAttachmentSize(file)) {
            this.dialog = {
                type: this.$$.PPProofDialogType.ATTACHMENT_SIZE,
                location: 'modal',
            };
            return false;
        }
        return true;
    }

    /**
     * @param {PPProof} proof
     * @param {String} type
     * @param {Boolean} state
     * @returns {$q}
     */
    markAllComments (type, state) {
        const update = () => {
            const { filteredComments } = this.$getfilteredComments(this.proof);
            if (this.filter.name && filteredComments.filter(this.filter.fn).length === 0) {
                this.$setFilter(null, this.proof);
            }
            this.loadPermissionsActionButton();
            this.whenCommentMarkStateHasChanged();
        };
        this.$markAllComments(this.proof, type, state).then(update);
        update();
    }

    /**
     * Displays the proof printing options
     *
     */
    printOptions() {
        const { destroy } = this.$$.modalService.createWithComponent('PrintOptionsContainer', this.printOptionsProps());
        this.closeOptions = destroy;
    }

    printOptionsProps() {
        return {
            proofId: this.proof.id,
            proofName: this.proof.title,
            proofType: this.proofType,
            onFinished: () => this.printOptionsFinished(),
            onCancel: () => this.closeOptions(),
        }
    }

    printOptionsFinished() {
        this.$$.$scope.headerControls.show = true;
        this.$$.$scope.headerControls.lock = true;
        this.isPrinting = true;
        this.$$.printService.subscribe('proof-controller', (jobs) => {
            this.$$.$scope.headerControls.lock = this.isPrinting = jobs.length > 0;
        });
        this.closeOptions();
    }

    legacyPrint() {
        let type = this.proof.approvedDate ? 'approval' : 'comments';
        let allCommentsAreTodos = false;
        if (this.proof.commentCount !== 0 && this.proof.commentCount === this.proof.todoCount) {
            allCommentsAreTodos = true;
        }
        return this.$$.$q((resolve) => {
            this.$$.printServiceLegacy.printProof(this.proof.id, type, allCommentsAreTodos, resolve);
        });
    }

    /**
     * Whether the pin is visible on screen.
     *
     * @param {sdk.Pin} pin
     * @returns {boolean}
     */
    isPinVisible(pin) {
        return false;
    }

    /**
     * For audio & video proofs, this gets the previous/next comments.
     *
     * @param {number} currentTime
     * @param {number} offset
     * @returns {PPProofComment}
     */
    getCommentByOffset (currentTime, offset) {
        currentTime = Math.floor(currentTime);

        // Build an array of all the comment objects on the proof and order them by the `time`
        // Luckily this is easy with video proofing as all comments are added to page 1 (index 0)
        let comments = this.proof.pages[0].comments
            .flatMap((comment) => comment.pins.map((pin, pinIndex) => ({ comment, pin, pinIndex, time: pin.time })))
            .filter(({ pin }) => !this.isPinVisible(pin));

        // Filter out any comments which have been filtered out by the comment filter
        comments = comments.filter(({ comment }) => this.filter.fn(comment));

        // Actually sort the comments by time (ascending)
        comments.sort(sortObjectArrayFn('time'));

        // The comment the user will end up scrubbing to
        let comment;

        // Find the last comment that is currently visible onscreen
        for (let index = 0, length = comments.length; index < length; index++) {
            const currentComment = comments[index];
            const currentCommentTime = Math.floor(currentComment.time);

            // Next comment
            if (offset === +1) {
                if (currentCommentTime <= currentTime) {
                    continue;
                }
                if (currentCommentTime > currentTime) {
                    comment = currentComment;
                    break;
                }
            }

            // Previous comment
            else if (offset === -1) {
                if (currentCommentTime < currentTime) {
                    comment = currentComment;
                } else {
                    break;
                }
            }
        }

        console.debug(comment);

        // Return the comment object
        return comment.comment;
    }

    canShowCommentTodoDoneCounts() {
        return this.permissions && this.proof.commentCount > 1 && (
            (this.permissions.proofer.commentLevel.canViewChanged && this.proof.todoCount >= 1) ||
            (this.permissions.proofer.commentLevel.canViewDone && this.proof.doneCount >= 1) ||
            (this.proof.agreeCount >= 1) ||
            (this.proof.labelCount) ||
            (this.proof.mentionedUsers.length) ||
            (this.proof.commentedPages.length > 1) ||
            (this.proof.attachmentCount >= 1)
        );
    }

    canShowCommentHeading() {
        return (
            this.proof && (
                this.filter.name || (
                    this.permissions && (
                        this.canShowCommentTodoDoneCounts() || (
                            this.permissions.proofer.commentLevel.canMarkAll &&
                            this.proof.commentCount > 1
                        )
                    )
                )
            )
        );
    }

    toggleScrollMode() {
        switch(this.scrollMode) {
            case 'pan':
                this.scrollMode = 'zoom';
                break;
            case 'zoom':
                this.scrollMode = 'pan';
                break;
        }
    }

    canShowCommentPane() {
        return (this.proof && this.proof.commentCount > 0) || this.isCreatingComment;
    }

    hasLoaded() {
        return this.$$commentRefresh.hasLoaded;
    }

    formatTooltip(comment) {
        const commentText = comment.comment || comment.decryptedComment || '';
        const obj = window.generalfunctions_parseCommentText(commentText);
        const text = window.__pageproof_quark__.sdk.util.comments.text(obj.tokens);
        return truncateStr(text, 50).replace(/\n/g, ' ').replace(/\s+/g, ' ').trim();
    }

    toggleTooltip(hover, comment, box) {
        if (comment.tooltip) {
            comment.tooltip();
        }

        if (hover) {
            comment.tooltip = this.$$.tooltipService.create(
                box.left, box.top + box.height + 5, null,
                `<strong>${comment.ownerEmail}</strong><br>` +
                `<p>${this.formatTooltip(comment)}</p>`,
                null, null, true, 'note'
            );
        }
    }

    _getZoomOptions() {
        const current = this.getTargetZoom();
        let previous = ZOOM_LEVELS[0];
        let next = ZOOM_LEVELS[ZOOM_LEVELS.length - 1];
        ZOOM_LEVELS.some(level => {
            if (level < current) {
                previous = level;
            }
            if (level > current) {
                next = level;
                return true;
            }
        });
        return {previous, next};
    }

    getZoom() {
        return this.canvas.getZoom() * 100;
    }

    getTargetZoom() {
        return this.canvas.getTargetZoom() * 100;
    }

    toggleOutline() {
        this.outline = !this.outline;
        return this.canvas.setKeyline(!this.canvas.enableKeyline);
    }

    zoomOut() {
        const {previous} = this._getZoomOptions();
        this.zoomTo(previous);
    }

    zoomIn() {
        const {next} = this._getZoomOptions();
        this.zoomTo(next);
    }

    nudgeZoomIn() {
        const current = this.getTargetZoom();
        this.zoomTo(Math.min(current + NUDGE_ZOOM_GAP, ZOOM_LEVELS[ZOOM_LEVELS.length - 1]));
    }

    nudgeZoomOut() {
        const current = this.getTargetZoom();
        this.zoomTo(Math.max(current - NUDGE_ZOOM_GAP, NUDGE_ZOOM_GAP));
    }

    zoomTo(level) {
        this.canvas._zoomTo(level);
    }

    fit() {
        this.canvas.rotate(0);
        this.canvas._fit(true, 500);
    }

    rotate(counter) {
        this.canvas.rotate(this.canvas.getTargetAngle() + (counter ? -90 : 90));
    }

    invertPins() {
        if (this.canvas) { //if static file there is already invert function
            this.canvas.invertPins();
        }
        this.isInvertedPins = !this.isInvertedPins;
    }

    goToCompare() {
        this.unjail();
        if (this.canShowCompareTool()) {
            this.$$.SegmentIo.track(45, {
                'proof id': this.proofId,
            });
            this.$$.$location.url(`/proof/compare/${this.proofId}/${this.currentPage}`);
        }
    }

    canShowCompareTool() {
        return (this.proof && this.proof.allowedVersions && typeof this.proof.allowedVersions[0] === 'object' && this.proof.allowedVersions.length > 1);
    };

    openAndScrollToWorkflow = () => {
        this.closeProofDialog();
        this.openProofInfo();
        this.scrollToWorkflow();
    };

    closeProofDialog = () => {
        this.dialog = null;
    };

    scrollToWorkflow() {
        let workflowElem = document.getElementById('workflow-structure');
        if (document.body.contains(workflowElem)) {
            this.$$.domService.scrollTo('.workflow-structure', 500, -180);
        } else {
            this.$$.$timeout(() => this.scrollToWorkflow());
        }
    }

    hideButtonConfirm() {
        this.$$.$timeout(() => {
            this.setButtonConfirm(false);
        }, 500);
    }

    toggleCollectionView() {
        this.showCollection = ! this.showCollection;
        this.setHeader();
    }

    openCollectionManage = () => {
        this.$$.$rootScope.$broadcast('openCollectionPane', this.group);
        if (!this.$$.$scope.$$phase) {
            this.$$.$scope.$apply();
        }
    }

    onCloseCollectionManage() {
        this.refreshGroupProps();
        this.setHeader();
    }

    storeInRecentProofs() {
        const localRecentProofs = this.$$.storageService('pageproof.app.recentProofs.' + this.user.id + '.');
        const keys = localRecentProofs.keys();

        if (keys.indexOf(this.proofId) !== -1) {
            return;
        }

        if (keys.length > 2) {
            keys.map(key => localRecentProofs.json(key))
                .sort((a, b) => a.timeAdded > b.timeAdded ? -1 : 1)
                .slice(2)
                .forEach(({ id }) => localRecentProofs.remove(id));
        }

        const proofObj = {
            id: this.proof.id,
            title: this.proof.title,
            type: this.proof.proofType,
            timeAdded: moment(),
            fileId: this.proof.fileId,
        };
        localRecentProofs.set(this.proof.id, JSON.stringify(proofObj));
    }

    canNavigatePage() {
        return !this.canvas || !this.canvas.drawingCanvas || !this.showColorSeparations;
    }

    getfilteredComments() {
        return this.$getfilteredComments(this.proof);
    }

    canShowProofPageInfo() {
      return !this.focusMode &&
        this.proof &&
        this.proof.measurementUnits &&
        this.proof.originalWidth !== null &&
        this.proof.originalHeight !== null;
    }

    canLoadNextProof = () => this.proof.groupId && !this.proof.canManage;

    loadNextProof() {
        if (this.canLoadNextProof()) {
            return this.$$.sdk.proofs.next(this.proofId)
                .then((proof) => {
                    this.nextProofProps.proof = proof;
                    // Todo - remove this extra call to proof api, once sdk-proof model resembles PPProof model
                    this.$$.proofCacheService.getProof(proof.id)
                    .then(nextProof => {
                        if (nextProof.fileCategory === 'static') {
                            const shouldLoadSprite = nextProof.pages.length > 1;
                            this.$$.proofRepositoryService.loadPagePreview(nextProof.pages[0], nextProof, shouldLoadSprite);
                        }
                    });
                    return !!proof;
                });
        }
        return false;
    }

    showNextProof() {
        if (this.nextProofProps.proof) {
            this.nextProofProps.visible = true;
        }
    }

    getReasonForPenUnavailability(isViewOnlyReviewer) {
        if (!this.proof) {
            return false;
        }
        let penDefaultStatus = 'proof.tools.pin.unavailable';
        let addedStatus = null;
        let type = this.proof.getProofType();
        switch (this.proof.status) {
            case this.$$.PPProofStatus.PROOFING:
                if (!isViewOnlyReviewer) {
                    addedStatus = type + '.waiting-action';
                }
                break;
            case this.$$.PPProofStatus.FINAL_APPROVING:
                if (this.proof.isLocked) {
                    addedStatus = type + '.locked';
                }
                break;
            case this.$$.PPProofStatus.CHANGES_REQUESTED:
                addedStatus = type + '.returned-todo-list';
                break;
            case this.$$.PPProofStatus.AWAITING_NEW_VERSION:
                addedStatus = type + '.awaiting-upload';
                break;
            case this.$$.PPProofStatus.HAS_NEW_VERSION:
                addedStatus = type + '.has-new';
                break;
            case this.$$.PPProofStatus.APPROVED:
                addedStatus = type + '.approved';
                break;
            case this.$$.PPProofStatus.CLOSED:
                addedStatus = type + '.archived';
                break;
        }

        if (!addedStatus && isViewOnlyReviewer) {
            addedStatus = type + '.view-only-reviewer';
        }

        return addedStatus ? penDefaultStatus + '.' + addedStatus : penDefaultStatus;
    }

    initCurrentCanvasPosition() {
        this.currentCanvasPosition = {
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            zoomLevel: 1,
            angle: 0,
            isRotating: false,
        }
    }

    getCommentPermissions() {
        this.$$.sdk
            .graphql(
                `query ppxapp_getCommentPermissions($id: ID!) {
                    proof(id: $id) {
                        permissions {
                            canCreateComment { value }
                            canCreatePrivateComment { value }
                            canMarkDone { value }
                            canMarkTodo { value }
                        }
                    }
                }`, { 
                    id: this.proofId
                }, {
                    throwOnError: false
                }
            )
            .then(({ data }) => {
                const {
                    proof: {
                        permissions: {
                            canCreateComment,
                            canCreatePrivateComment,
                            canMarkDone,
                            canMarkTodo,
                        },
                    },
                } = data;
            
                this.commentPermissions = {
                    canCreateComment: canCreateComment.value,
                    canCreatePrivateComment: canCreatePrivateComment.value,
                    canSetCommentStatus: canMarkDone.value || canMarkTodo.value,
                };
            });
    }


   
    withCommentsPane(cb) {
        if (this.commentsPane) {
            cb(this.commentsPane);
            return;
        }
        this._initialCommentsPaneScrollCallback = cb;
    }

    commentsPaneDidMount() {
        if (this._initialCommentsPaneScrollCallback) {
            try {
                this._initialCommentsPaneScrollCallback(this.commentsPane);
            } finally {
                this._initialCommentsPaneScrollCallback = null;
            }
        }
    }
}

window.GenericProofController = GenericProofController;
