/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const mediaControlsTemplate = `
    <spinner
            ng-if="!mediaControlsCtrl.media.isReady"
            spinner-options="{ enabled: true, center: true }"
    >
    </spinner>
    <button class="page__proof__toolbar__button--video-buttons--skip page__proof__toolbar__button"
            ng-class="{'page__proof__toolbar__button--video-buttons-disabled': ! mediaControlsCtrl.media.canSkipBackward}"
            ng-click="mediaControlsCtrl.media.skipBackward(5)"
            ng-if="!mediaControlsCtrl.media.isShortAudio"
            ng-disabled="!mediaControlsCtrl.media.isReady"
            tooltip='{{ "proof.media-player.audio.skip-backwards" | translate }}'
            tooltip-direction="up center"
            tooltip-style="buttons">
        <inline-svg src="img/content/proof/icons/video/skip_back_5.svg"></inline-svg>
    </button>
    <button class="page__proof__toolbar__button--video-buttons page__proof__toolbar__button""
            ng-class="{'page__proof__toolbar__button--video-buttons-disabled': ! mediaControlsCtrl.media.canSkipBackward}"
            ng-click="mediaControlsCtrl.media.skipBackward(1)"
            ng-disabled="!mediaControlsCtrl.media.isReady"
            tooltip='{{ "proof.media-player.audio.nudge-backwards" | translate }}'
            tooltip-direction="up center"
            tooltip-style="buttons">
        <inline-svg src="img/content/proof/icons/video/previous_frame.svg"></inline-svg>
    </button>
    <button class="page__proof__toolbar__button--video-buttons page__proof__toolbar__button""
            ng-class="{'page__proof__toolbar__button--video-buttons-playing': mediaControlsCtrl.media.isPlaying }"
            ng-click="mediaControlsCtrl.media.toggle()"
            ng-disabled="!mediaControlsCtrl.media.isReady"
            data-walkthrough-hook="{{ mediaControlsCtrl.walkthroughHook }}"
            tooltip='{{ "proof.media-player.audio.play-pause" | translate }}'
            tooltip-direction="up center"
            tooltip-style="buttons">
        <inline-svg src="img/content/proof/icons/video/play_circle.svg" ng-if="!mediaControlsCtrl.media.isPlaying"></inline-svg>
        <inline-svg src="img/content/proof/icons/video/pause.svg" ng-if="mediaControlsCtrl.media.isPlaying"></inline-svg>
    </button>
    <button class="page__proof__toolbar__button--video-buttons page__proof__toolbar__button"
            ng-class="{'page__proof__toolbar__button--video-buttons-disabled': !mediaControlsCtrl.media.canSkipForward }"
            ng-click="mediaControlsCtrl.media.skipForward(1)"
            ng-disabled="!mediaControlsCtrl.media.isReady"
            tooltip='{{ "proof.media-player.audio.nudge-forward" | translate }}'
            tooltip-direction="up center"
            tooltip-style="buttons">
        <inline-svg src="img/content/proof/icons/video/next_frame.svg"></inline-svg>
    </button>
    <button class="page__proof__toolbar__button--video-buttons--skip page__proof__toolbar__button"
            ng-class="{'page__proof__toolbar__button--video-buttons-disabled': !mediaControlsCtrl.media.canSkipForward }"
            ng-if="!mediaControlsCtrl.media.isShortAudio"
            ng-click="mediaControlsCtrl.media.skipForward(5)"
            ng-disabled="!mediaControlsCtrl.media.isReady"
            tooltip='{{ "proof.media-player.audio.skip-forward" | translate }}'
            tooltip-direction="up center"
            tooltip-style="buttons">
        <inline-svg src="img/content/proof/icons/video/skip_forward_5.svg"></inline-svg>
    </button>
    <span class="page__proof__duration page__proof__duration--desktop" ng-if="mediaControlsCtrl.media.duration">
    {{ mediaControlsCtrl.media.time | videoTime }} / {{ mediaControlsCtrl.media.duration | videoTime }}
</span>
`;

function MediaControlsController() {
    // Convert to class if this gets more complex...
    this.media = null;
}

function MediaControlsDirective(directiveHelper) {
    return {
        require: ['mediaControls'],
        controller: MediaControlsController,
        controllerAs: 'mediaControlsCtrl',
        template: mediaControlsTemplate,

        link(scope, element, attrs, [mediaControlsCtrl]) {
            directiveHelper.oneWayBinding(scope, attrs, 'media', mediaControlsCtrl, 'media');
            directiveHelper.expressionBinding(scope, attrs, 'walkthroughHook', mediaControlsCtrl, 'walkthroughHook');
        }
    };
}

app.directive('mediaControls', MediaControlsDirective);
