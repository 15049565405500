/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
'use strict';

/* Controllers */

var ppxControllers = angular.module('ppxControllers', ['pascalprecht.translate', 'moment']);
var i18n = window.__pageproof_i18n__;

ppxControllers.service('I18nLoader', function () {
    return function (options) {
        return i18n.getTranslations(options.key);
    };
});

ppxControllers.config(function ($translateProvider) {
    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.useLoader('I18nLoader');

    $translateProvider.translations('fallback', i18n.getFallbackTranslations());
    $translateProvider.fallbackLanguage('fallback');

    $translateProvider.preferredLanguage(i18n.getLocale());
});

ppxControllers.run(function ($translate, $rootScope) {
    i18n.on('did-change-locale', function () {
        $translate.use(i18n.getLocale());
        if (!$rootScope.$$phase) $rootScope.$apply();
    });
});

ppxControllers.run([

    '$rootScope', '$location', 'UserService',
    function ($rootScope, $location, application) {
        var ignoreRedirect = ['activate', 'logout'];

        $rootScope.$on('$locationChangeStart', function (event, next, current) {
            if (application.IsLoggedIn() && ! application.IsActivated()) {
                var redirectTo = 'activate/' + application.GetUserData().userId,
                    segments = $location.path().substring(1).split('/'),
                    ignore = ignoreRedirect.some(function (segment) { return segment === segments[0] });

                if ( ! ignore) {
                    event.preventDefault();
                    $location.path(redirectTo).search({});
                }
            }
        });
    }

]);

ppxControllers.run(function ($rootScope, $location) {
    $rootScope.$on('$locationChangeSuccess', function () {
    });
});

ppxControllers.run([
    '$rootScope', '$location', '$interval', '$timeout', 'UserService', 'DataService', 'bugsnag', 'Activity', '$webSocket', 'utilService', 'userService', 'sdk', 'backgroundService', 'proofInfoService', 'liveClient',
    function ($rootScope, $location, $interval, $timeout, application, data, bugsnag, Activity, $webSocket, utilService, userService, sdk, backgroundService, proofInfoService, liveClient) {
    var $window = $(window);

    function _ () {
        if ( ! $rootScope.$$phase) $rootScope.$apply();
    }

    $rootScope.getUserInitials = window.getUserInitials;
    $rootScope.displayMarketingNavigation = false;
    $rootScope.currentUser = application.IsLoggedIn() ? application.GetUserData() : null;

    window.addEventListener("storage", message_receive);

    $rootScope.logos = {
        user: null,
        proof: null,
        login: null,
    };

    function setTabRefreshMessage() {
        var message = 'refreshTab';
        localStorage.setItem(message, true);
        localStorage.removeItem(message);
    }

    function message_receive(event) {
        if (event.key === 'refreshTab') {
            window.location.reload();
        }
    }

    function updateSDKSession() {
        sdk.setSession(window.__pageproof_quark__.session.getSavedSession());
    }

    function updateUserLogo(user) {
        $rootScope.logos.user = (user && user.brandingUrl) ? user.brandingUrl : null;
    }

    window.__pageproof_setLogo = function (url, type) {
        $rootScope.logos[type || 'user'] = url;
        _();
    };

    function updateBugsnagUserData (user) {
        bugsnag.user = user ? {
            id: user.userId,
            accountType: user.accountType,
            isPremium: user.accountType === 1,
            isActivated: user.isActivated,
            isDomainAdmin: user.isDomainAdmin,
            lastUpload: user.lastUpload
        } : {};
    }

    function updateAppcuesUserData (user) {
        if (window.Appcues) {
            if (user) {
                Appcues.identify(user.userId, {
                    email: user.userEmail
                });
            } else {
                Appcues.identify(null, {});
            }
        }
    }

    updateSDKSession();
    updateUserLogo($rootScope.currentUser);
    updateBugsnagUserData($rootScope.currentUser);
    updateAppcuesUserData($rootScope.currentUser);

    if ($rootScope.currentUser) {
        liveClient.connect();
    }

    $window.on('userlogin.pageproof', function (event) {
        $rootScope.currentUser = event.user;
        updateSDKSession();
        updateUserLogo($rootScope.currentUser);
        updateBugsnagUserData($rootScope.currentUser);
        updateAppcuesUserData($rootScope.currentUser);
        liveClient.connect();
        setTabRefreshMessage();

        _();
    });

    $window.on('userlogout.pageproof', function (event) {
        $rootScope.currentUser = null;
        updateSDKSession();
        updateUserLogo($rootScope.currentUser);
        updateBugsnagUserData($rootScope.currentUser);
        updateAppcuesUserData($rootScope.currentUser);
        data.clearData();
        updateRootId();
        liveClient.disconnect();

        _();
    });

    $window.on('unauthorised.pageproof', function (event) {
        event.preventDefault();
        backgroundService.clearTasks();
        proofInfoService.close();
        if (application.IsActivated()) {
            application.Logout(function () {

                updateSDKSession();
                Activity.clearData();
                $webSocket.close();
                liveClient.disconnect();


                $location.backward().url('logout');
                _();
            });
        }
    });

    $rootScope.$on('$routeChangeStart', function (event) {
        $window.trigger($.Event('routechange.pageproof', {
            $scope: event.targetScope
        }));
    });

    $rootScope.$on('$routeChangeSuccess', function (event) {
        $window.trigger($.Event('routechanged.pageproof', {
            $scope: event.targetScope
        }));
    });

    (function () {
        var cue, last;
        $rootScope.$on('$locationChangeSuccess', function (event) {
            if (window.Appcues) {
                if (cue) {
                    $timeout.cancel(cue);
                    cue = null;
                }
                cue = $timeout(function () {
                    var current = $location.url();
                    if (current !== last) {
                        last = current;
                        console.log('Sending location ping to Appcues (%s)', location.href);
                        Appcues.start();
                    }
                    cue = null;
                }, 500);
            }
        });
    })();

    $rootScope.$on('$includeContentLoaded', function (event) {
        $window.trigger($.Event('routeloaded.pageproof', {
            $scope: event.targetScope
        }));
    });

    $rootScope.goHomepage = function () {
        if (application.IsLoggedIn()) {
            $location.path('dashboard').search({});
        } else {
            $location.url('/');
        }
    };

    function updateRootId () {
        try {
            var userData = application.GetUserData();

            if (userData.userId) {
                document.documentElement.id = '_' + utilService.md5(userData.userId);
            } else {
                document.documentElement.id = '';
            }
        } catch (e) {}
    }

    //$interval(function () {
    //    if ($rootScope.currentUser) application.PullUserData();
    //}, application.GetConfigSetting('AutoPullUserData')); // 2 mins

    if ($rootScope.currentUser) {
        application.PullUserData();
        userService.populateUser();
    }

    application.on('userDataUpdated', function (userData) {
        $rootScope.currentUser = userData;
        console.log('pulled user data', userData);

        updateSDKSession();
        updateRootId();

        if ( ! $rootScope.$$phase) $rootScope.$apply();
    });

    //if ($rootScope.currentUser) {
    //    application.PullUserData();
    //}
}]);

ppxControllers.directive('ngModelOnblur', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        priority: 1, // needed for angular 1.2.x
        link: function(scope, elm, attr, ngModelCtrl) {
            if (attr.type === 'radio' || attr.type === 'checkbox') return;

            elm.unbind('input').unbind('keydown').unbind('change');
            elm.bind('blur', function() {
                scope.$apply(function() {
                    ngModelCtrl.$setViewValue(elm.val());
                });
            });
        }
    };
});

ppxControllers.directive('focusThis', function($timeout) {
    return {
        scope: { trigger: '@focusThis' },
        link: function(scope, element) {
            scope.$watch('trigger', function(value) {
                if(value === "true") {
                    $timeout(function() {
                        //element[0].focus();
                    });
                }
            });
        }
    };
});

ppxControllers.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.ngEnter, {'$event': event});
                });

                event.preventDefault();
            }
        });
    };
});

/*
directive that fires a function when the image loads. usage: ng-load="onImgLoad($event);"
 */

ppxControllers.directive('ngLoad', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var fn = $parse(attrs.ngLoad);
            elem.on('load', function (event) {
                scope.$apply(function () {
                    fn(scope, {$event: event});
                });
            });
        }
    };
}]);

ppxControllers.run(function ($rootScope, seoService, $injector) {
    var $intercomStyle = document.createElement('style');
    document.head.appendChild($intercomStyle);

    function updateIntercomVisibility (show) {
        // TODO Clean this up (if Intercom have an api for hiding/showing the activator)
        $intercomStyle.textContent = '#intercom-container, #intercom-activator, .intercom-lightweight-app-launcher, .intercom-launcher { display: ' + (show ? 'block' : 'none') + ' !important }';
    }

    $rootScope.$on('$routeChangeSuccess', function (event, next) {
        var data = next.$$route && next.$$route.data || {},
            title;

        if (angular.isString(data.title)) {
            title = data.title;
        } else if (angular.isArray(data.title) || angular.isFunction(data.title)) {
            title = $injector.invoke([].concat(
                $injector.annotate(data.title),
                data.title
            ));
        }

        seoService.set({
            title: title,
            description: data.description
        });

        $rootScope.displayMarketingNavigation = !! data.marketing;

        updateIntercomVisibility(data.intercom !== false);
        Intercom('update', { last_request_at: (new Date()).getTime() });
    });

    $rootScope.seo = seoService;
});

ppxControllers.run(['$rootScope', function ($rootScope) {
    $rootScope.$watch('currentUser', function (user) {
        if (user && user.features.supportChat) {
            Intercom('boot', {
                app_id: env('intercom_key'),
                email: user.userEmail,
                user_id: user.userId,
                widget: {
                    activator: '#intercom-activator'
                }
            });
        } else if (!user) {
            Intercom('boot', {
                app_id: env('intercom_key'),
                email: null,
                user_id: null,
                widget: {
                    activator: '#intercom-activator'
                }
            });
        } else {
            Intercom('update', {
                "hide_default_launcher": true,
            });
        }
    });
}]);
