/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class StaticProofController extends GenericProofController {
    $extractedTextResources = null;
    $extractedTextPages = {};
    textSelectionAvailable = false;
    showColorSeparations = false;
    currentPageText = null;
    interactionMode = 'pin';
    pages = [];
    barcodesByPage = {};

    constructor() {
        super();

        this.gettingStartedKey = 'pageproof.app.proof.hasDismissedProofToolsIntroduction';

        this.$$import(this.$$dependencies([
            'storageService',
        ]));

        const localDrawModeOrder = this.$$.storageService('pageproof.app.pen.').json('order-modes') || [];
        this.interactionMode = localDrawModeOrder[0] || 'pin';

        this.load()
            .then(() => this.$$.appService.showTranslatedLoaderMessage('loader-message.decrypting-proof'))
            .then(() => (this.loadExtractedTextResources(), undefined))
            .then(() => this.bindCurrentPageToRouteParams())
            .then(() => this.loadFirstPagePreview())
            .then(() => this.$$.appService.hideLoader())
            .then(() => this.didLoad())
            .then(() => this.setHasSeenMultiplePages());

        const proofCtrl = this;
        this.barcodeScannerProps = {
            isEnabled: false,
            isHighlighting: true,
            get pageNumber() {
                return proofCtrl.currentPage;
            },
            canCreateComment() {
                return proofCtrl.permissions.proofer.commentLevel.canCreate && !proofCtrl.isCreatingComment;
            },
            onCreateComment: (barcode) => {
                this.startCreateComment({
                    x1: (barcode.boundingBox.x / this.barcodeScannerProps.image.width) - 0.5,
                    y1: (barcode.boundingBox.y / this.barcodeScannerProps.image.height) - 0.5,
                    x2: ((barcode.boundingBox.x + barcode.boundingBox.width) / this.barcodeScannerProps.image.width) - 0.5,
                    y2: ((barcode.boundingBox.y + barcode.boundingBox.height) / this.barcodeScannerProps.image.height) - 0.5,
                }, `${barcode.rawValue}`);
            },
            onIsHighlightingChanged: (isHighlighting) => {
                this.updateBarcodeScannerProps({ isHighlighting });
            },
            onClose: () => {
                this.setEnableBarcodeScanner(false);
            },
        };
        this.jumpToInputProps = {
            get currentValue() {
                return proofCtrl.currentPage;
            },
            get max() {
                return proofCtrl.proof.pageCount;
            },
            min: 1,
            onChange: (pageNumber) => proofCtrl.switchPage({pageNumber}),
        }
        this.pageNavigationProps = {
            get currentPage() {
                return proofCtrl.currentPage;
            },
            get pageCount() {
                return proofCtrl.proof.pageCount;
            },
            onClickPrevious: () => this.previousPage(),
            onClickNext: () => this.nextPage(),
            onOpenPageGrid: () => this.togglePages(),
        }
    }

    /**
     * Initialise page navigation to URL.
     */
    bindCurrentPageToRouteParams() {
        if (this.proof.pageCount > 1) {
            this.bindRouteParams([{
                key: 'currentPage',
                searchParam: 'page',
                read: (value) =>  + value || 1,
                write: (filter) => String(filter),
            }], true);
        }
    }

    setHasSeenMultiplePages() {
        this.hasSeenMultiplePages = this.hasSeenMultiplePages ||
            this.proof.pages.some(page => page.pageNumber > 1 && page.comments.some(comment => comment.ownerId === this.user.id));
    }

    setColorSeparationVisibility(visibility) {
        return (pageNumber) => {
            this.showColorSeparations = visibility;

            if (pageNumber) {
                this.switchPage({ pageNumber });
            }

            if (visibility) {
                this.$$.SegmentIo.track(61, {
                    'proof id': this.proofId,
                    'file id': this.proof.fileId,
                }); // Color separation mode
            }
        };
    }

    loadExtractedTextResources() {
        this.$extractedTextResources = this.$$.sdk.proofs.assets.extractedText.resources(this.proofId);
        this.$extractedTextResources.then((resources) => {
            if (!resources) return;
            this.textSelectionAvailable = !(this.isMobile || this.isTablet); // disable text selection on touch devices
        });
        return this.$extractedTextResources;
    }

    getExtractedTextForPage(pageNumber, retry = true) {
        if (!this.$extractedTextPages[pageNumber]) {
            this.$extractedTextPages[pageNumber] = (
                this.$extractedTextResources
                    .then((resources) => {
                        if (!resources) return;
                        return (
                            this.$$.sdk.proofs.assets.extractedText.download(resources, pageNumber)
    
                                // handle expired urls, non-existent pages, and other errors by loading the extracted text resources again, and retrying the download
                                // if it fails for a second time, we assume the error cannot be avoided - most likely the extracted text file doesn't exist.
                                .catch((err) => (retry
                                    ? (
                                        this.loadExtractedTextResources()
                                            .then(() => {
                                                // delete the previously cached entry, because it has proven to be erroneous
                                                delete this.$extractedTextPages[pageNumber];
                                                return this.getExtractedTextForPage(pageNumber, false); // recursive
                                            })
                                    )
                                    : (
                                        delete this.$extractedTextPages[pageNumber],
                                        Promise.reject(err)
                                    )
                                ))
                                .then((extractedText) => {
                                    return [
                                        extractedText,
                                        resources.pages.filter(page => page.number === pageNumber)[0].dimensions,
                                    ];
                                })
                        )
                    })
            );
        }
        return this.$extractedTextPages[pageNumber];
    }

    initWatchers() {
        super.initWatchers();

        this.beforeDestroy(this.$watch('showComments', (showComments) => {
            if (this.canvas) {
                this.canvas.setOffsets({
                    left: showComments ? -(this.commentPaneWidth / 2) : 0,
                }, true, 500);
            }
        }));

        const proofStorage = this.$$.storageService('pageproof.app.proof.' + this.proofId +'.' + this.user.id + '.');

        this.beforeDestroy(this.$watch('showPages', (showPages) => {
            if (showPages) {
                proofStorage.set('has-seen-multiple-pages', 'true');
            }
        }));

        this.beforeDestroy(this.$watch('currentPage', (currentPage) => {
            if (currentPage > 1) {
                proofStorage.set('has-seen-multiple-pages', 'true');
            }
            if (this.barcodeScannerProps.isEnabled) {
                this.scanBarcodes(currentPage);
            }
        }));

        this.beforeDestroy(proofStorage.watch('has-seen-multiple-pages', (_, hasSeenMultiplePages) => {
            this.hasSeenMultiplePages = hasSeenMultiplePages === 'true';
        },  true));        
    }

    initKeyboardShortcuts() {
        super.initKeyboardShortcuts();
        super.initCanvasKeyboardShortcuts();

        this.beforeDestroy(this.$$.shortcutService.watch('barcodeScanner', () => {
            this.setEnableBarcodeScanner(!this.barcodeScannerProps.isEnabled);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('barcodeScannerClose', () => {
            if (this.barcodeScannerProps.isEnabled) {
                this.setEnableBarcodeScanner(false);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('barcodeScannerSpotlight', () => {
            if (this.barcodeScannerProps.isEnabled) {
                this.updateBarcodeScannerProps({
                    isHighlighting: !this.barcodeScannerProps.isHighlighting,
                });
            }
        }));
    }

    loadFirstPagePreview() {
        const currentPageObj = this.proof.pages.find(page => page.pageNumber === this.currentPage);

        if (currentPageObj.comments.length) {
            this.withCommentsPane((commentsPane) => commentsPane.scrollToPage(this.currentPage));
        }
        this.loadPageText(currentPageObj);
        return this.loadPagePreview(currentPageObj);
    }

    loadPagePreview(page) {
        return super.loadPagePreview(page)
            .then((preview) => {
                this.showPages = false;
                return preview;
            })
            .then(() => {
                this.preLoadPages();
            });
    }

    togglePages() {
        this.showComments = false;
        this.showPages = !this.showPages;
        // delay applied between scale in animation & scrollToPage
        this.$$.$timeout(() => this.scrollToPage(), 500);
    }

    scrollToPage() {
        return this.$$.domService.scrollTo(`.js-page-grid-current-page`, 500, 0 - (window.innerHeight / 2) + 100);
    }

    fakeSwitchPage() {
        this.showPages = true;
        const currentPage = this.proof.getPage(this.currentPage);
        return this.requestLoadPage(currentPage);
    }

    canSwitchPages() {
        return !this.loadingPage;
    }

    showText() {
        if (this.currentPageText) {
            const [extractedText, dimensions] = this.currentPageText;
            this.canvas.setText(extractedText, dimensions);
            this.canvas.showText();
        }
    }

    enableDrawing() {
        if (this.canvas) {
            this.canvas.enableDrawing();
        }
    }

    loadPageText(page) {
        if (this.canvas) {
            this.currentPageText = null;
            this.canvas.removeText();
        }
        this.getExtractedTextForPage(page.pageNumber)
            .then((response) => {
                if (!response) return;
                const [extractedText, dimensions] = response;
                if (this.currentPage === page.pageNumber) {
                    this.currentPageText = [extractedText, dimensions];
                    if (this.isCommenting) {
                        this.showText();
                    }
                }
            });
    }

    whenLoadPage(pageObj) {
        const { page, pageNumber } = pageObj;
        if (page.comments.length) {
            this.withCommentsPane((commentsPane) => commentsPane.scrollToPage(pageNumber));
        }
        this.loadPageText(page);
        return this.loadPagePreview(page);
    }

    switchPageCheck(page) {
        if (this.canSwitchPages() && super.switchPage(page)) {
            return this.fakeSwitchPage();
        }
    }

    previousPage() {
        if (this.canSwitchPages() && super.previousPage()) {
            return this.fakeSwitchPage();
        }
    }

    nextPage() {
        if (this.canSwitchPages() && super.nextPage()) {
            return this.fakeSwitchPage();
        }
    }

    selectComment(comment, scroll = false, canPanToPin = true, closeTooltip = false) {
        this.toggleTooltip(false, comment);
        return super.selectComment(comment, scroll).then((comment) => {
            this.$$.$timeout(() => { // This delay is to work panToPin(), when clicks on a comment from a different page
                if (canPanToPin) {
                    const pins = this.canvas.getPinsByIdPrefix(comment.id);

                    if (this.isGeneralComment(comment)) {
                        const { x, y } = this.canvas._getFitScales();
                        const fitScale = Math.min(x, y);

                        if (Math.round(fitScale * 100) < Math.round(this.canvas.getZoom() * 100)) {
                            this.canvas.fit();
                        }

                        this.flashingGeneralComment = null;
                        this.$$.$timeout(() => this.flashingGeneralComment = comment);
                    } else {
                        this.canvas.panToPins(pins);
                    }
                }
                if (closeTooltip) {
                    this.toggleCommentPinTooltip(false, comment, null)
                }
            });
            return comment;
        });
    }

    flashComment(comment) {
        if (this.isGeneralComment(comment)) {
            this.flashingGeneralComment = null;
            this.$$.$timeout(() => this.flashingGeneralComment = comment);
        } else {
            this.canvas.flashPinsByIdPrefix(comment.id);
        }
    }

    preLoadPages() {
        if (this.currentPage < this.proof.pageCount) {
            super.loadPagePreview(this.proof.pages[this.currentPage]);
        }
        if (this.currentPage > 1) {
            super.loadPagePreview(this.proof.pages[this.currentPage - 2]);
        }
    }

    finishCreateComment(comment, selectComment) {
        this.resetDrawing();
        return super.finishCreateComment(comment, selectComment);
    }

   /**
     * Toggles focus mode of static proof page
     */
    toggleFocusMode = () => {
        if (this.canvas.image && (!this.isSmallScreen || this.focusMode)) {
            this.focusMode = !this.focusMode;
            this.toggleFocusModeInHeader(this.focusMode);
            if (this.focusMode) {
                this.beforeFocusZoomLevel = this.getZoom();
                const { width, height } = document.documentElement.getBoundingClientRect();
                const focusZoomLevel = Math.min((width / this.canvas.image.getScaledWidth()), (height / this.canvas.image.getScaledHeight()));
                this.canvas._moveToCenter();
                this.zoomTo(Math.min(Math.floor(focusZoomLevel * 100), 100));
            } else {
                this.zoomTo(this.beforeFocusZoomLevel);
            }
        }
    }

    setEnableBarcodeScanner(isEnabled) {
        if (!this.hasInitializedBarcodeScanner && isEnabled) {
            const overlayElement = this.canvas.getCustomOverlayElement();
            const barcodeScannerOverlayElement = document.createElement('div');
            overlayElement.appendChild(barcodeScannerOverlayElement);
            this.barcodeScannerOverlayElement = barcodeScannerOverlayElement;

            // Ensure we don't re-initialize the barcode scanner
            this.hasInitializedBarcodeScanner = true;
        }

        this.updateBarcodeScannerProps({
            isEnabled,
        });

        // Kickstart the scanner on the current page
        if (isEnabled) {
            this.$$.SegmentIo.track(64, {
                'proof id': this.proofId,
            });
            this.scanBarcodes(this.currentPage);
        }
    }

    scanBarcodes(pageNumber) {
        if (!this.barcodeScannerProps.isEnabled) {
            console.warn('Cannot scan for barcodes when the feature is not enabled.');
            return;
        }

        if (!this.barcodesByPage[pageNumber]) {
            this.barcodesByPage[pageNumber] = window.__pageproof_quark__.makeUnstableBackendRequest({
                path: '/api/barcodes/scan',
                query: {
                    proofId: this.proofId,
                    pageNumber,
                },
            });
        }

        const promise = this.barcodesByPage[pageNumber];
        promise.then((result) => {
            if (this.currentPage === pageNumber) {
                this.updateBarcodeScannerProps({
                    isLoading: false,
                    image: result.image,
                    barcodes: result.barcodes,
                });
            }
        });

        this.updateBarcodeScannerProps({
            isLoading: true,
            image: null,
            barcodes: null,
        });

        return promise;
    }

    updateBarcodeScannerProps(updates) {
        Object.assign(this.barcodeScannerProps, updates);
        window.__pageproof_quark__.features.renderBarcodeScannerOverlay(
            this.barcodeScannerOverlayElement,
            this.barcodeScannerProps
        );
        if (!this.$$.$rootScope.$$phase) {
            this.$$.$rootScope.$apply();
        }
    }

    getPageGridViewProps() {
        return {
          loadingPage: this.loadingPage,
          currentPage: this.currentPage,
          pages: this.proof.pages,
          onPageSelect: (selectedPage) => this.switchPage(selectedPage),
          fileId: this.proof.fileId,
          show: this.proof.pages.length > 1 && this.showPages,
          pagesMetadata: this.proof.pagesMetadata,
        }
    }

    switchPage(page) {
        if (this.loadingPage) return;

        if (this.currentPage !== page.pageNumber) {
          this.$$.$q.when(this.switchPageCheck({
              pageNumber: page.pageNumber,
              page,
          }))
          .then((allow) => {
            return allow && this.requestLoadPage(page)
          });
        }
    }

    requestLoadPage(page) {
        if (this.loadingPage) return;
        this.loadingPage = page.pageNumber;

        return (
            this.$$.$q.when(this.whenLoadPage({
                pageNumber: page.pageNumber,
                page: page,
            }))
            .then(() => this.loadingPage = 0)
        );
    }
}

app
    .controller('StaticProofController', StaticProofController);
