/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const DEFAULT_STATIC_ENDPOINT = 'https://{id}.static.pageproof.com';
const IFRAME_VERTICAL_PADDING = 200;
const IFRAME_HORIZONTAL_PADDING = 200;
const MOBILE_IFRAME_VERTICAL_PADDING = 68 * 2; // On mobile footer and header have 68px of padding
const MOBILE_IFRAME_HORIZONTAL_PADDING = 0;
const IFRAME_TOP_ON_FOCUS_MODE = 10; // On focus mode, iframe's top position.
const RECENTLY_SELECTED_DEVICE_KEY_PREFIX = 'pageproof.app.recently-selected-device.'; 
const DONT_PROMPT_TO_INSTALL_EXTENSION_KEY_PREFIX = 'pageproof.app.dont-prompt-to-install-extension.'; 

class WebProofController extends GenericProofController {
    /**
     * Whether or not to enable the individual progress percentage.
     *
     * @type {number}
     */
    enableProgress = true;

    rulerViewport = {
        width: 0,
        height: 0,
    }

    measurementUnit = 'px';

    extensionInstalled = false;
    
    extensionInfo = {
        capabilities: [],
    };

    constructor() {
        super();

        this.gettingStartedKey = 'pageproof.app.proof.web.welcome.dismissed';

        this.$$import(this.$$dependencies([
            '$q',
            '$exceptionHandler',
            'SegmentIo',
            'zipService',
            'staticService',
            'utilService',
            'browserService',
            'temporaryStorageService',
            'fileService',
            'appService',
            'PPProofCommentSnapshot',
            '$scope',
        ]));

        this.$$.SegmentIo.track(49, {
            'proof id': this.proofId,
        });

        this.load()
            .then(() => (super.didLoad(), null))
            .then(() => {
                this.isExternal = this.webProofProps.isExternal = (this.proof.fileType === 'external-Web');
                // We can't tell when an external proof is loaded, so we always show it as loaded
                if (this.isExternal) {
                    this.isLoading = this.webProofProps.isLoading = false;
                }
                this.isManaged = this.proof.isManagedWebUrl;
                if (!this.isExternal) {
                    this.progress = [10, null, 'proof.web.progress.getting-html-setup'];
                    return (
                        this.loadOrigin()
                            .then(() => (
                                this.origin.isComplete().then(isComplete => {
                                    if (!isComplete) {
                                        this.progress = [20, 0, 'proof.web.progress.downloading-file'];
                                        return (
                                            this.downloadZipFile()
                                                .then(file => (this.progress = [60, 0, 'proof.web.progress.unzipping'], file))
                                                .then(file => this.loadFile(file))
                                        );
                                    }
                                })
                            ))
                    );
                }
            })
            .then(() => this.calculateEntryFile())
            .then(() => this.progress = [90, null, this.isExternal ? 'proof.web.progress.loading-website' : 'proof.web.progress.loading-html'])
            .then(() => this.embedProof())
            .then(() => this.progress = null)
            .then(() => this.didFinishInitialising())
            .catch(err => this.handleError(err));

        this.setupLicenseHandshakeHandler();

        this.entryFile = '';
        this.isExternal = false; // Default
        const isMobile = this.$$.browserService.is('mobile');
        const that = this;
        this.recentlySelectedDeviceKey = RECENTLY_SELECTED_DEVICE_KEY_PREFIX + `${this.user.id}.${this.proofId}`;
        this.dontPromptToInstallExtensionKey = DONT_PROMPT_TO_INSTALL_EXTENSION_KEY_PREFIX + `${this.user.id}`;
        const commentPaneHandleWidth = isMobile ? 0 : 100;
        this.webProofProps = {
            device: window.localStorage.getItem(this.recentlySelectedDeviceKey),
            isFitViewport: false,
            contentHeight: 0,
            customDevices: [
                {
                    icon: isMobile ? 'phone' : 'desktop',
                    name: window.__pageproof_quark__.translation.createReactTranslationElement('device.my-screen'),
                    width: screen.width,
                    height: screen.height,
                },
            ],
            orientation: 'vertical',
            speed: null,
            url: null,
            isLoading: true,
            get commentPaneWidth() {
                return that.showComments && !that.isMobile 
                    ? +that.commentPaneWidth + commentPaneHandleWidth 
                    : commentPaneHandleWidth;
            },
            focusMode: false,
            isExternal: this.isExternal,

            onDeviceChange: device => this.setDevice(device),
            onOrientationChange: orientation => this.setOrientation(orientation),
            onSpeedChange: speed => this.onSpeedChange(speed),
            onFrameCreate: frame => this.handleFrameCreate(frame),
            onDimensionsChange: (dimensions) => {
                // Update temporary snapshot canvas image size on on window size change
                if (this.isTransparentSnapshotWhileCreatingComment()) {
                    this.canvas._100OrFit(false);
                }
                /*
                    Basically, Gridlines's position is calculated by following realtime current px-canvas position.
                    On viewing an existing snapshot on Web proof, can get the current px-canvas position by `when-move-canvas` event.
                    But, On viewing a website as Iframe, needs to get iframe position, not a px-canvas.
                */
                if (this.preview.mode === null) {
                    this.isInitGridRulers = true;
                    this.dimensions = dimensions;
                    this.currentCanvasPosition = {
                        top: (this.focusMode ? IFRAME_TOP_ON_FOCUS_MODE : (IFRAME_VERTICAL_PADDING / 2)) + (dimensions.container.height - dimensions.logical.height) / 2,
                        left: (IFRAME_HORIZONTAL_PADDING / 2) + (dimensions.container.width - dimensions.logical.width) / 2,
                        width: dimensions.logical.width,
                        height: dimensions.logical.height,
                        zoomLevel: dimensions.scale,
                        angle: 0,
                        isRotating: false,
                    };
                    this.rulerViewport = {
                        width: dimensions.originalViewport.width,
                        height: dimensions.originalViewport.height,
                    };
                }
            },
            onRefresh: () => {
                if (!this.isExternal || !this.extensionInstalled) {
                    this.refresh();
                    return;
                }

                this.getPageInfo()
                    .then(data => {
                        this.webProofProps.url = data.url;
                    })
                    .finally(() => this.refresh());       
            },
            onStopRefresh: () => this.stopRefresh(),
            onFullscreen:() => this.frame.requestFullscreen(),
            onHome: () => {
                this.embedProof();
                this.refresh();
            },
            onToggleFitViewport: () => {
                this.webProofProps.isFitViewport = !this.webProofProps.isFitViewport;
                this.webProofProps.onDimensionsChange(this.dimensions);
            },
        };

        this.preview = {
            mode: null, // null, 'create', 'existing'
        };

        this.progress = null;

        this.updateCanvasPosition = (data) => {
            if ((that.preview.mode === 'create' && !that.$loadingSnapshot) ||
                (that.preview.mode === 'existing' && that.snapshot && !that.$loadingSnapshot)) {
                super.updateCurrentCanvasPosition(data);
            }
        }

        this.whenImageLoad = (canvas, id) => {
            that.updateCanvasOffset(canvas);
            canvas.setFitPadding({ height: 200 });
            canvas.setKeyline(!that.isShowingTemporarySnapshot);
            canvas._100OrFit(false);
            canvas.flashPinsByIdPrefix(id);
        };

        this.beforeDestroy(() => {
            document.documentElement.classList.remove('takingSnapshot');
        });
    }

    setupLicenseHandshakeHandler() {
        function obtainLicense() {
            const sdk = __pageproof_bridge__.sdk;
            const method = 'POST';
            const path = '/api/wps2/obtain_license';
            return (
                sdk.crypto()
                    .generateAuthorizationHeaders(method, path, sdk.session)
                    .then(headers => sdk.rawRequest({
                        method: method,
                        url: new URL(path, window.env('api_url')),
                        body: null,
                        headers,
                    }))
                    .then(response => JSON.parse(response.body))
            );
        }

        function onMessage(event) {
            if (event.data.type === 'wps2:obtainLicense') {
                obtainLicense().then((response) => {
                    const webProofElement = document.querySelector('.WebProof__frame');
                    webProofElement.contentWindow.postMessage({
                        type: 'wps2:obtainedLicense',
                        response,
                    }, event.origin);
                });
            }
        }

        window.addEventListener('message', onMessage);
        this.beforeDestroy(() => {
            window.removeEventListener('message', onMessage);
        });
    }

    load() {
        const { isReady } = window.__pageproof_quark__.webProofUrls;

        this.supportsExtension = this.$$.browserService.is('desktop') && this.$$.browserService.any(
            'chrome', // https://chrome.google.com/webstore/detail/pageproof-online-review-a/khbdkbdlepgilfkdiemkebkepekakebg
            'safari', // https://apps.apple.com/us/app/id1507023716
            'firefox', // https://addons.mozilla.org/en-US/firefox/addon/pageproof-online-review/
            'edge' // https://microsoftedge.microsoft.com/addons/detail/ieddepbggaaolpoaigbfpmkakccanpkl
        );

        return Promise.all([
            window.detectExtension().then((extensionInfo) => {
                this.extensionInstalled = !!extensionInfo;

                if (typeof extensionInfo !== 'boolean') {
                    this.extensionInfo = extensionInfo;
                }
            }),
            isReady(),
        ]).then(() => {
            return super.load();
        });
    }

    startCreateComment (data, text) {
        if (this.isTransparentSnapshotWhileCreatingComment()) {
            this.canvas.setEnablePan(false);
        }
        super.startCreateComment(data, text);
    }

    preLoadChecks() {
        // We still require the browser extension installed to proof web URL proofs.
        this.needsExtension = this.proof.fileType === 'external-Web';

        const dontPromptToInstallExtension = window.localStorage.getItem(this.dontPromptToInstallExtensionKey) === 'true';
        const continueWithoutInstallingExtension = ( 
          this.proof.fileType === 'zip-Web' &&
          this.$$.browserService.any('mobile', 'tablet')
        );

        if (
            !continueWithoutInstallingExtension &&
            !this.extensionInstalled && (
                // If the extension MUST be installed in order 
                this.needsExtension ||
                !dontPromptToInstallExtension
            )
        ) {
            this.$$.appService.hideLoader();
            this.installExtensionPromptModalProps = {
                url: this.proof.importUrl,
                fileType: this.proof.fileType,
                canInstall: !this.extensionInstalled && this.supportsExtension,
                onContinue: this.continueWithoutExtension,
                onClose: () => {
                  this.deferredPreloadCheck.resolve(false);
                  this.redirectTo('dashboard');
                },
            };
            this.promptUserInstallExtension = true;
            this.deferredPreloadCheck = this.$$.$q.defer();
            return this.deferredPreloadCheck.promise;
        }

        if (this.hasExtensionCapability('can_set_basic_authentication_credentials')) {
            return this.setupBasicAuthenticationCredentials();
        }

        return true;
    }

    continueWithoutExtension = (dontPromptToInstallExtension) => {
        this.promptUserInstallExtension = false;
        window.localStorage.setItem(this.dontPromptToInstallExtensionKey, dontPromptToInstallExtension);

        return this.setupBasicAuthenticationCredentials()
            .then(() => {
                this.deferredPreloadCheck.resolve(true);
            });
    }

    setupBasicAuthenticationCredentials() {
        if (this.hasBasicAuth() && this.hasExtensionCapability('can_set_basic_authentication_credentials')) {
            const url = new URL(this.proof.importUrl);
            this.beforeDestroy(() => {
                if (this.hasSetupBasicAuthenticationCredentials) {
                    window.setBasicAuthenticationCredentials(url.origin, null);
                }
            });
            return window.setBasicAuthenticationCredentials(url.origin, {
                username: url.username,
                password: url.password,
            }).then(
                () => (this.hasSetupBasicAuthenticationCredentials = true),
                (err) => Bugsnag.notifyException(err)
            );
        }
        return this.$$.$q.resolve(true);
    }

    setDevice(device) {
        this.webProofProps.device = device;
        if (device) {
            window.localStorage.setItem(this.recentlySelectedDeviceKey, device);
        } else {
            window.localStorage.removeItem(this.recentlySelectedDeviceKey);
        }
        this.resetPreview();
    }

    setOrientation(orientation) {
        this.webProofProps.orientation = orientation;
        this.resetPreview();
    }

    isCustomDevice() {
        const {customDevices, device} = this.webProofProps;
        return customDevices.some(dev => device === `${dev.width}x${dev.height}`);
    }

    shouldShowOptions() {
        if (this.isExternal) {
            if (this.proof.importUrl.startsWith('https://xd.adobe.com/embed')) {
                return false;
            }
        }
        return true;
    }

    jumpToContentSizeWhenLoaded() {
        if (
            !this._hasJumpedToFitContent &&
            this._hasDocumentLoaded &&
            !this.isExternal &&
            this._hasReceivedContentSize &&
            this.webProofProps.device === null
        ) {
            this.setFittedContentMode();
        }
    }

    calculateEntryFile() {
        if (!this.isExternal) {
            return this.origin.entry().then((entryFile) => {
                this.entryFile = entryFile;
            });
        }
    }

    setFittedContentMode() {
        // If content width fits to viewport we set one of the Content modes.
        const leftRightPadding = this.isMobile ? MOBILE_IFRAME_HORIZONTAL_PADDING : IFRAME_HORIZONTAL_PADDING;
        const topBottomPadding = this.isMobile ? MOBILE_IFRAME_VERTICAL_PADDING : IFRAME_VERTICAL_PADDING;
        if (this.contentSize.width < window.innerWidth - leftRightPadding) {
            // If content height is bigger than viewport we use 'Fit Content Width'.
            // If height fits to viewport we use 'Content Size'.
            const contentHeight = this.contentSize.height > window.innerHeight - topBottomPadding && 'auto';
            this.setContentMode(contentHeight);
        }
    }

    setContentMode(height) {
        // If height is false or not passed we set 'Content Size' mode using content height.
        const contentHeight = height || this.contentSize.height;
        if (this.contentSize && this.contentSize.width && contentHeight) {
            this._hasJumpedToFitContent = true;
            this.webProofProps.device = `${this.contentSize.width}x${contentHeight}`;
        }
    }

    pollContentSizeHook(callback) {
        let size = {};

        const timer = setInterval(run, 250);
        const off = () => clearInterval(timer);

        const handle = (event) => {
            const currentSize = event;
            if (size.width !== currentSize.width || size.height !== currentSize.height) {
                this.$$.$rootScope.$apply(() => {
                    callback(currentSize, size, off);
                });
            }
            size = currentSize;
        };

        function run() {
            window.__pageproof_hooks__
                .hook('web-proof-content-size', {}, () => null)
                .then(handle);
        }

        run();

        return off;
    }

    onContentResize(callback) {
        if (this.isExternal) {
            return this.pollContentSizeHook(callback);
        } else {
            return this.origin.onContentResize(callback);
        }
    }

    didFinishInitialising() {
        let hasInit = false;

        this.contentSize = {
            icon: 'content',
            name: window.__pageproof_quark__.translation.createReactTranslationElement('device.content-size'),
        };

        this.contentWidth = {
            icon: 'content-width',
            name: window.__pageproof_quark__.translation.createReactTranslationElement('device.fit-content-width'),
        };

        const init = size => {
            if (!hasInit) {
                this.webProofProps.customDevices.push(this.contentSize, this.contentWidth);
                hasInit = true;
            }
        };

        this.beforeDestroy(this.onContentResize((size, previous) => {
            if (size.error) {
                // An error can occur when the static.js lib polls the content before it has actually
                // had a chance to load - something like "Cannot read property 'children' of null".
                return;
            }

            this._hasReceivedContentSize = true;

            this.contentSize.width = size.width;
            this.contentSize.height = size.height;

            this.contentWidth.width = this.contentSize.width;
            this.contentWidth.height = 'auto';

            this.webProofProps.contentHeight = size.height;

            // Initialise the content size option (only when we get the content size initially)
            init(size);

            // Don't just attempt to jump to the content size first time - some pages start out larger than
            // when they're finally finished loading. Some banner ads, for instance.
            this.jumpToContentSizeWhenLoaded();

            // Ensure that if we were in content size mode, that we stay in it whenever the content
            // size changes. This is required because we use the dimensions as the device identifier.
            if (this.webProofProps.device === `${previous.width}x${previous.height}`) {
                this.setFittedContentMode();
            }
        }));
    }

    refresh() {
        this.frame.src = 'data:text/html,Hello World';
        this.frame.src = this.webProofProps.url;
        if (!this.isExternal) {
            this.webProofProps.isLoading = true;
        }
        this.resetPreview();
    }

    stopRefresh() {
        this.origin.stopPage();
        this.webProofProps.isLoading = false;
    }

    onChangeRulerModeOnWeb = (isActivatedRuler) => {
        if (isActivatedRuler) {
            if (!this.preview.mode) {
                // If no preview mode start taking screenshot
                this.startTakingSnapshot()
                  .then(() => {
                      this.onChangeRulerMode(isActivatedRuler);
                  });
            }
            this.onChangeRulerMode(isActivatedRuler);
        } else {
            if (!this.isCreatingComment && !this.comments.selectedComment) {
                this.resetPreview();
            }
            this.onChangeRulerMode(isActivatedRuler);
        }
    }

    initWatchers() {
        super.initWatchers();

        this.beforeDestroy(this.$watch('isCommenting', (isCommenting) => {
            if (isCommenting) {
                this.startTakingSnapshot();
            } else if (!this.temporaryComment && !this.isActivatedRuler) {
                this.resetPreview();
            }
        }));

        this.beforeDestroy(this.$watch('showComments', (showComments) => {
            this.updateCanvasOffset();
            if (!showComments) {
                this.resetPreview();
            }
        }));
    }

    initKeyboardShortcuts() {
        super.initKeyboardShortcuts();
        super.initCanvasKeyboardShortcuts();

        // Toggle the ruler mode (web proofing) (default key binding: -)
        this.beforeDestroy(this.$$.shortcutService.watch('ruler', () => {
            this.onChangeRulerModeOnWeb(!this.isActivatedRuler);
        }));
    }

    startTakingSnapshot() {
        this.snapshot = null;
        this.preview = {
            mode: 'create',
            data: {
                snapshot: null,
                page: null,
                dimensions: null,
                device: this.webProofProps.device,
                orientation: this.webProofProps.orientation,
                speed: this.webProofProps.speed,
            },
        };
        return this.makeWebProofTopLayer(true)
            .then(() => this.takeSnapshot())
            .catch((err) => {
                console.error(err);
                Bugsnag.notifyException(err);
                if (!this.extensionInstalled) {
                    this.setDeviceFromResponsiveToCustom();
                }
            })
            .then(() => this.makeWebProofTopLayer(false));
    }

    makeWebProofTopLayer(bool) {
        document.documentElement.classList[bool ? 'add' : 'remove']('takingSnapshot');

        return window.generalfunctions_sleep(200);
    }
    
    setDeviceFromResponsiveToCustom() {
        if (!this.webProofProps.device) {
            this.webProofProps.device = `${this.dimensions.originalViewport.width}x${this.dimensions.originalViewport.height}`;
        }
    }

    onSpeedChange(speed) {
        this.webProofProps.speed = speed.id;
        this.origin.throttle(speed.id, speed);
        this.resetPreview();
    }

    getSnapshot = window.__pageproof_hooks__.hookable('take-screenshot', () => {
        return Promise.reject(new Error('Extension is not available to take snapshot'));
    });

    takeSnapshot() {
        // Generate a temporary spacer image to display in place of the snapshot
        const { width, height } = this.dimensions.logical;
        this.temporaryImage = {
            url: spacer(width, height),
            width: width,
            height: height,
            loaded: false,
        };
        // Fetch the page info
        this.getPageInfo().then(this.didGetPageInfo);

        // Store the current dimension data
        this.preview.data.dimensions = this.dimensions;

        // Generate the snapshot
        return this.getSnapshot().then(this.didTakeSnapshot);

    }

    cancelCreateComment() {
        super.cancelCreateComment();
        this.resetPreview();
        this.showComment = null;
        this.isCommenting = false;

        if (this.canvas) {
            this.resetDrawing();
        }
    }

    didTakeSnapshot = (data) => {
        const snapshot = new this.$$.PPProofCommentSnapshot();
        snapshot.$file = data.image;
        snapshot.url = URL.createObjectURL(snapshot.$file);
        snapshot.name = `Snapshot ${moment().format('YYYY-MM-DD HH.mm.ss')}.png`;
        snapshot.extension = 'png';

        // Swap out the temporary image for the snapshot's image url
        if (this.temporaryImage) {
            this.temporaryImage.url = snapshot.url;
            this.temporaryImage.loaded = true;
        }
        // Store the snapshot data
        this.preview.data.snapshot = snapshot;
        if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
    }

    getPageInfo = (...args) => {
        if (this.isExternal) {
            return window.__pageproof_hooks__.hookable(
                'web-url-proof-page-info',
                () => {}
            )(...args);
        } else {
            return this.origin.getPageInfo().then(({data}) => data);
        }
    }

    didGetPageInfo = (data) => {
        // Store the page info data
        this.preview.data.page = data;
    };

    finishCreateComment(comment) {
        this.showComments = false;
        const {
            snapshot,
            page,
            dimensions,
            device,
            speed,
            orientation
        } = this.preview.data;

        const isCustomDeviceSize = /(\d+)x(\d+|auto\b)/.test(device);
        // Attach the snapshot to the comment
        comment.snapshot = snapshot;

        // Construct & merge the metedata into the comment
        comment.mergeMetadata({
            image: {
                width: dimensions.viewport.width,
                height: dimensions.viewport.height,
            },
            page: page
                ? {
                    title: page.title,
                    path: page.url,
                    top: page.scroll.top,
                    left: page.scroll.left,
                }
                : null,
            device: {
                id: isCustomDeviceSize ? null : device,
                width: dimensions.originalViewport.width,
                height: dimensions.originalViewport.height,
                orientation: orientation,
            },
            viewport: {
                scale: dimensions.scale,
            },
            browser: {
                name: this.$$.browserService.name,
                version: this.$$.browserService.version,
                os: this.$$.browserService.os,
                userAgent: navigator.userAgent,
                devicePixelRatio: window.devicePixelRatio,
                colorDepth: window.screen.colorDepth,
                width: window.innerWidth,
                height: window.innerHeight,
                uptime: performance.now(),
            },
            throttle: {
                id: speed,
            },
        });

        this.temporaryImage = null;
        this.resetDrawing();

        return super.finishCreateComment(comment, false);
    }

    handleFrameCreate(frame) {
        this.frame = frame;
        if (this.isExternal) {
            //
        } else {
            this.origin.command(frame);
        }
    }

    handleError(err) {
        console.log(err);
        console.error('WebProofController handleError: ' + (err.message || err));
        this.$$.$exceptionHandler(err);
        if (this.progress) {
            this.progress[2] = 'proof.error.please-refresh-browser';
        } else {
            const userMessage = generalfunctions_getUserMessageFromError(err);
            if (userMessage) {
                this.fatalErrorMessage = userMessage;
            }
        }
    }

    loadOrigin() {
        const origin = this.origin = this.$$.staticService(this.getUrl());
        this.beforeDestroy(origin.logs(data => {
            switch (data.type) {
                case 'document.load': {
                    this.webProofProps.isLoading = false;
                    this._hasDocumentLoaded = true;
                    this.jumpToContentSizeWhenLoaded();
                    break;
                }
                default: {
                    console.debug('LOG', data);
                    break;
                }
            }
        }));
        this.beforeDestroy(() => origin.destroy());
        origin.throttle().then(([ speed ]) => {
            if (speed) {
                this.webProofProps.speed = speed;
            }
        });
        return this.origin.ready().catch((error) => {
            this.dialog = {
                type: this.$$.PPProofDialogType.THIRD_PARTY_COOKIES,
                location: 'modal',
            };
            throw error;
        });
    }

    getUrlWithoutBasicAuthenticationCredentials() {
        const url = new URL(this.proof.importUrl);
        const newUrl = new URL(`${url.origin}${url.pathname}${url.search}${url.hash}`);
        return newUrl.toString();
    }

    getRestoreUrl(url) {
        if (this.isExternal) {
            // Managed proofs store the url with the static.pageproof.com prefix (so there's no need to do anything differently here - unlike `getUrl`)
            return url;
        } else {
            return this.getUrl() + url;
        }
    }

    hasExtensionCapability(capability) {
        return this.extensionInfo.capabilities.indexOf(capability) !== -1;
    }

    hasBasicAuth() {
        const {username, password} = generalfunctions_parseUrl(this.proof.importUrl);
        return !!username || !!password;
    }

    isInsecureWebsite() {
        const url = new URL(this.proof.importUrl);
        return url.protocol === 'http:';
    }

    shouldProxyWebsite() {
        return (
            (this.isExternal && this.isInsecureWebsite()) ||
            (this.hasBasicAuth() && !this.hasSetupBasicAuthenticationCredentials) ||
            (this.isManaged && !this.hasExtensionCapability('can_allow_embedding_any_web_proof_url_without_proxy'))
        );
    }

    getUrl() {
        const { getWebZipProofUrl, getProxyRedirectUrl } = window.__pageproof_quark__.webProofUrls;

        if (this.isExternal) {
            if (this.shouldProxyWebsite()) {
                return getProxyRedirectUrl(this.proof.importUrl);
            }
            return this.getUrlWithoutBasicAuthenticationCredentials();
        } else {
            return getWebZipProofUrl(this.proofId);
        }
    }

    embedProof() {
        this.webProofProps.url = this.getUrl() + this.entryFile;
    }

    downloadZipFile() {
        return this.$$.temporaryStorageService
            .auto(['#web', this.proof.id], () => {
                // Todo use fileService.downloadFile rather which uses SDK
                const {promise, cancel} = this.$$.fileService.download(
                    this.proof.id,
                    this.proof.file.id,
                    this.proof.file.chunks,
                    'application/zip'
                );
                /*
                err => {
                    this.progress[2] = `You've gone offline, please refresh`;
                }
                */
                promise.then(null, null, progress => { // download progress is a percentage
                    const percentTotal = Math.floor(progress * 0.4) + 20;
                    const percentStep = Math.floor(progress);
                    this.progress = [percentTotal, percentStep, 'proof.web.progress.downloading-file'];
                    if (percentStep === 100) {
                        this.progress = [percentTotal, null, 'proof.web.progress.decrypting'];
                    }
                });
                this.beforeDestroy(cancel); // Make sure we cancel if the user decides to navigate away from the proof page
                return promise;
            });
    }

    loadFile(file) {
        // const file = document.querySelector('#file').files[0];
        return this.$$.zipService.extract(file)
            .then(files => this.$$.$q.all([
                files,
                this.origin.clear(),
            ]), null, progress => {
                const percentTotal = Math.floor(progress * 30) + 60;
                const percentStep = Math.floor(progress * 100);
                this.progress = [percentTotal, percentStep, 'proof.web.progress.unzipping'];
            })
            .then(([files]) => this.origin.upload(files));
    }

    loadSnapshot(comment) {
        this.preview = {
            mode: 'existing',
        };
        const isReActivatedGridLines = this.isActivatedGridLines;
        const task = () => {
            this.$loadingSnapshot = true;
            this.onChangeGridLinesMode(false, true);
            return comment.snapshot.$loading = (
                this.$$.sdk.files
                    .preview(comment.snapshot.id, 1, 'high')
                    .then(image => {
                        comment.snapshot.$file = image;
                        comment.snapshot.url = URL.createObjectURL(image);
                    })
                    .catch(err => {
                        this.restoreMetadata(comment.getMetadata());
                        this.setTemporarySnapshot(comment);
                        // ignore - the error was already picked up by the SDK's angular adapter
                    })
                    .then(() => {
                        this.$loadingSnapshot = false;
                        this.onChangeGridLinesMode(isReActivatedGridLines, true);
                        comment.snapshot.$loading = null;
                    })
            );
        };
        if (comment.snapshot.$loading) {
            // Already loading
            return comment.snapshot.$loading;
        } else {
            if (comment.snapshot.$file) {
                // Already loaded
                return this.$$.$q.when(null);
            } else {
                // Not yet loaded, start
                return task();
            }
        }
    }

    deleteComment(comment) {
        if (comment.$selected) {
            this.resetPreview();
        }
        return super.deleteComment(comment);
    }

    selectionUpdate(previous, comment) {
        this.isInitGridRulers = true;
        this.snapshot = null;
        this.isShowingTemporarySnapshot = false;
        if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
        if (comment && !comment.$selected && Object.keys(comment.metadata).length) {
            if (comment.snapshot) {
                this.loadSnapshot(comment).then(() => {
                    if (comment.$selected) {
                        // Ensure the comment is still selected after the snapshot has loaded...
                        this.snapshot = { comment };
                        
                        this.rulerViewport = {
                            width: this.snapshot.comment.metadata.device.width,
                            height: this.snapshot.comment.metadata.device.height,
                        };
                        if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
                    }
                });
            } else {
                const shouldUnselectComment = false;
                this.restoreMetadata(comment.getMetadata(), shouldUnselectComment);
                this.setTemporarySnapshot(comment);
            }
        }
        return super.selectionUpdate(previous, comment);
    }

    setTemporarySnapshot(comment) {
        this.preview = {
            mode: 'existing',
        };
        this.isShowingTemporarySnapshot = true;
        const { width, height } = comment.metadata.device;
        this.snapshot = {comment: Object.assign({}, comment)};
        this.snapshot.comment.snapshot = {
            url: spacer(width, height),
            width,
            height,
            loaded: true,
        };
    }

    selectComment(comment, scroll = false) {
        super.selectComment(comment, scroll);
        this.flashComment(comment);
    }

    flashComment(comment) {
        if (this.isGeneralComment(comment)) {
            this.flashingGeneralComment = null;
            this.$$.$timeout(() => this.flashingGeneralComment = comment);
        } else if (this.snapshotCanvas) {
            this.snapshotCanvas.flashPinsByIdPrefix(comment.id);
        } else {
            super.flashComment(comment);
        }
    }

    updateCanvasOffset(canvas) {
        const c = (canvas || this.canvas);
        if (c) {
            c.setOffsets({
                left: this.showComments ? -(this.commentPaneWidth / 2) : 0,
            }, false);
        }
    }

    get canvas () {
        return this.temporaryCanvas || this.snapshotCanvas;
    }

    resetPreview(shouldUnselectComment = true) {
        this.preview = { mode: null };
        this.snapshot = null;
        this.isCreatingComment = false;
        this.temporaryImage = null;
        this.isInitGridRulers = true;
        this.isActivatedRuler = false;
        this.temporaryComment = null;
        this.isShowingTemporarySnapshot = false;

        if (this.comments && shouldUnselectComment) {
            this.comments.unselectComment();
        }
        if (this.dimensions) {
            this.rulerViewport = {
                width: this.dimensions.originalViewport.width,
                height: this.dimensions.originalViewport.height,
            };
        }
        if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
    }

    restoreScroll = (page) => (
        window.__pageproof_hooks__.hookable(
            this.isExternal ? 'web-url-proof-restore-scroll' : 'web-zip-proof-restore-scroll',
            () => this.origin.scroll(page.top, page.left)
        )(page)
    )

    restoreMetadata(metadata, shouldUnselectComment) {
        this.resetPreview(shouldUnselectComment);
        this.webProofProps.orientation = metadata.device.orientation;
        this.webProofProps.device = metadata.device.id || `${metadata.device.width}x${metadata.device.height}`;
        this.webProofProps.speed = metadata.throttle.id;
        const restoreUrl = this.getRestoreUrl(metadata.page.path);
        if (restoreUrl) {
            this.webProofProps.url = restoreUrl;
        }
        this.restoreScroll(metadata.page);
    }

    populateCommentedPagesForFilter() {
        this.proof.commentedPages = [];
        if (this.proof.pages[0].comments.length) {
            this.proof.pages[0].comments.forEach(comment => {
                if (comment.metadata && comment.metadata.page) {
                    // this.proof.commentedPages.push({
                    //     id: comment.getCachedFormattedMetadata().path.before,
                    //     label: comment.metadata.page.path,
                    // });
                }
            });
        }
    }

    enableDrawing() {
        if (this.temporaryCanvas && this.temporaryCanvas.image) {
            this.temporaryCanvas.enableDrawing();
        } else {
            setTimeout(() => this.enableDrawing());
        }
    }

    /**
     * Toggles focus mode of web proof page
     */
    toggleFocusMode = () => {
        if (!this.isSmallScreen || this.focusMode) {
            this.focusMode = !this.focusMode;
            this.toggleFocusModeInHeader(this.focusMode);
            this.updateFocusModeinProps(this.focusMode);
        }
    }

    updateFocusModeinProps(focusMode) {
        const focusedElement = document.querySelector('.page__proof__focus');
        if ((focusMode && focusedElement) ||
            (!focusMode && !focusedElement)) {
            this.webProofProps.focusMode = focusMode;
            if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
        } else {
            setTimeout(() => {
                this.updateFocusModeinProps(focusMode);
            }, 200);
        }
    }

    isTransparentSnapshotWhileCreatingComment() {
        return (
            !this.extensionInstalled &&
            this.preview.mode === 'create' &&
            this.temporaryImage
        );
    }
}

app.controller(
    'WebProofController',
    WebProofController
);
