/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class ShortcutsController {
    /**
     * The zoom level shortcut names.
     *
     * @type {String[]}
     */
    zoomShortcuts = ['fit', '100', '200', '400'];

    submitCommentKey = 'shift+enter';

    /**
     *
     */
    constructor (shortcutService, userService, storageService, $scope) {
        this.$$ = { shortcutService };

        const unwatch = storageService('pageproof.').watch(`cache.user-preferences.${userService.getUser().id}`, (_, value) => {
            const keyboardShortcutsPreference = JSON.parse(value).find((preference => preference.Name === 'keyboardShortcuts'))
            const keyboardShortcuts = keyboardShortcutsPreference && keyboardShortcutsPreference.Value;

            if (keyboardShortcuts && keyboardShortcuts.submitComment) {
                this.submitCommentKey = keyboardShortcuts.submitComment.key;
            }
        }, true);

        $scope.$on('$destroy', unwatch);
    }

    /**
     *
     */
    zoomLevel (level) {
        return 'zoom' + ucFirst(level);
    }

    /**
     * @param {String} name
     */
    changeShortcut (name) {
        this.staging = name;
        this.$$.shortcutService.custom(name, () => {
            this.staging = null;
        });
    }
}

function ShortcutsDirective (directiveHelper) {
    return {
        require: ['shortcuts'],
        templateUrl: 'templates/partials/proof/components/shortcuts.html',
        controller: ShortcutsController,
        controllerAs: 'shortcutsCtrl',

        link(scope, element, attr, [shortcutsCtrl]) {
            directiveHelper.callbackBinding(scope, attr, 'whenDismiss', shortcutsCtrl, 'whenDismiss');
            directiveHelper.oneWayBinding(scope, attr, 'isStaticFile', shortcutsCtrl, 'isStaticFile');
            directiveHelper.oneWayBinding(scope, attr, 'isWebFile', shortcutsCtrl, 'isWebFile');
            directiveHelper.oneWayBinding(scope, attr, 'isVideoFile', shortcutsCtrl, 'isVideoFile');
            directiveHelper.oneWayBinding(scope, attr, 'isAudioFile', shortcutsCtrl, 'isAudioFile');
            directiveHelper.oneWayBinding(scope, attr, 'isInCollection', shortcutsCtrl, 'isInCollection');
            directiveHelper.oneWayBinding(scope, attr, 'isGifFile', shortcutsCtrl, 'isGifFile');
        }
    };
}

app
    .directive('shortcuts', ShortcutsDirective);
