/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const MENTION_STATIC_OPTIONS = [
    {
        id: '~everyone',
        display: 'everyone'
    },
    {
        id: '~everyone',
        display: 'all'
    }
];

const MINIMUM_POSITION_DIFFERENCE_FOR_COMPARING = 0.001;
const COMPARE_MODE_CANVAS_Y_OFFSET = 50; // In compare-mode, px-canvas's top is adjusted by 50px.

class BaseProofController extends BaseController {
    /**
     * The registered hooks.
     *
     * @type {Object<String, Function[]>}
     */
    $$hooks = {};

    /**
     * The conditions to meet in order for a comment refresh to happen.
     *
     * @type {Object<String, Boolean>}
     */
    $$commentRefresh = {
        windowFocus: true,
        hasLoaded: false,
    };

    /**
     * Interval of time for the comment refresher.
     *
     * @type {Number}
     */
    $$commentRefreshMs = -1;

    /**
     * The last time the comment refresh.
     *
     * @type {moment}
     */
    $$commentRefreshLast = null;

    /**
     * The current comment refresh promise object.
     *
     * @type {$q}
     */
    $$commentRefreshPromise = null;

    /**
     * Actions that defer the comment refresh.
     *
     * @type {$q[]}
     */
    $$commentRefreshDefer = [];

    /**
     * The filter data of the comment filter.
     *
     * @type {Object}
     */
    $$commentFilter = null;

    /**
     * array for storing unsent comments to local storage
     * @type {Array}
     */
    localStoredComments = [];

    /**
     * The data of the comment-pin tooltip
     *
     * @type {Object}
     */
    commentPinTooltipData = {
        hover: false,
        comment: null,
        box: null,
    }

    /**
     * The data of the comment order
     *
     * @type {Object}
     */
    commentOrder = {
        searchParam: '',
        orderCommentsBy: '',
        isReversedCommentOrder: false,
    }

    maxCanvasPixels = null;

    priorityCommentIds = [];

    decryptCommentPendingPromises = {};

    /**
     * @constructor
     */
    constructor () {
        super();

        this.$$import(this.$$dependencies([
            '$q',
            '$scope',
            '$timeout',
            '$location',
            '$filter',
            '$exceptionHandler',
            'features',
            'eventService',
            'domService',
            'proofInfoService',
            'proofRepositoryService',
            'backgroundService',
            'attachmentService',
            'downloadManager',
            'fileService',
            'proofDialogService',
            'commentFilterService',
            'commentRepositoryService',
            'PPProofComment',
            'PPProofControllerHook',
            'PPProofPermissions',
            'PPProofActions',
            'PPCommentMarkType',
            'PPProofDialogType',
            'PPProofCommentAttachment',
            'UserService',
            'storageService',
            'temporaryStorageService',
            'userRepositoryService',
            'PPProofType',
            'PPProofStatus',
            'userService',
            'SegmentIo',
            'sdk',
            'walkthrough',
            'browserService',
        ]));

        // Rename `UserService` to application (why was it even called that to start with? :P)
        this.$$.application = this.$$.UserService;
        this.$$.api = new API(this.$$.application);
        this.user = this.$$.userService.getUser();

        const browserService = this.$$.browserService;

        if (browserService.is('safari')) {
            this.maxCanvasPixels = 14750000;
        } else if (browserService.is('firefox')) {
            this.maxCanvasPixels = 30000000;
        } else {
            this.maxCanvasPixels = 60000000;
        }

        this.$$initCommentFilter();
        this.$$initCommentOrder();
        this.$$initCommentRefreshInterval();
        this.$$initEventListeners();

        this.initCommentEventListeners();

        this.initHeaderHidingWatcher();

        // feature flags
        this.$$.features.setProof(null).update();
        this.beforeDestroy(() => this.$$.features.setProof(null).update());
    }

    initCommentEventListeners () {
        this.$$.$scope.$on('commentCreatedOffline', (event, commentData) => {
            if (this.proof) {
                this.findAndAttachCommentId(commentData);
            }
        });

        this.$$.$scope.$on('attachmentAddedOffline', (event, commentData) => {
            if (this.proof) {
                this.bindAttachmentToComment(commentData);
            }
        });

        this.$$.$scope.$on('snapshotAddedOffline', (event, commentData) => {
            if (this.proof) {
                this.bindSnapshotToComment(commentData);
            }
        });

        this.$$.$scope.$on('commentUserFilterSet', (event, user) => {
            if (this.proof) {
                this.$setFilter(user, this.proof);
                if (this.canShowCommentPane()) {
                    this.showComments = true;
                }
            }
        });
    }

    initHeaderHidingWatcher () {
        // force show the header when loading the proof page
        this.$$.$scope.headerControls.show = true;
        this.$$.$scope.headerControls.lock = true;

        this.setHeaderHidingTimer();

        // Unlock & show the header when navigating away
        this.beforeDestroy(() => {
            this.$$.$scope.headerControls.show = true;
            this.$$.$scope.headerControls.lock = true;
        });
    }

    canShowCommentPane() {
        return false;
    }

    setHeaderHidingTimer () {
        if (this.proofHasLoaded()) {
            if (this.setHeader) {
                this.setHeader(); // Sets header so that if proof is approved, confetti can be shown
                // after 5s, unlock the header, unless printing
                const t = this.$$.$timeout(() => {
                    if(!this.isPrinting) {
                        this.$$.$scope.headerControls.lock = false;
                    }
                }, 5000);
                this.beforeDestroy(() => {
                    this.$$.$timeout.cancel(t);
                });
            }
        } else {
            const timer = this.$$.$timeout(() => {
                this.setHeaderHidingTimer();
            }, 1000);
            this.beforeDestroy(() => {
                this.$$.$timeout.cancel(timer);
            });
        }
    }

    setProofLoaded (isLoaded) {
        this.proofLoaded = isLoaded;
    }

    proofHasLoaded () {
        // hi or low resolution proof has been loaded / if any pop up message, it has been clicked to go away / if intro video appeared, has been clicked to go away
        return this.proofLoaded && !this.dialog && !this.gettingStarted;
    }

    /**
     * Registers a proof hook.
     *
     * Note: You do not need to de-register the hook when the `$scope` is destroyed as
     * the callback is automatically cleaned up - however you can still un-register it ahead
     * of the `$destroy` event.
     *
     * @param {String} hook
     * @param {Function} callback
     */
    registerHook (hook, callback) {
        // Lazy initialise the array for a specific hook
        let hooks = (this.$$hooks[hook] = this.$$hooks[hook] || []),
            isRegistered = true;

        function unregisterHook () {
            if (isRegistered) {
                hooks.splice(hooks.indexOf(callback), 1);
                isRegistered = false;
            }
        }

        this.beforeDestroy(unregisterHook);
        hooks.push(callback); // Add the callback to the hooks array

        return unregisterHook;
    }

    /**
     * Registers a proof hook which only invokes the callback the first time.
     *
     * @param {string} hook
     * @param {function} callback
     * @returns {function}
     */
    registerHookSingle(hook, callback) {
        const unregisterHook = this.registerHook(hook, (...args) => {
            unregisterHook();
            callback(...args);
        });
        return unregisterHook;
    }

    /**
     * Invokes a hook.
     *
     * @param {String} hook
     * @param {...*} [args]
     * @returns {$q}
     */
    invokeHook (hook, ...args) {
        let promise;

        if (hook in this.$$hooks && this.$$hooks[hook].length) {
            promise = this.$$.$q.all(this.$$hooks[hook].map((callback) => {
                // Allow the result of a hook to be another promise (which defers the resolution)
                // of the hook (allowing for async tasks to be handled.
                return this.$$.$q.when(callback(...args));
            }));
        } else {
            // If there are no hooks in the hooks array, return a self-resolving promise
            promise = this.$$.$q((resolve) => resolve());
        }

        return promise;
    }

    /**
     * Initialises the comment filter code.
     *
     */
    $$initCommentFilter () {
        this.bindRouteParams([{
            key: 'filter',
            read: (value) => {
                let filter = this.$$.commentFilterService.parse(value);
                return this.$$.commentFilterService.filter(filter);
            },
            write: (filter) => this.$$.commentFilterService.stringify(filter)
        }]);
    }

    /**
     * Initialises the comment order code.
     *
     */
    $$initCommentOrder () {
        this.bindRouteParams([{
            key: 'commentOrder',
            searchParam: 'order',
            read: (value) => {
                if (value) {
                    const [orderBy, isReversedRaw] = value.split(':');
                    const isReversed = isReversedRaw === 'reversed';
                    return {
                        searchParam: orderBy,
                        orderCommentsBy: '',
                        isReversedCommentOrder: isReversed,
                    };
                }
                return this.commentOrder;
            },
            write: (commentOrder) => {
                if (commentOrder.searchParam) {
                    return `${commentOrder.searchParam}${commentOrder.isReversedCommentOrder ? ':reversed' : ''}`;
                }
                return '';
            },
        }]);
    }

    /**
     * Start the comment refresh interval.
     *
     * Note: Creates a read-only interval ($timeout) which triggers the `WHEN_COMMENT_REFRESH` event,
     * at which point, the implementing controller has the ability to fetch the latest comments.
     *
     * TODO Support resolving the hook with a promise which returns an array of encrypted comment objects for decryption.
     *
     * Note: This interval will always run for the default amount of time first. If the `$$commentRefreshMs` is
     * overridden, the interval will not pick up the overridden number until it's finished it's first round.
     *
     * @see {BaseProofController.$$commentRefresh}
     */
    $$initCommentRefreshInterval () {
        let deferStartCommentRefresh;

        // Load the comment refresh ms from the app config (as a default)
        this.$$commentRefreshMs = this.$$.application.config.CommentRefreshInterval;

        let cancelCommentRefresh = () => {
            // Clean out the old `$$commentRefresh` interval promise
            this.$$.$timeout.cancel(this.$$commentRefreshTimeout);
            this.$$commentRefreshTimeout = null;

            // Cancel the timeout which initially kick-starts the comment refresh
            // This can happen if the page is immediately redirected after the proof page initially
            // loads (edge case - but prevents the controller staying alive after it's meant to.
            this.$$.$timeout.cancel(deferStartCommentRefresh);
        };

        let scheduleCommentRefresh = () => {
            if (this.$$commentRefreshTimeout) {
                cancelCommentRefresh();
            }

            if (this.$$commentRefreshPromise) {
                this.$$commentRefreshPromise = null;
            }

            if (this.$$destroyed) {
                return; // Don't bother registering another refresh
            }

            this.$$commentRefreshTimeout = this.$$.$timeout(() => {
                if (this.$canRefreshComments()) {
                    // Defer the comment refresh until all actions have complete. Any actions like adding comments can take
                    // priority over the comment refresh's execution. Wait.
                    this.$deferCommentRefresh()
                        .then((deferred) => {
                            if (deferred) console.debug('Comment refresh was deferred...');

                            // Invoke the hook, and pass through the time the comment refresh last invoked
                            // Then schedule another comment refresh once the previous hook has complete (deferred by promises/requests)
                            this.$$commentRefreshPromise = this.invokeHook(
                                this.$$.PPProofControllerHook.WHEN_COMMENT_REFRESH, this.$$commentRefreshLast
                            );

                            // Set the last comment refresh time (to pass to the next refresh)
                            this.$$commentRefreshLast = moment();

                            // Schedule the next comment refresh after this promise is resolved
                            return this.$$commentRefreshPromise;
                        })
                        .finally(scheduleCommentRefresh);
                } else {
                    console.log('Preventing comment refresh:',
                        // Print out the failing conditions (from the $$commentRefresh object)
                        Object.getOwnPropertyNames(this.$$commentRefresh).filter((name) => ! this.$$commentRefresh[name]).join(', '));

                    // Re-schedule the comment refresh
                    scheduleCommentRefresh();
                }
            }, this.$$commentRefreshMs);
        };

        // Start off the loop (allow subclasses to override the comment refresh time)
        deferStartCommentRefresh = this.$$.$timeout(scheduleCommentRefresh);

        // Cancel the comment refresh when the user navigates away
        this.beforeDestroy(cancelCommentRefresh);
    }

    /**
     * Initialise any event listeners which are required for the base controller.
     *
     * Note: This method registers an event listener on the window for when the user enters/exits the page (by focus);
     * where by losing focus causes the comment refresh to defer.
     */
    $$initEventListeners () {
        // Toggle the `windowFocus` state in the comment refresh condition map when the user gains/loses focus
        // of the current window - `windowFocus` is enabled by default.
        this.beforeDestroy(this.$$.eventService.on(window, 'focus blur', ({ type }) => {
            this.$$commentRefresh['windowFocus'] = type === 'focus';
        }));

        this.beforeDestroy(this.$$.$scope.$on('onCloseCollectionPane', (event) => {
            this.onCloseCollectionManage();
        }));

        this.$$.storageService('pageproof.').watch(`cache.user-preferences.${this.user.id}`, (key, value) => {
            const userPreferences = JSON.parse(value);
            this.userPreferencePinColorMappingObject = window.__pageproof_quark__.colorPreferences.getPreferencePinColorMappingObject(userPreferences);
        }, true);
    }

    /**
     * Whether the comment refresh is allowed to happen.
     *
     * @returns {Boolean}
     */
    $canRefreshComments () {
        // Make sure every comment refresh condition is met
        // Supports getters for the logic for this (to make it easier for state)
        return Object.getOwnPropertyNames(this.$$commentRefresh).every((condition) => {
            return !! this.$$commentRefresh[condition];
        });
    }

    headerHeight () {
        return this.$$.$scope.appCtrl.mobile || !this.$$.$scope.headerControls.show
            ? 0
            : angular.element('header')[0].offsetHeight;
    }

    commentPaneHeaderHeight () {
        const commentPaneFlyoutContentHeight = angular.element('.app__flyout__content')[0].offsetHeight;
        const commentPanePageHeading = angular.element('.app__comments-page__heading')[0];
        const commentPanePageHeadingMargin = 15;
        return commentPaneFlyoutContentHeight + (commentPanePageHeading
            ? commentPanePageHeading.offsetHeight + commentPanePageHeadingMargin
            : 0);
    }

    /**
     * Sets the comment filter (based off a filter string).
     *
     * @param {String} filter
     */
    $setFilter(filter, proof) {
        let obj = this.$$.commentFilterService.parse(filter);
        this.filter = this.$$.commentFilterService.filter(obj);
        if (proof && proof.pages) {
            proof.filter = this.filter;
            proof.pages.forEach((page) => {
                page.filteredComments = page.comments.filter(this.filter.fn);
            });
        } else if (proof.leftImageData && proof.rightImageData) {
            // compare proof we have two left and right proof pages
            proof.leftImageData.filteredComments = proof.leftImageData.comments.filter(this.filter.fn);
            proof.rightImageData.filteredComments = proof.rightImageData.comments.filter(this.filter.fn);
        }
        this.loadPermissionsActionButton();
    }

    /**
     * Whether the page has comments visible.
     *
     * @param {PPProofPage} page
     * @returns {Boolean}
     */
    $isPageFiltered (page) {
        if (this.filter) {
            return page.comments.some(this.filter.fn);
        } else {
            return page.comments.length > 0; // By default, if there isn't a filter (before page load), show all pages
        }
    }

    /**
     * Decrypts a single comment object.
     *
     * @param {PageProofEncryption} encryptionData
     * @param {PPProofComment} comment
     */
    $decryptComment (encryptionData, comment) {
        // Sets the `$encryptedComment` value so other code can register the comment as decrypted
        // Even though at this point the comment is not decrypted - we still set it, as we don't want
        // to register another worker for decrypting the comment for a second time.
        comment.$encryptedComment = comment.encryptedComment;

        // Get the comment's identifier (based on the encrypted text & the comment id)
        let identifier = this.$$.commentRepositoryService.getCommentIdentifier(comment);

        return (
            this.$$
                .commentRepositoryService
                .get(identifier)
                .then((decryptedComment) => {
                    // Set the decrypted comment and the user-editable comment field
                    comment.decryptedComment = comment.comment = decryptedComment;
                })
        );
    }

    /**
     * Decrypts a/or many comment objects.
     *
     * Returns a promise for when all the provided comments have decrypted.
     *
     * @param {PageProofEncryption} encryptionData
     * @param {...PPProofComment} comments
     * @returns {$q}
     */
    $decryptComments (encryptionData, ...comments) {
        return this.$$.$q.all(comments.map((comment) => {
            return this.$decryptComment(encryptionData, comment);
        })).then(() => {
            if (this._renderCommentsPane) {
                this._renderCommentsPane();
            }
            return this.invokeHook(this.$$.PPProofControllerHook.DID_DECRYPT_COMMENTS, {
                comments,
            });
        });
    }

    $decryptCommentsByPriority(encryptionData, comments, concurrency = 10) {
        return new Promise((resolve) => {
            if (comments.length === 0) {
                resolve();
                return;
            }

            let pending = 0;
            const pendingIds = [];

            const commentIds = [];
            const commentsById = {};
            comments.forEach((comment) => {
                commentsById[comment.id] = comment;
                commentIds.push(comment.id);

                comment.$encryptedComment = comment.encryptedComment;
            });

            const getNextComment = () => {
                const comment = (
                    commentsById[this.priorityCommentIds.find(commentId => commentId in commentsById)] ||
                    commentsById[commentIds[0]]
                );

                if (comment) {
                    delete commentsById[comment.id];
                    commentIds.splice(commentIds.indexOf(comment.id), 1);
                }

                return comment;
            };

            const next = () => {
                const comment = getNextComment();

                if (!comment) {
                    if (pending === 0) {
                        resolve();
                    }
                    return;
                }

                pending++;
                pendingIds.push(comment.id);
                this.$decryptComment(encryptionData, comment)
                    .catch((err) => {
                        this.$$.$exceptionHandler(err);
                    })
                    .then(() => {
                        pending--;
                        pendingIds.splice(pendingIds.indexOf(comment.id), 1);
                        next();
                    });
            };

            const startTrickle = (resolve) => {
                let count = Math.min(concurrency, commentIds.length);
                if (count === 0) {
                    resolve();
                    return;
                }
                while (count--) {
                    next();
                }
            };

            this.$$.commentRepositoryService.localBulk(comments).then((comments) => {
                Object.entries(comments).forEach(([commentId, decryptedComment]) => {
                    const comment = commentsById[commentId];
                    comment.decryptedComment = comment.comment = decryptedComment;
                    delete commentsById[commentId];
                    commentIds.splice(commentIds.indexOf(commentId), 1);
                });
                if (this._renderCommentsPane) {
                    this._renderCommentsPane();
                }
                startTrickle(resolve);
            }, () => {
                startTrickle(resolve);
            });
        }).then(() => {
            if (this._renderCommentsPane) {
                this._renderCommentsPane();
            }
            return this.invokeHook(this.$$.PPProofControllerHook.DID_DECRYPT_COMMENTS, {
                comments,
            });
        });
    }

    /**
     * Encrypts a single comment object.
     *
     * @param {PageProofEncryption} encryptionData
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $encryptComment (encryptionData, comment) {
        let done = this.$$.backgroundService.create('Encrypt comment');

        return (
            this.$$.sdk.crypto().encryptComment(comment.comment, this.$$.sdk.keyPairs.getPublicKeyPEMs())
                .then((data) => {
                    comment.envelope = data.envelope;
                    comment.encryptedComment = data.comment$;
                })
                .finally(done)
        );
    }

    bindAttachmentToComment(comment) {
        let commentObj = this.comments.getCommentById(comment.id, true);
        // todo look here for filtering old / new attachments if needed
        commentObj.attachments = comment.attachments;
    }

    bindSnapshotToComment(comment) {
        let commentObj = this.comments.getCommentById(comment.id);
        commentObj.snapshot = comment.snapshot;
    }

    findAndAttachCommentId(commentData) {
        for (let index = 0; index < this.proof.pages.length; index++) {
            if (index + 1 === commentData.pageNumber) {
                let page = this.proof.pages[index];
                page.findCommentByCreationToken(commentData.creationToken).then((comment) => {
                    if (comment) {
                        comment.id = commentData.id;
                        comment.isSaved = true;
                    }
                });
                break;
            }
        }
    }

    /**
     * Encrypts a comment and sends a request to create a comment.
     *
     * @param {PageProofEncryption} encryptionData
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $encryptAndCreateComment (encryptionData, comment) {
        return (
            this.$encryptComment(encryptionData, comment)
                .then(() => this.$$.localCommentService.$createComment(comment))
        );
    }

    /**
     * Encrypts a comment and sends a request to update a comment.
     *
     * @param {PageProofEncryption} encryptionData
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $encryptAndUpdateComment (encryptionData, comment) {
        return (
            this.$encryptComment(encryptionData, comment)
                .then(() => this.$$.localCommentService.$updateComment(comment))
        );
    }

    /**
     * Delete a comment.
     *
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $deleteComment (comment) {
        let done = this.$$.backgroundService.create('Delete comment');

        return (
            this.$$
                .backendService
                .fetch('proof.comment.delete', {
                    commentId: comment.id
                })
                .promise()
                .finally(done)
        );
    }

    /**
     * Delete an attachment.
     *
     * @param {PPProofCommentAttachment, PPProofComment} attachment
     * @returns {$q}
     */
    $deleteAttachment (attachment, comment) {
        let done = this.$$.backgroundService.create('Delete attachment');

        return (
            this.$$
                .backendService
                .fetch('proof.attachment.delete', {
                    attachment: attachment.id,
                    commentId: comment.id
                })
                .promise()
                .finally(done)
        );
    }

    /**
     * Agree/un-agree to a comment.
     *
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $agreeComment (comment/*, user */) {
        let done = this.$$.backgroundService.create('Agree comment');

        return (
            this.$$
                .backendService
                .fetch('proof.comment.agree', {
                    commentId: comment.id
                })
                .data()
                .then((commentData) => {
                    // Update the comment object with the latest data (since it passes back end entire comment
                    // object again - we don't need to re-fetch anything from the server).
                    comment.updateFromProofCommentData(commentData);
                })
                .finally(done)
        );
    }

    /**
     * Update the comments xy data.
     *
     * @param {PPProofComment} comment
     */
    $updateCommentPin (comment) {
        let done = this.$$.backgroundService.create('Update comment pin');

        return (
            this.$$
                .backendService
                .fetch('proof.comment.pin.update', {
                    commentId: comment.id,
                    xy: __pageproof_quark__.sdk.util.pins.serialize(comment.pins)
                })
                .data()
                .then((commentData) => {
                    comment.updateFromProofCommentData(commentData);
                })
                .finally(done)
        );
    }

    toggleCommentPinTooltip(hover, comment, box) {
        this.commentPinTooltipData = { hover, comment, box };
        this.$$.$scope.$apply();
    }

    /**
     * Locks the proof.
     */
    $lockProof = () => {
        return this.$$.sdk.proofs.lock(this.proofId)
            .then(() => {
                return this.refreshProof();
            });
    }

    /**
     * Unlocks the proof
     */
    $unlockProof = () => {
        return this.$$.sdk.proofs.unlock(this.proofId)
            .then(() => this.refreshProof());
    }

    /**
     * Sends an email to unlock proof whoever can do (owners/ gatekeeper/ approver)
     */
    $requestUnlockProof = () => {
        return this.$$.sdk.proofs.requestUnlock(this.proofId)
            .then(() => {
                return this.refreshProof();
            });
    }

    /**
     * Loads a proof from the server (populating as much of the proof as possible).
     *
     * @param {String} proofId
     * @param {Object} [options]
     * @returns {$q<PPProof>}
     */
    $loadProof (proofId, options = {}) {
        return (
            this.$$
                .proofRepositoryService
                .getById(proofId, angular.extend({
                    referrer: this.$$.features.referrer,
                    proof: true,
                    status: true,
                    versions: true,
                    workflow: true,
                    finalApprover: true,
                    comments: false,
                    coOwners: true,
                    owner: true,
                    editor: true,
                    recipient: true,
                    workflowManagers: true,
                    video: true,
                }, options))
                .then(proof => {
                    this.$$.features.setProof(proof.featureFlags).update();
                    return proof;
                })
        );
    }

    $loadComments(proof) {
        return (
            this.$$
                .proofRepositoryService
                .$getCommentsById(proof.id, proof)
        );
    }

    /**
     * Updates the proof object from the server.
     *
     * @param {PPProof} proof
     * @param {Object} options
     * @returns {$q<PPProof>}
     */
    $updateProof (proof, options = {}) {
        return (
            this.$$
                .proofRepositoryService
                .$populateProof(proof, angular.extend({
                    proof: true,
                    status: true,
                    workflow: true,
                    coOwners: true,
                    editor: true
                }, options))
        );
    }

    /**
     * Loads an array of comments since a given time.
     *
     * @param {String} proofId
     * @param {moment} since
     * @returns {$q<Object[]>}
     */
    $loadCommentsSince (proofId, since) {
        return (
            this.$$
                .backendService
                .fetch('proof.comments.since', {
                    proofId,
                    timeStamp: since.utc().format('YYYY-MM-DDTHH:mm:ss')
                })
                .data()
        );
    }

    /**
     * Uploads and assign an attachment to a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofCommentAttachment} attachment
     * @returns {$q}
     */
    $uploadAndAssignAttachments (comment, attachments) {
        return this.$$.localCommentService.$uploadAndAssignAttachments(comment, attachments);
    }

    /**
     * Uploads and assign a snapshot to a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofCommentSnapshot} attachment
     * @returns {$q}
     */
    $uploadAndAssignSnapshot (comment, snapshot) {
        return this.$$.localCommentService.$uploadAndAssignSnapshot(comment, snapshot);
    }

    $getfilteredComments(proof, pageNumber = false) {
        let filteredComments = [];
        if (proof && proof.pages) {
            if (pageNumber) {
                // On compare screen we need only particular page comments to be filter
                filteredComments = filteredComments.concat(this.$$.$filter('filter')(proof.pages[pageNumber - 1].comments, this.filter.fn));
            } else {
                proof.pages.forEach(page => {
                    filteredComments = filteredComments.concat(this.$$.$filter('filter')(page.comments, this.filter.fn));
                });
            }
        }
        return {
            filteredComments,
            count: filteredComments.length,
        };
    }

    /**
     * Marks all the comments on a proof as a specific mark type.
     *
     * @param {PPProof} proof
     * @param {String} type
     * @param {Boolean} state
     * @returns {$q}
     */
    $markAllComments (proof, type, state, pageNumber) {
        const user = this.$$.userService.getUser();
        const { canMarkAsChanged, canMarkAsDone } = new this.$$.PPProofPermissions(user, proof).fetchUserPermissions().proofer.commentLevel;
        const done = this.$$.backgroundService.create('Mark all comments');
        const { TODO, DONE, UNMARKED } = this.$$.PPCommentMarkType;
        const getCurrentType = (isTodo, isDone) => {
            if (isDone) {
                return DONE;
            } else if (isTodo) {
                return TODO;
            } else {
                return UNMARKED;
            }
        };
        const { filteredComments } = this.$getfilteredComments(proof, pageNumber);
        const affectedCommentIds = filteredComments.filter((comment) => {
            if (comment.isPrivate) {
                return;
            }
            
            const currentMarkType = getCurrentType(comment.isTodo, comment.isDone);
            if (type === DONE) {
                return state ? currentMarkType !== type : currentMarkType === type;
            } else if (type === TODO) {
                return canMarkAsChanged && !canMarkAsDone && currentMarkType === DONE // gatekeeper & approver can not change 'Done'
                    ? false
                    : state ? currentMarkType !== type : currentMarkType === type;
            } else {
                return type === UNMARKED;
            }
        }).map((comment) => {
            comment.updateFromCommentMarkType(comment, type, state);
            return comment.id;
        });
        return (state
            ? this.$$.sdk.comments.markAll(proof.id, affectedCommentIds, type)
            : this.$$.sdk.comments.unmarkAll(proof.id, affectedCommentIds, type))
            .then((commentsData) => {
                const commentsObject = commentsData.reduce((a, v) => Object.assign(a, { [v.commentId]: v }), {});
                // Keep the object up to date
                filteredComments.forEach(comment => {
                    if (commentsObject[comment.id]) {
                        comment.updateFromCommentMarkType(comment, commentsObject[comment.id].commentState);
                    }
                });
            })
            .finally(done);
    }

    commentPinToCanvasXY(pin) {
        const xy = pin.__canvasXY = (pin.__canvasXY || {});
        xy.x1 = pin.x;
        xy.y1 = pin.y;
        xy.x2 = pin.x2;
        xy.y2 = pin.y2;
        return xy;
    }

    canvasXYToCommentPin(xy) {
        return {
            x: xy.x1,
            y: xy.y1,
            x2: xy.x2,
            y2: xy.y2,
            fill: xy.boxFillType ? {
                type: xy.boxFillType,
                image: xy.boxFillImage,
            } : undefined,
        };
    }

    /**
     * Creates a comment on a specific proof, for a specific user.
     *
     * @param {PPProof} proof
     * @param {PPUser} user
     * @param {String} [text]
     * @param {canvas.XY} [data]
     * @returns {PPProofComment}
     */
    $startComment (proof, user, text, data) {
        let comment = new this.$$.PPProofComment();

        comment.proofId = proof.id;
        comment.ownerId = user.id;
        comment.ownerEmail = user.email;

        if (angular.isString(text)) {
            comment.comment = text;
        }

        if (angular.isObject(data)) {
            comment.pins = [this.canvasXYToCommentPin(data)];
        }

        return comment;
    }

    /**
     * Grab the permissions (and action/button state) for a specific user on a proof.
     *
     * @param {PPProof} proof
     * @param {PPUser} user
     * @returns {$q<Object>}
     */
    $loadPermissionsActionButton (proof, user) {
        return this.$$.$q((resolve) => {
            let permissions = new this.$$.PPProofPermissions(user, proof),
                actions = new this.$$.PPProofActions(permissions);

            console.log('perms', permissions.fetchUserPermissions());

            resolve({
                // Pass back the original objects we've constructed
                $permissions: permissions,
                $actions: actions,

                // Pass back the most useful information
                permissions: permissions.fetchUserPermissions(),
                action: actions.getActionType(),
                button: actions.getButtonState()
            });
        });
    }

    /**
     * Opens the proof info pane for a proof.
     *
     * @param {String} proofId
     */
    $openProofInfo (proofId) {
        this.$$.proofInfoService.open(proofId, 'proof');
    }

    /**
     * Toggles the proof info pane for a proof.
     *
     * @param {String} proofId
     */
    $toggleProofInfo (proofId) {
        this.$$.proofInfoService.toggle(proofId, 'proof');
    }

    /**
     * get the current comment status mark (todo or done or unmarked).
     *
     * @param {PPProofComment} comment
     * @returns {String}
     */
    $getCommentStatusMark(comment) {
        const { TODO, DONE, UNMARKED } = this.$$.PPCommentMarkType;
        const { isTodo, isDone } = comment;
        return isDone
            ? DONE
            : isTodo
                ? TODO
                : UNMARKED;
    }

    $getCanCreatePrivateComment(proof, permissions, parentComment) {
        if (!proof.canHavePrivateComments || this.user.teamId !== proof.teamId) {
            return false;
        }

        return parentComment ? permissions.proofer.commentLevel.canReply : permissions.proofer.commentLevel.canCreate;
    }

    /**
     * get the next status marks of the comment what can be marked as Todo or Done or unMarked.
     *
     * @param {Object} permissions
     * @param {PPProofComment} comment
     * @returns {Object<String, Boolean>}
     */
    $getNextCommentStatusMarkOptions(permissions, comment) {
        if (comment.isPrivate) {
            return [];
        }
        
        const { TODO, DONE, UNMARKED } = this.$$.PPCommentMarkType;
        const { canMarkAsChanged, canMarkAsDone } = permissions.proofer.commentLevel;
        const { isTodo, isDone } = comment;
        let nextMarks = [];
        if (canMarkAsChanged && canMarkAsDone) { // Owner or Co-Owner
            if (!isTodo && !isDone) { // current comment status is unmarked
                nextMarks = [
                  { type: TODO, state: true },
                  { type: DONE, state: true },
                ];
            } else { // current comment status is To-do or Done
                nextMarks = [
                  { type: UNMARKED, state: true },
                  { type: isDone ? TODO : DONE, state: true },
                ];
            }
        } else if (canMarkAsChanged || canMarkAsDone) {
            if (canMarkAsChanged && !isDone) { // gatekeeper & approver can not change 'Done'
                nextMarks = [{ type: isTodo ? UNMARKED : TODO, state: true }];
            } else if (canMarkAsDone) {
                nextMarks = [{ type: DONE, state: !isDone }];
            }
        }
        return nextMarks
    }

    /**
     *  get next comment status mark type & state
     *
     * @param {Object} permissions
     * @param {PPProofComment} comment
     * @returns {Object<String, Boolean>}
     */
    $getNextCommentStatusType(permissions, comment) {
        const markTypes = Object.values(this.$$.PPCommentMarkType); // ["todo", "done", "unmarked"]
        const markOptions = this.$getNextCommentStatusMarkOptions(permissions, comment);
        const markOptionTypes = markOptions.reduce((markTypes, markOption) => [ ...markTypes, markOption.type ], []);
        const currentStatusMarkTypeIndex = markTypes.indexOf(this.$getCommentStatusMark(comment));
        let nextMarkTypeIndex = currentStatusMarkTypeIndex + 1;
        let markOptionIndex = 0;
        let returnObject = null;

        if (markOptions.length > 0) {
            if (markOptions.length > 1) {    
                while (true) {
                    if (nextMarkTypeIndex === markTypes.length) {
                        nextMarkTypeIndex = 0;
                    }
                    markOptionIndex = markOptionTypes.indexOf(markTypes[nextMarkTypeIndex]);
                    if (markOptionIndex !== -1) {
                        break;
                    }
                    nextMarkTypeIndex += 1;
                }
            }
            returnObject = {
                type: markOptions[markOptionIndex].type,
                state: markOptions[markOptionIndex].state
            };
        }
        return returnObject;
    }

    /**
     * Send a request to mark a comment as to-do/done.
     *
     * @param {PPProofComment} comment
     * @param {PPCommentMarkType} type
     * @param {Boolean} state
     */
    $markComment(comment, type, state) {
        const done = this.$$.backgroundService.create('Mark comment');
        comment.updateFromCommentMarkType(comment, type, state);
        return ((state
                    ? this.$$.sdk.comments.mark(comment.id, type)
                    : this.$$.sdk.comments.unmark(comment.id, type)
                ).then((commentMarkType) => {
                    // Keep the object up to date
                    comment.updateFromCommentMarkType(comment, commentMarkType);
                })
                .finally(done)
        );
    }

    /**
     * Handles downloading a file.
     *
     * @param {PPFile|PPProofCommentAttachment} file
     * @returns {$q<Blob>}
     */
    $downloadFile (file) {
        if (file.$file) {
            this.$$.downloadManager.downloadFile(file.$file, file.name);
            return this.$$.$q.when(file.$file);
        } else {
            const { promise } = this.$$.fileService.downloadFile(file.id, file.name);
            promise.then(blob => file.$file = blob);
            return promise;
        }
    }

    /**
     * Cancels any pending downloads.
     */
    $cancelDownload () {
        this.$$.downloadManager.cancelDownload();
    }

    /**
     * Handles downloading an attachment.
     *
     * @param {PPProofCommentAttachment} attachment
     * @returns {$q<Blob>}
     */
    $downloadAttachment (attachment) {
        return this.$downloadFile(attachment);
    }

    /**
     * Redirects the user to an error page (without the user's knowledge they shifted
     * to an entirely different page). This allows the user to keep refreshing/bookmark
     * the page for later.
     *
     * Note: This method does flicker the url between the provided `url` and the current
     * page url.
     *
     * @param {String} url
     */
    $redirectErrorPage (url) {
        let currentUrl = this.$$.$location.url();
        this.$$.$location.url(url).replace();
        this.$$.$timeout(() => {
            this.$$.$location.url(currentUrl).replace().ignore();
        });
    }

    /**
     * Handles redirect logic for proof errors.
     *
     * Returns whether the method handled the error (whether the user was redirected).
     *
     * @param {String} err
     * @returns {Boolean}
     */
    $handleLoadProofError (err) {
        let redirectUrl;
        const isBrief = window.isBriefId(this.proofId);

        switch (err) {
            case this.$$.api.error.ERROR_NOT_FOUND:
                redirectUrl = isBrief ? 'access/10' : 'proof-not-found';
                break;
            case this.$$.api.error.ERROR_NO_ACCESS:
                redirectUrl = isBrief ? 'access/9' : 'access/1';
                break;
            case this.$$.api.error.ERROR_NO_ACCESS_ADMIN:
                redirectUrl = isBrief ? 'access/9' : 'access/15';
                break;
            case this.$$.api.error.ERROR_RESTRICTED:
                redirectUrl = 'access/2';
                break;
        }

        if (redirectUrl) {
            this.$redirectErrorPage(redirectUrl);
            return true;
        }

        return false;
    }

    /**
     * Defer until the comment refresh is allowed to happen.
     *
     * @returns {$q}
     */
    $deferCommentRefresh () {
        if (this.$$commentRefreshDefer.length) {
            return this.$$.$q.all(this.$$commentRefreshDefer)
                .then(() => this.$deferCommentRefresh())
                .then(() => true);
        } else {
            return this.$$.$q.when(false);
        }
    }

    /**
     * Stall the comment refresh until the promise is resolved.
     *
     * Invokes the `ui` argument twice (to keep the ui up with the latest data) - in case the comment refresh
     * modifies the data which the action changes. And give the feel that the action was immediate. The first time
     * the function is invoked - it's passed `false` and the second time: `true`.
     *
     * @param {Function} action
     * @param {Function} [ui]
     * @returns {$q}
     */
    $stallCommentRefresh (action, ui) {
        const deferred = this.$$.$q.defer();
        this.$$commentRefreshDefer.push(deferred.promise);

        let handle = () => {
            const promise = this.$$.$q.when(action());
            promise.finally(() => deferred.resolve());

            return promise.then(() => {
                let index = this.$$commentRefreshDefer.indexOf(promise);
                this.$$commentRefreshDefer.splice(index, 1);

                if (ui) ui(true);
            });
        };

        if (ui) ui(false);

        if (this.$$commentRefreshPromise) {
            return this.$$commentRefreshPromise.then(handle);
        } else {
            return this.$$.$q.when(handle());
        }
    }

    /**
     *
     * @param {PPProof} proof
     * @param {PPUser} user
     * @returns {$q}
     */
    $whenProofDataLoaded(proof, user) {
        return this.$loadPermissionsActionButton(proof, user)
            .then(({ permissions }) => this.$$.proofDialogService.getDialogType(permissions))
            .then(type => type ? this.dialog = type : null);
    }

    getAllUsersOnProof(proof) {
        let allUsers;
        if (proof.workflow) {
            allUsers = [
                proof.ownerUser,
                ...proof.editorUsers,
                ...proof.coOwners,
                ...proof.workflow.prooferUsers,
            ];
        } else {
            allUsers = [
                proof.ownerUser,
                ...proof.coOwners,
                ...proof.editorUsers,
                proof.recipientUser,
            ];
        }
        return allUsers;
    }

    // Pass page number if commented by users suppose to calculate only for single page
    // On compare screen we do calculate for single page
    populateMentionData(proof, user, pageNumber) { 
        const allUsers = this.getAllUsersOnProof(proof);
        console.debug("ALL USERS for mention dropdown", allUsers);
        this.$populateMentionData(proof, allUsers, user);
        this.populateCommentByUsers(proof, pageNumber);
    }

    $getMentionableUsers(proof, allUsers) {
        if (proof.commentVisibility == null) {
            return Promise.resolve(allUsers);
        } else {
            return this.$$.sdk.graphql(
                `
                    query ppxapp_getProofMentionableUsers($id: ID!) {
                        proof (id: $id) {
                            mentionableUsers {
                                id
                                name
                                email
                            }
                        }
                    }
                `,
                { id: proof.id },
                { throwOnError: false })
            .then(result => result.data.proof.mentionableUsers || [])
        }
    };

    /**
     *
     * @param {PPUsers} allUsers
     * @param {PPUser} loggedInUser
     */
    $populateMentionData(proof, allUsers, loggedInUser) {
        proof.allowedUsersForMention = [];
        this.$getMentionableUsers(proof, allUsers).then(mentionableUsers => {
            mentionableUsers.forEach(user => {
                if(user){
                    var isNew = !proof.allowedUsersForMention.some(function(choices){
                        return choices.id === user.id;
                    });
                    if(isNew && !(loggedInUser.id === user.id)){
                        user.display = (user.name) ? user.name : user.email ;
                        proof.allowedUsersForMention.push(user);
                    }
                }
            });
            if (proof.commentVisibility === null && proof.allowedUsersForMention.length > 1) {
                proof.allowedUsersForMention = [...proof.allowedUsersForMention, ...MENTION_STATIC_OPTIONS];
            }
        });
    }

    populateCommentByUsers(proof, pageNumber) {
        const users = {};
        const pages = pageNumber ? [proof.pages[pageNumber - 1]] : proof.pages;
        pages
          .flatMap((page) => ([
            ...page.comments,
            ...page.comments.flatMap(comment => comment.replies),
          ]))
          .forEach((comment) => {
            const pdfAuthor = comment.sourceMetadata && comment.sourceMetadata.pdfImport && comment.sourceMetadata.pdfImport.author;
            const pdfAuthorId = pdfAuthor && ('pdfAuthor:' + pdfAuthor); 

            const userId = pdfAuthorId || comment.ownerId;

            users[userId] = {
              id: userId,
              name: pdfAuthor || comment.ownerEmail,
            };
          });
       proof.commentByUsers = Object.values(users);
    }

    populateMentionedUsers(proof, pageNumber) {
        const mentionedIds = this.getMentionedIds(proof, pageNumber);
        return Promise.all(
            mentionedIds.map((id) => {
                return this.$$.userRepositoryService.get(id);
            })
          ).then((users) => {
            proof.mentionedUsers = users.map((user) => {
              return {
                id: user.userId,
                name: user.name || user.email,
                selected: false,
              };
            });
          });
    }

    getMentionedIds(proof, pageNumber) {
        const pages = pageNumber ? [proof.pages[pageNumber - 1]] : proof.pages;
        let allMentionedIds = [];
        pages.forEach((page) => {
            page.comments.forEach((comment) => {
                allMentionedIds = [...allMentionedIds, ...(comment.mentionedIds || [])];

                if (comment.isParent) {
                    comment.replies.forEach((reply) => {
                        allMentionedIds = [...allMentionedIds, ...(reply.mentionedIds || [])];
                    });
                }
            });
        });
        return allMentionedIds.filter((id, index, ids) => ids.indexOf(id) === index);
    }

    /**
     * Validates whether a file can be attached according to supported file types.
     *
     * @param {File} file
     */
    $validateAttachmentType (file) {
        const isValidAttachment = this.$$.application.isValidAttachmentFileType(getFileType(file.name));

        if (!isValidAttachment) {
            this.$$.SegmentIo.track(65, {
                'file extension': file.name.split('.').pop(),
                type: 'attachment',
            });
        }

        return isValidAttachment;
    }

    /**
     * Validates whether a file can be attached according to file size limit.
     *
     * @param {File} file
     */
    $validateAttachmentSize (file) {
        return this.$$.application.isValidAttachmentFileSize(file.size);
    }

    addOwner (owner) {
        return this.$$.sdk.proofs.owners.add(this.proofId, {email: owner});
    }

    addReviewer (reviewer) {
        return this.$$.sdk.proofs.reviewers.add(this.proofId, {email: reviewer});
    }

    /**
     * Reply to a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofComment} reply
     * @param {PPProof} proof
     * @returns {PPProofComment}
     */
    $replyComment (comment, reply, proof) {
        // Setup any additional properties on the reply
        reply.createdAt = moment();
        reply.proofId = proof.id;
        reply.parentId = comment.id;
        reply.pageNumber = comment.pageNumber;
        reply.ownerEmail = this.user.email;
        reply.ownerId = this.user.id;
        reply.decryptedComment = reply.comment;

        // Push the new reply comment object to the parent comment's replies array
        comment.replies.push(reply);

        // Send a request to the server to create the comment reply
        return this.$encryptAndCreateComment(this.encryptionData, reply)
            .then(() => {
                // @see {GenericProofController.createComment}
                if (reply.attachments.length) {
                    this.$uploadAndAssignAttachments(reply, reply.attachments);
                    proof.calculateCommentCounts();
                }
            })
            .finally(() => {
                reply.decryptedComment = reply.comment;
                reply.$encryptedComment = reply.encryptedComment;
                // this.$setFilter(null);
            });
    }

    /**
     * Updates an existing comment.
     *
     * @param {PPProofComment} comment
     * @param {Array<PPProofCommentAttachment>} attachments
     * @param {PPProof} proof
     */
    $updateComment (comment, newAttachments, proof) {
        // Update the rendered comment (decryptedComment) with the users
        const decryptedComment = comment.comment;
        comment.decryptedComment = null;
        comment.tokens = [];

        // Encrypt the comment and send a request to update it
        return this.$encryptAndUpdateComment(this.encryptionData, comment)
            .then(() => {
                if (newAttachments && newAttachments.length) {
                    this.updateAttachments(comment, newAttachments);
                }
                proof.calculateCommentCounts();
            })
            .finally(() => {
                comment.decryptedComment = decryptedComment;
                comment.$encryptedComment = comment.encryptedComment;
            });

        // TODO Error handling when comment fails to update
    }

    /**
     * Update the attachments (to comment or reply).
     *
     * @param {PPProofComment} comment
     * @param {Array<PPProofCommentAttachment>} attachments
     */
    updateAttachments(comment, newAttachments) {
        const validNewAttachments = this.getValidAttachments(newAttachments);
        this.$$.localCommentService.$uploadAttachments(comment, validNewAttachments);
    }

    getValidAttachments(attachments) {
        return attachments.filter(attachment => this.isNewValidAttachment(attachment));
    }

    isNewValidAttachment(attachment) {
        return attachment.$file && attachment.$file.name;
    }

    $removeComment(comment, page) {
        const parentCommentId = comment.parentId;

        if (parentCommentId) {
            const replies = page.comments.find(pageComment => pageComment.id === parentCommentId).replies;
            const replyIndex = replies.indexOf(comment);

            if (replyIndex !== -1) {
                replies.splice(replyIndex, 1);
            }
        } else {
            const commentIndex = page.comments.indexOf(comment);
            if (commentIndex !== -1) {
                page.comments.splice(commentIndex, 1);
            }
        }

        if (comment.creationToken) {
            this.$$.localCommentService.removeCommentFromLocalStorage(comment.creationToken);
        }
    }

    $scrollAndFocusComment(createCommentId) {
        const that = this;
        this.$$.$timeout(() => {
            const element = document.querySelector(createCommentId);
            let top = -180;
            if (element.getBoundingClientRect().y > 150) {
                top = -250;
            }
            that.$$.domService.scrollTo(createCommentId, 500, top).then(() => {
                document.querySelector(createCommentId + ' [contenteditable]').focus();
            });
        });
    }

    /**
     * When the selection of a comment updates.
     *
     * @param {PPProofComment} previous
     * @param {PPProofComment} current
     */
    $selectionUpdate (previous, current) {
        if (previous) {
            // If there was a previously selected comment (unselect it)
            previous.$selected = false;
        }

        if (current) {
            // Set the new comment's selected state
            current.$selected = true;

            // Make sure the comments pane is rolled out & scroll to it
            this.showComments = true;
        }
    }

    onChangeCommentOrder = ordering => {
        const { searchParam, orderCommentsBy, isReversedCommentOrder } = this.commentOrder;
        if (searchParam !== ordering.searchParam || orderCommentsBy !== ordering.orderCommentsBy || isReversedCommentOrder !== ordering.isReversedCommentOrder) {
            this.commentOrder = ordering;
        }
    }

    /**
     * Returns pin color value for different types of comment pins
     * @param {string} type type of comment - todo|done|unmarked
     */
    getPreferenceMappedPinColor(type) {
        return this.userPreferencePinColorMappingObject
            ? this.userPreferencePinColorMappingObject[type].normal
            : 'grey';
    }

    /**
     * Returns the color for the temporary pin.
     *
     * @returns {String}
     */
    getTemporaryPinColor () {
        // as per new rules, in status 0, 10 all pins has to be red for all users.
        return this.getPreferenceMappedPinColor('todo');
    }

    /**
     * Returns the color for a specific pin.
     *
     * @returns {String}
     */
    getPinColor = (comment, proofData ) => {
        const proof = proofData ? proofData : this.proof;

        if ((proof.status < this.$$.PPProofStatus.FINAL_APPROVING) &&
            (proof.proofType !== this.$$.PPProofType.BRIEF) &&
            !proof.isOwnerOrCoOwner) {
            return this.getTemporaryPinColor();
        } else {
            // Pin color based on whether the comment is marked as to-do/done (red/grey)
            return (this.getPreferenceMappedPinColor(comment.isDone ? 'done' : comment.isTodo ? 'todo' : 'unmarked'));
        }
    }

    getCommentMarkStatus (comment, proofData) {
        const proof = proofData ? proofData : this.proof;

        const { TODO, DONE, UNMARKED } = this.$$.PPCommentMarkType;

        if ((proof.status < this.$$.PPProofStatus.FINAL_APPROVING) &&
            (proof.proofType !== this.$$.PPProofType.BRIEF) &&
            !proof.isOwnerOrCoOwner) {
            return TODO;
        } else {
            return comment.isDone ? DONE : comment.isTodo ? TODO : UNMARKED;
        }
    }

    closeDialog() {
        const dialogType = this.dialog.type; 

        this.dialog = null;
        switch (dialogType) {
            case 'editor-todos-requested':
                this.$$.walkthrough.startWalkthrough('w-proof-editor', true);
                break;
            default:
                break;
        }
    }

    getFinalApproversThatApproved() {
        const finalApprovers = new Set();

        const proof = this.proof;
        const workflow = proof.workflow;
        const { Enum } = window.__pageproof_quark__.sdk;

        if (proof.hasApproved || proof.status === Enum.ProofStatus.APPROVED) {
            finalApprovers.add(proof.lockerId);
        }

        const finalStep = workflow && workflow.steps && workflow.steps.find(step => step.position === 1000);
        if (finalStep && finalStep.users) {
            finalStep.users.forEach(user => {
                if ([Enum.Decision.APPROVED, Enum.Decision.APPROVED_WITH_CHANGES].includes(user.decision)){
                    finalApprovers.add(user.id);
                }
            });
        }

        return [...finalApprovers];
    }

    isGeneralComment(comment) {
        return comment.pins && comment.pins.length === 0;
    }

    compareCanvasPosition(previousPosition, newPosition) {
        return Object.keys(previousPosition).some(key => Math.abs(previousPosition[key] - newPosition[key]) > MINIMUM_POSITION_DIFFERENCE_FOR_COMPARING);
    }

    /**
     * Updates the current canvas position and any related elements.
     * versionObj is only passed in compare mode, so we know which canvas we're dealing with.
     */
    updateCurrentCanvasPosition(position, versionObj) {
        const type = versionObj ? versionObj.type : null;
        const currentCanvasPosition = type ? this.currentCanvasPosition[type] : this.currentCanvasPosition;

        if (position.zoomLevel > 0 && this.compareCanvasPosition(currentCanvasPosition, position)) {
            // Update the location of the canvas
            if (type) {
                const canvasContainerElement = this.canvasContainerElements[type][0];
                this.currentCanvasPosition[type] = Object.assign({}, position);
                this.currentCanvasPosition[type].top = position.top - ((versionObj.mergeImages || this.isStatic(versionObj)) ? COMPARE_MODE_CANVAS_Y_OFFSET : 0);
                this.currentCanvasPosition[type].canvasContainerWidth = canvasContainerElement ? canvasContainerElement.getBoundingClientRect().width : 0;    
            } else {
                this.currentCanvasPosition = Object.assign({}, position);
            }

            // Update the location of the general comment icons
            const currentPage = type ? this.getCurrentPageByType(type) : this.getCurrentPage();
            if (currentPage.generalCommentCount > 0) {
                const newLocation = this.getGeneralCommentIconButtonsLocation(type);
                const generalCommentButtons = document.getElementById(type ? `general-comment-icon-buttons-${type}` : `general-comment-icon-buttons`);

                // General comment icons may not be on the screen yet, eg. during rotation
                if (generalCommentButtons) {
                    generalCommentButtons.style.display = position.isRotating ? 'none' : 'block';
                    generalCommentButtons.style.top = `${newLocation.top}px`;
                    generalCommentButtons.style.left = `${newLocation.left}px`;
                }
            }

            // Update the location of the grid lines (not available in compare mode)
            if (!type && this.isActivatedGridLines && !this.$$.$scope.$$phase) {
                this.$$.$scope.$apply();
            }
        }
    }
}

window.BaseProofController = BaseProofController;
