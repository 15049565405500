/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const PAGE_SORT_FN = sortObjectArrayFn('pageNumber');
const VIDEO_FILE_TYPES = ['mp4-AnimatedGif'];

app.factory('PPProof', function ($filter, PPModel, PPFile, PPProofPage, PPProofVideoMeta, UserService, PPProofType, userService, PPProofStatus, PPReminder, PPIntegrationReference, PPChecklist) {
    let { dateTransformer, numberTransformer, booleanTransformer } = PPModel;

    const proofTransformer = {
        description: 'Description',
        dueDate: dateTransformer('DueDate'),
        extension: 'Extension',
        editorId: 'EditorId',
        editorMessage: 'EditorMessage',
        editorMessageUserId: 'EditorMessageOwnerId',
        fileId: 'FileId',
        fileStatus: 'FileStatus',
        fileSize: 'FileSize',
        fileType: 'FileType',
        fileStatusMessage: 'FileStatusMessage',
        isManagedWebUrl: 'IsManagedWebUrl',
        hasVersion: 'HasVersion',
        isLocked: 'Locked',
        isClosed: 'Closed',
        isVersion: 'IsVersion',
        ownerId: 'UserId',
        ownerMessage: 'OwnerMessage',
        ownerMessageUserId: 'OwnerMessageCreatedById',
        pageCount: numberTransformer('Pages'),
        commentCount: numberTransformer('ParentComments'),
        todoCommentCount: numberTransformer('CountTodoComments'),
        doneCommentCount: numberTransformer('CountDoneComments'),
        id: 'ProofId',
        status: 'Status',
        title: 'Title',
        workflowId: 'WorkflowId',
        version: 'Version',
        lockerId: 'ProofLockerId',
        chunks: 'Chunks',
        canDownload: booleanTransformer('DownloadOriginal'),
        commentVisibility: 'CommentVisibility',
        approvedDate: dateTransformer('ApprovedDate'),
        width: numberTransformer('Width'),
        height: numberTransformer('Height'),
        originalWidth: numberTransformer('OriginalWidth'),
        originalHeight: numberTransformer('OriginalHeight'),
        measurementUnits: 'MeasurementUnits',
        brandingUrl: data => ((data.Branding && data.Branding.LogoURL) || null),
        proofType: 'Type',
        recipient: 'Recipient',
        recipientEmail: 'RecipientEmail',
        integrationReferences: (data) => {
            if (data.IntegrationReferences) {
                return data.IntegrationReferences.map(integrationReference => (
                    PPIntegrationReference.from(integrationReference)
                ));
            }
        },
        checklist: data => data.Checklist && PPChecklist.from(data.Checklist),
        reminders: (data) => {
            if (data.Reminders) {
                return data.Reminders.map(reminderData => (
                    PPReminder.from(reminderData)
                  ));
            }
        },
        importUrl: 'ImportURL',
        featureFlags: 'FeatureFlags',
        ownerIds: 'OwnerIds',
        owners: 'Owners',
        isAdminOnProof: 'IsAdminOnProof',
        editorIds: 'EditorIds',
        groupId: 'GroupId',
        unlockRequesterId: 'UnlockedRequesterId',
        canReupload: 'CanReupload',
        hasEditors: 'HasEditors',
        fileCategory: 'FileCategory',
        canOthersEditComments: booleanTransformer('FeatureEditComments'),
        canAddCommentReplies: 'FeatureCanAddCommentReplies',
        canHavePrivateComments: 'FeaturePrivateComments',
        canHaveGeneralComments: 'FeatureGeneralComments',
        hasDecisionsEnabled: 'FeatureDecisions',
        hasColorSeparations: 'HasColorSeparations',
        reference: 'Reference',
        isReferenceEnabled: 'FeatureProofReference',
        pageDimensions: 'PageDimensions',
        fontList: 'FontList',
        colorList: 'ColorList',
        source: 'Source',
        sourceMetadata: 'SourceMetadata',
        teamId: 'TeamId',
        isPublic: 'IsPublic',
        canBePublic: 'FeaturePublicProofs',
        canHaveCommentVisibility: 'FeatureCommentVisibility',
        availableWorkflowRoles: 'FeatureAvailableWorkflowRoles',
        canAddChecklist: 'FeatureCanAddChecklist',
        usePreviousVersionDueDate: 'FeatureUsePreviousVersionDueDate',
        canSendShareLink: 'FeatureSendShareLink',
        canSendMessageToOwners: 'FeatureMessageToOwners',
        allowedDecisions: 'FeatureAllowedDecisions',
        role: 'Role',
        ownerCanDeleteComments: 'FeatureOwnerCanDeleteComments',
        pagesMetadata: data => (data.AdditionalFileMetadata && data.AdditionalFileMetadata.pages),
        colorProfile: data => (data.AdditionalFileMetadata && data.AdditionalFileMetadata.colorProfile),
        linkedFiles: data => (data.AdditionalFileMetadata && data.AdditionalFileMetadata.linkedFiles),
        framesPerSecond: data => ((data.AdditionalFileMetadata && data.AdditionalFileMetadata.framesPerSecond) || 30),
        publicProofKeyPair: data => (data.PublicProofKeyPair && {
            publicKeyPEM: data.PublicProofKeyPair.PublicKey,
            privateKeyPEM: data.PublicProofKeyPair.PrivateKey,
        }),
        crData: data => (data.AdditionalFileMetadata &&  data.AdditionalFileMetadata.crData),
        tags: (data) => {
            if (data.Tags) {
                return data.Tags.map(tag => tag.Value);
            }
            return [];
        }
    };

    const proofRecentCommentsTransformer = {
        status: 'Status',
        isLocked: 'Locked',
        canAddCommentReplies: 'FeatureCanAddCommentReplies',
        commentVisibility: 'CommentVisibility',
    };

    class PPProof extends PPModel {
        /**
         *
         * @type {Boolean}
         */
        isLatestVersion = false;

        /**
         *
         * @type {String}
         */
        description = null;

        /**
         *
         * @type {String}
         */
        dueDate = null;

        /**
         *
         * @type {String}
         */
        editorId = null;

        /**
         *
         * @type {String}
         */
        editorMessage = null;

        /**
         * For files that have been imported from a hyperlink/permalink.
         *
         * @type {String}
         */
        importUrl = null;

        /**
         *
         * @type {String}
         */
        fileId = null;

        /**
         *
         * @type {String}
         */
        fileStatus = null;

        /**
         *
         * @type {String}
         */
        fileType = null;

        /**
         *
         * @type {String}
         */
        fileStatusMessage = null;

        /**
         * Whether the Web URL proof is managed - meaning it needs to be served over the static.pageproof.com reverse proxy.
         *
         * @type {Boolean}
         */
        isManagedWebUrl = false;

        /**
         *
         * @type {Boolean}
         */
        hasVersion = false;

        /**
         *
         * @type {Boolean}
         */
        isLocked = false;

        /**
         *
         * @type {String}
         */
        ownerId = null;

        /**
         *
         * @type {String}
         */
        ownerMessage = null;

        /**
         *
         * @type {Number}
         */
        pageCount = 0;

        /**
         * The number of comments in total on the proof.
         *
         * @type {Number}
         */
        commentCount = 0;

        /**
         * The number of private comments in total on the proof.
         *
         * @type {Number}
         */
        privateCount = 0;

        /**
         * The number of comments which are marked as to do.
         *
         * @type {Number}
         */
        todoCount = 0;

        /**
         * The number of comments which are marked as done.
         *
         * @type {Number}
         */
        doneCount = 0;

        /**
         *
         * @type {String}
         */
        id = null;

        /**
         *
         * @type {Number}
         */
        status = null;

        /**
         *
         * @type {String}
         */
        title = null;

        /**
         *
         * @type {String}
         */
        workflowId = null;

        /**
         * The version number of the proof.
         *
         * @type {Number}
         */
        version = 0;

        /**
         * The proof thumbnail (url).
         *
         * @type {String}
         */
        thumbnail = null;

        /**
         * The owner user object.
         *
         * @type {PPUser}
         */
        ownerUser = null;

        prooferUsers = [];
        /**
         * The array of co owner users.
         *
         * @type {PPUser[]}
         */
        coOwners = [];

        /**
         * The array of co owner user ids.
         *
         * @type {String[]}
         */
        coOwnerIds = [];

        /**
         * The editor user object.
         *
         * @type {PPUser}
         */
        editorUser = null;

        /**
         * The array of editor users.
         *
         * @type {PPUser[]}
         */
        editorUsers = [];

        /**
         * The final approver users.
         *
         * @type {PPUser[]}
         */
        approvingFinalApproverUsers = null;

        /**
         * The proof workflow.
         *
         * @type {PPProofWorkflow}
         */
        workflow = null;

        /**
         * The proofs pages.
         *
         * @type {PPProofPage[]}
         */
        pages = [];

        /**
         * Whether comment /reply can be edited by other users
         *
         * @type {boolean}
         */
        canOthersEditComments = false;

        /**
         * Whether proof is publicly accessible
         */
        isPublic = false;

        /**
         * Whether reply can be added in statuses.
         *
         * @type {boolean}
         */
        canAddCommentReplies = true;

        /**
         * Whether general comment (comment with no markup) can be added to proof.
         *
         * @type {boolean}
         */
        canHaveGeneralComments = true;

        /**
         * Whether private comments can be added to the proof.
         *
         * @type {boolean}
         */
        canHavePrivateComments = false;

        /**
         * The other proof versions.
         *
         * @example
         *     {
         *       "P5K0WGP6FNXK966A": { versionNumber: 1, canAccess: true }
         *       "P121SR0GG74I7PNV": { versionNumber: 2, canAccess: true }
         *       "PJA2RJC16ATSKMJ2": { versionNumber: 6, canAccess: true }
         *     }
         *
         * @type {Object}
         */
        versions = {};

        /**
         * filtered versions, which user have access
         * @type [array]
         */
        allowedVersions = [];

        /**
         * The proofs assigned status message text.
         *
         * @type {String}
         */
        statusMessage = null;

        /**
         * The PPFile object from the proof data.
         *
         * @type {PPFile}
         */
        file = null;

        /**
         * The date the proof was approved (otherwise null).
         *
         * @type {moment|null}
         */
        approvedDate = null;

        /**
         * The url to the custom logo which should be presented on this proof's proof screen.
         *
         * @type {string}
         */
        brandingUrl = null;

        /**
         * @private
         */
        featureFlags = null;

        /**
         * An array of all owner and co-owner ids
         *
         * @type {array}
         */
        ownerIds = [];

        /**
         * An array of all owners user object
         *
         * @type {array}
         */
        owners = [];

        /**
         * An array of all editor ids
         *
         * @type {array}
         */
        editorIds = [];

        /**
         * If a file has been stucked, then on user's click a temporary allowing user file reupload
         *
         * @type {boolean}
         */
        allowReupload = false;

        /**
         * Whether the proof has the reference (which can be used to integrate with other applications)
         *
         * @type {string}
         */
        reference = null;

        /**
         * Whether the reference has been enbaled/disabled (which can be used to integrate with other applications)
         *
         * @type {boolean}
         */
        isReferenceEnabled = false;

        /**
         * array of mentionedUsers on the proof
         * @type {Array}
         */
        mentionedUsers = [];

        /**
         * Array of users that have left a comment on the proof
         * @type {Array}
         */
         commentByUsers = [];

        /**
         * Object having device category counts in comments of a proof
         * @type {Object}
         */
        deviceCategoryCounts = {};

        /**
         * Import file names of comments on a proof
         * @type {Object}
         */
        importFileNameCounts = {};

        /**
         * Object having web pages counts in comments of a web proof
         * @type {Object}
         */
        webPageCounts = {};

        /**
         * Whether the current user can send a message to the proof owners.
         *
         * @type {boolean}
         */
        canSendMessageToOwners = true;

        /**
         * Whether the proof has color separation data.
         *
         * @type {boolean}
         */
        hasColorSeparations = false;

        allowedUsersForMention = [];

        /**
         * The number of replies in total on the proof.
         *
         * @type {Number}
         */
        repliesCount = 0;

        /**
         * if proof has been approved
         * Actually, in status 70 we know it, but in status 100, to know if proof has been approved
         * @type {boolean}
         */
        get hasApproved () {
            return (!!(this.approvedDate) && this.approvedDate.unix() !== -2208988800 && moment(this.approvedDate).format('YYYY') > '1900')
                ? this.approvedDate.format('h:mma[,] Do MMMM YYYY')
                : false;
        }

        /**
         * Whether the proof has an editor assigned.
         *
         * @returns {Boolean}
         */
        get hasEditor () {
            return !! (this.editorIds.length);
        }

        /**
         * Whether the proof has a workflow.
         *
         * @returns {Boolean}
         */
        get hasWorkflow () {
            return !! (this.workflowId && this.workflowId.length);
        }

        /**
         * Whether the proof has a file error.
         *
         * Checks if the proof has a file id attached, if not, assumes there is an error.
         *
         * @returns {Boolean}
         */
        get hasFileError () {
            return (this.fileStatus === 'Error' || this.fileStatus === '' || ! this.fileId || this.allowReupload);
        }

        /**
         * Whether or not the proof is overdue.
         *
         * Note: Returns false if the due date has not been loaded.
         *
         * @returns {Boolean}
         */
        get isOverdue () {
            return this.dueDate && moment().isAfter(this.dueDate);
        }

        get hasProgress () {
            return this.getProgressPercent() >= 0;
        }

        /**
         * Whether the proof is a video file.
         *
         * @returns {Boolean}
         */
        get isVideo () {
            return this.fileCategory === 'video';
        }

        /**
         * Whether the proof is an audio file.
         *
         * @returns {Boolean}
         */
        get isAudio () {
            return this.fileCategory === 'audio';
        }

        /**
         * Whether the proof is a static file.
         *
         * @returns {Boolean}
         */
        get isStatic() {
            return this.fileCategory === 'static';
        }

        /**
         * Whether the proof is a web archive.
         *
         * @returns {boolean}
         */
        get isWeb() {
            return this.fileCategory === 'web';
        }

         /**
         * Whether the logged in user owns the proof
         *
         * @returns {Boolean}
         */
        get isOwnerOrCoOwner () {
            return userService.matches(...this.ownerIds);
        }

        get isEditor () {
            return userService.matches(...this.editorIds);
        }

        /**
         * Whether the logged in is on the team that owns the proof
         *
         * @returns {Boolean}
         */
        get isCurrentUserInProofTeam() {
            return userService.getUser().teamId === this.teamId;
        }

        get canManage() {
            return this.isOwnerOrCoOwner || userService.matches(this.recipient);
        }

        get hasChecklist() {
            return this.checklist && this.checklist.id;
        }

        get isUnlockRequestPending () {
            return !! this.unlockRequesterId;
        }

        /**
         * Returns the URL to the proof page.
         *
         * @returns {string}
         */
        getUrl() {
            let type = this.proofType === 2 ? 'brief' : 'proof';
            let path = type + '/static';

            if (this.isVideo) {
                path = type + '/video';
            } else if (this.isAudio) {
                path = type + '/audio';
            } else if (this.isWeb) {
                path = type + '/web';
            }

            return '/' + path + '/' + this.id;
        }

        getProofType() {
            switch (this.proofType) {
                case 2:
                    return 'brief';
                case 1:
                default:
                    return 'proof';
            }
        }

        /**
         * Parses proof status message
         * Currently seems only two type of strings coming
         * @param proof
         * @returns {*}
         */
        getStatusMessage() {
            if (this.statusMessage) {
                let message = this.statusMessage.toLowerCase();
                switch (message) {
                    case "proofing (primary_email)":
                        return "proofing (Final approver)";
                    case "awaiting new version":
                        return "New version required";
                    default:
                        return this.statusMessage;
                }
            }
            return this.statusMessage;
        }

        /**
         * Get the videos processing progress (point, not percent).
         *
         * @see {PPProof.$$videoProcessingProgress}
         * @returns {Number}
         */
        getVideoProcessingProgress () {
            if (this.isVideo) {
                if (this.$$videoProcessingProgress &&
                    this.$$videoProcessingProgress.message === this.fileStatusMessage) {
                    return this.$$videoProcessingProgress.percent;
                }

                let percent = 0,
                    message = String(this.fileStatusMessage),
                    match;

                if (message.startsWith('Video-Scheduled-')) {
                    percent = 0;
                }

                else if ((match = /^Video\-\w+\-([-+]?[0-9]*\.?[0-9]*)/g.exec(message))) {
                    percent = getPercentage(parseFloat(match[1]) / 100, 0, .2);
                }

                else if ((match = /^Thumb\-\w+\-([-+]?[0-9]*\.?[0-9]*)/g.exec(message))) {
                    percent = getPercentage(parseFloat(match[1]) / 100, .8, 0);
                }

                return (this.$$videoProcessingProgress = {
                    message: message, percent
                }).percent;
            } else {
                return 0;
            }
        }

        getProgressPercent() {
            switch (this.fileStatus) {
                case "Uploading":
                    return .1;
                case "Queued":
                    return .2;
                case "Ripping":
                    if (this.isVideo) {
                        return Number(getPercentage(this.getVideoProcessingProgress(), .2, .1).toFixed(2));
                    }
                    return .6;
                default:
                    return -1;
            }
        }

        canUnlock() {
            return this.isLocked
                    && (this.isOwnerOrCoOwner || (this.lockerId === userService.getUser().id));
        }

        canRequestUnlock() {
            return this.isLocked
                    && !this.isOwnerOrCoOwner
                    && this.lockerId !== userService.getUser().id;
        }

        /**
         * Gets a page by it's page number.
         *
         * @param {number} pageNumber
         * @returns {PPProofPage}
         */
        getPage (pageNumber) {
            pageNumber = Number(pageNumber);
            for (let index = 0, length = this.pages.length; index < length; index++) {
                if (this.pages[index].pageNumber === pageNumber) {
                    return this.pages[index];
                }
            }
        }

        /**
         * Adds a/or many pages to the pages array.
         *
         * @see {PPProof.pages}
         * @param {...PPProofPage} pages
         */
        addPages (...pages) {
            pages.forEach(page => this.pages.push(page));
        }

        /**
         * Sorts the `pages` array to make sure the pages are in the correct order.
         *
         * @see {sortObjectArrayFn}
         */
        sortPages () {
            this.pages.sort(PAGE_SORT_FN);
        }

        _normaliseCommentsData(pages) {
            const pageNumbers = Object.keys(pages);
            const commentsByPage = {};
            const commentIndex = {};
            const replies = [];

            pageNumbers.forEach(pageNumber => {
                commentsByPage[pageNumber] = [];

                pages[pageNumber].Comments.forEach(commentOrReply => {
                    if (commentOrReply.Parent) {
                        replies.push(commentOrReply);
                    } else {
                        commentIndex[commentOrReply.CommentId] = commentOrReply;
                        commentsByPage[commentOrReply.Page].push(commentOrReply);
                    }
                });
            });

            replies.forEach(reply => {
                const parentComment = commentIndex[reply.Parent];

                if (parentComment) {
                    commentsByPage[parentComment.Page].push(reply);
                } else {
                    commentsByPage[reply.Page].push(reply);
                }
            });

            const normalisedPages = {};
            pageNumbers.forEach(pageNumber => {
                const original = pages[pageNumber];
                normalisedPages[pageNumber] = {
                    Changes: original.Changes,
                    Count: original.Count,
                    Done: original.Done,
                    Comments: commentsByPage[pageNumber],
                };
            });

            return normalisedPages;
        }

        /**
         * Update the comments from a recent comments request data object.
         *
         * @param {Object} recentCommentsData
         * @returns {PPProofComment[]}
         */
        updateFromRecentCommentsData (recentCommentsData) {
            let updatedComments = [];

            // Transform the request (has locked and status)
            this.transform(recentCommentsData, proofRecentCommentsTransformer);

            // Needed due to a bug where replies to comments were being added to page 1 (not the comment's page)
            const normalisedPages = this._normaliseCommentsData(recentCommentsData.Pages);

            Object.keys(recentCommentsData.Pages).forEach((pageNumber) => {
                let page = this.getPage(pageNumber);
                updatedComments = updatedComments.concat(
                    page.updateFromRecentCommentsData(normalisedPages[pageNumber]));
            });

            this.calculateCommentCounts();

            // Return the updated comments array (for deferred decryption)
            return updatedComments;
        }

        /**
         * Updates the proof version from a proof version object.
         *
         * @param {Array} versionData
         */
        updateFromVersionData (versionData) {
            let baseVersion = window.isBriefId(this.id) ? 0 : 1;
            let latestVersion = baseVersion;

            if ( ! versionData.length) {
                this.versions[this.id] = {
                    versionNumber: baseVersion,
                    canAccess: true,
                };
                this.allowedVersions[1] = baseVersion;
            } else {
                versionData.forEach(version => {
                    this.versions[version.ProofId] = {
                        versionNumber: version.Version,
                        canAccess: version.CanAccess,
                    };
                    latestVersion = Math.max(latestVersion, version.Version);
                    if (version.CanAccess) {
                        this.allowedVersions.push(version);
                    }
                });
            }

            this.version = this.versions[this.id].versionNumber;
            this.isLatestVersion = latestVersion === this.version;
        }

        /**
         * Updates the proof status string from a status string object.
         *
         * @param {String} statusStringData
         */
        updateFromStatusStringData (statusStringData) {
            this.statusMessage = statusStringData;
        }

        /**
         * Updates the proof from a proof data object.
         *
         * @param {Object} data
         */
        updateFromProofData (data) {
            // Transform the proof data - @see proofTransformer
            this.transform(data, proofTransformer);

            // Load the PPFile object from the proof data
            this.file = PPFile.fromProofData(this);
            this.file.name = this.file.name + '.' + this.file.extension;

            // Also setup the page objects for the proof to prevent later creating them
            this.setupPages();

            // The comment count we receive from the server never includes private comments.
            // If we've already calculated the private comment count we need to add it to the comment count here
            if (this.privateCount) {
                this.commentCount += this.privateCount;
            }

            // If you'd like to test external web proofs before the API has been rolled out,
            // uncomment these lines below:
            // this.fileType = 'external-Web';
            // this.importUrl = 'https://example.com';
        }

        /**
         * Updates the proof from a proof team data object.
         *
         * @param {Object[]} teamData
         */
        updateFromTeamData (teamData) {
            teamData.forEach((teamUser) => {
                let { UserId: userId } = teamUser;

                if (teamUser.ProofCoOwner) {
                    this.coOwnerIds.push(userId);
                }
            });
        }

        /**
         * Updates the proof from the decrypted video string.
         *
         * @param {String} videoData
         */
        updateFromVideoData ({streamingUrl, accessToken}) {
            this.video = new PPProofVideoMeta(streamingUrl, accessToken);
        }

        /**
         *
         */
        calculateCommentCounts() {
            let commentCount = 0,
                privateCount = 0,
                unmarkedCount = 0,
                todoCount = 0,
                doneCount = 0,
                agreeCount = 0,
                agreeCommentCount = 0,
                agreeReplyCount = 0,
                attachmentCount = 0,
                labelCount = {
                    'approved': 0,
                    'highlight': 0,
                },
                deviceCategoryCounts = {},
                importFileNameCounts = {},
                webPageCounts = {},
                repliesCount = 0,
                privateRepliesCount = 0,
                notAgreeCount = 0;

            if (this.pages.length) {
                this.pages.forEach((page) => {
                    commentCount += page.comments.length;
                    page.commentCount = 0;
                    page.privateCount = 0;
                    page.unmarkedCount = 0;
                    page.todoCount = 0;
                    page.doneCount = 0;
                    page.agreeCount = 0;
                    page.agreeCommentCount = 0;
                    page.agreeReplyCount = 0;
                    page.attachmentCount = 0;
                    page.notAgreeCount = 0;
                    page.labelCount = {
                        'approved': 0,
                        'highlight': 0,
                    };
                    page.generalCommentCount = 0;
                    page.generalCommentUnmarkedCount = 0;
                    page.generalCommentTodoCount = 0;
                    page.generalCommentDoneCount = 0;
                    page.repliesCount = 0;
                    page.privateRepliesCount = 0;

                    page.comments.forEach((comment) => {
                        page.commentCount++;

                        if (comment.isPrivate) {
                            privateCount++;
                            page.privateCount++;
                        }
                        
                        if (comment.pins.length === 0) {
                            page.generalCommentCount++;
                        }
                        if (comment.isDone) {
                            doneCount++;
                            page.doneCount++;

                            if (comment.pins.length === 0) {
                                page.generalCommentDoneCount++;
                            }
                        } else if (comment.isTodo) {
                            todoCount++;
                            page.todoCount++;

                            if (comment.pins.length === 0) {
                                page.generalCommentTodoCount++;
                            }
                        } else if (!comment.isPrivate) {
                            unmarkedCount++;
                            page.unmarkedCount++;

                            if (comment.pins.length === 0) {
                                page.generalCommentUnmarkedCount++;
                            }
                        }
                        if (comment.agrees.length > 0) {
                            agreeCount++;
                            agreeCommentCount++;
                            page.agreeCount++;
                            page.agreeCommentCount++;
                        } else {
                            notAgreeCount++;
                            page.notAgreeCount++;
                        }
                        if (!!comment.attachments) {
                            attachmentCount = attachmentCount + comment.attachments.length;
                            page.attachmentCount = page.attachmentCount + comment.attachments.length;
                        }
                        let commentLabel = comment.getLabel();
                        if (commentLabel === 'approved') {
                            labelCount.approved++;
                            page.labelCount.approved++;
                        } else if (commentLabel === 'highlight') {
                            labelCount.highlight++;
                            page.labelCount.highlight++;
                        }

                        if (comment.metadata && comment.metadata.device) {
                            const deviceCategory = window.__pageproof_quark__.comments.getCommentDeviceCategory(comment.metadata.device.id);
                            deviceCategoryCounts[deviceCategory] = deviceCategoryCounts[deviceCategory] || 0;
                            deviceCategoryCounts[deviceCategory]++;
                        }

                        const importFileName = comment.sourceMetadata && comment.sourceMetadata.pdfImport && comment.sourceMetadata.pdfImport.fileName;
                        if (importFileName) {
                            importFileNameCounts[importFileName] = importFileNameCounts[importFileName] || 0;
                            importFileNameCounts[importFileName]++;
                        }

                        if (comment.metadata && comment.metadata.page) {
                            const path = window.__pageproof_quark__.comments.getCommentWebPagePath((comment.metadata.page.path));
                            webPageCounts[path] = webPageCounts[path] || 0;
                            webPageCounts[path]++;
                        }

                        if (comment.replies.length) {
                            repliesCount += comment.replies.length;
                            page.repliesCount += comment.replies.length;
                            comment.replies.forEach((reply) => {
                                if (reply.isPrivate) {
                                    privateRepliesCount++;
                                    page.privateRepliesCount++;
                                }

                                if (reply.agrees.length > 0) {
                                    agreeCount++;
                                    page.agreeCount++;
                                    agreeReplyCount++;
                                    page.agreeReplyCount++;
                                }
                                if (reply.attachments.length) {
                                    attachmentCount = attachmentCount + reply.attachments.length;
                                    page.attachmentCount = page.attachmentCount + reply.attachments.length;
                                }
                            });
                        }
                    });
                });
            }

            // Re-assign the comment counts (resets to zero if error)
            [this.commentCount, this.privateCount, this.unmarkedCount, this.todoCount, this.doneCount, this.agreeCount, this.agreeCommentCount, this.agreeReplyCount, this.attachmentCount, this.labelCount, this.notAgreeCount, this.deviceCategoryCounts, this.importFileNameCounts, this.webPageCounts, this.repliesCount, this.privateRepliesCount] =
                [commentCount, privateCount, unmarkedCount, todoCount, doneCount, agreeCount, agreeCommentCount, agreeReplyCount, attachmentCount, labelCount, notAgreeCount, deviceCategoryCounts, importFileNameCounts, webPageCounts, repliesCount, privateRepliesCount];
        }

        /**
         * Create/bootstrap the page objects for the proof.
         *
         * @see {PPProofPage}
         */
        setupPages () {
            let pageCount = Math.max(1, this.pageCount),
                existingPages = this.pages.map(page => page.pageNumber),
                addPages = [];

            for (let pageNumber = 1; pageNumber <= pageCount; pageNumber++) {
                if (existingPages.indexOf(pageNumber) === -1) {
                    let page = new PPProofPage();
                    page.pageNumber = pageNumber;
                    page.proofId = this.id;
                    addPages.push(page);
                }
            }

            // Add all the pages to the proof object and then shuffle the pages into the correct order
            // This is necessary as by default the `addPages` method pushes pages to the internal `pages` array
            this.addPages(...addPages);
            this.sortPages();
        }

        /**
         * Creates a Proof object from a proof data object.
         *
         * @param {Object} proofData
         */
        static from (proofData) {
            let proof = new this();
            proof.updateFromProofData(proofData);
            return proof;
        }

        /**
         * Returns an array of owners and co-owners
         *
         *
         * @returns {Array}
         */
        getOwners() {
            return [].concat(this.ownerUser, this.coOwners).filter(owner => owner);
        }
    }

    return PPProof;
});
